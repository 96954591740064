import { useState, createContext } from 'react'
import api from 'src/shared/api'

type ApiContext = {
  api: ReturnType<typeof api>
  correlationId: string
  setCorrelationId: (correlationId: string) => void
}

export const ApiContext = createContext<ApiContext>(null)

export const ApiProvider = ({ children }) => {
  const [correlationId, setCorrelationId] = useState('')
  return (
    <ApiContext.Provider value={{ correlationId, setCorrelationId, api: api(setCorrelationId) }}>
      {children}
    </ApiContext.Provider>
  )
}
