import { Dayjs } from 'dayjs'
import { CommonMetadata } from './metadata'
import { Receivable } from './receivable'

export enum InstallmentType {
  ENROLLMENT = 'ENROLLMENT',
  TUITION = 'TUITION',
}

export enum InstallmentTypeLabel {
  ENROLLMENT = 'Matrícula',
  TUITION = 'Mensalidade',
}

export enum InstallmentOverviewStatus {
  ALL_PAID = 'ALL_PAID',
  NONE_PENDING = 'NONE_PENDING',
  WAITING_PAYMENT = 'WAITING_PAYMENT',
}

export type Installment = CommonMetadata & {
  amount: cents
  contract_id: uuid
  due_date: datestring
  order_reference?: string
  receivables?: Array<Receivable>
  status: InstallmentStatuses
  type?: InstallmentType
}

export enum InstallmentStatuses {
  CANCELED = 'CANCELED',
  OPEN = 'OPEN',
  OVERDUE = 'OVERDUE',
  PAID = 'PAID',
}

export type ProcessedInstallment = {
  amount: cents
  children?: ProcessedInstallment[]
  due_date: datestring
  due_month: Dayjs
  id: uuid
  installment_id: uuid
  key: string
  orderReference: string
  status: string
  type?: string
}
