import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { ContractDetailsDrawerState } from 'src/escolas/components/contract/ContractDetailsDrawer/constants'
import ContractDetailsDrawerDefaultContent from 'src/escolas/components/contract/ContractDetailsDrawer/ContractDetailsDrawerDefaultContent'
import ManualSignDrawer from 'src/escolas/components/contract/ManualSignDrawer'
import {
  Contract,
  ContractStatus,
  ReceivableStatuses,
  SignAndPaymentStatus,
} from 'src/shared/interfaces'
import styled from 'styled-components'
import Drawer from '@material-ui/core/Drawer'
// feature toggle: we need to call the hook below to switch the Button ("Gerar Demonstrativo de Pagamento")
import useContractPaidAmountsReport from 'src/escolas/router/contrato/[contractId]/demonstrativo-pagos/use-contract-paid-amounts-report'
import { ActionDrawerHeader, Button, ButtonDocker } from '@olaisaac/design-system'
import { PUBLIC_DOMAIN } from 'src/shared/api'
import ContractCheckoutActions from '../ContractActionsCheckout/ContractActionsCheckout'

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    width: calc(((100% - 160px) / 12) * 4);
    transition: transform 0.3s cubic-bezier(0.42, 0.29, 0.39, 0.83) !important;
  }
`

export type ContractDetailsDrawerProps = {
  addContract?: Dispatch<SetStateAction<boolean>>
  canEditDiscounts?: boolean
  canEditDueDay?: () => boolean
  contract: Contract
  drawerState: ContractDetailsDrawerState
  getContract: () => void
  isCheckoutSchool?: boolean
  isOpen: boolean
  isReenrollable?: boolean
  onClose: () => void
  openDiscountsEdition?: () => void
  openDueDayEdition?: () => void
  setDrawerState: Dispatch<SetStateAction<ContractDetailsDrawerState>>
  setIsConfirmationDialogVisible: (value: boolean) => void
  setIsFailureDialogOpen: (value: boolean) => void
  tooltip?: string
}

const ContractDetailsDrawer: FC<ContractDetailsDrawerProps> = ({
  contract,
  getContract,
  isCheckoutSchool,
  isOpen,
  isReenrollable,
  onClose,
  setIsFailureDialogOpen,
  drawerState,
  setDrawerState,
  setIsConfirmationDialogVisible,
  addContract,
  openDiscountsEdition,
  canEditDiscounts,
  openDueDayEdition,
  canEditDueDay,
  tooltip,
}) => {
  const [contractStatus, setContractStatus] = useState(null)
  const [showAnnualPaidAmountsStatement, setShowAnnualPaidAmountsStatement] = useState(false)
  const goBackToDefaultContent = () => setDrawerState(ContractDetailsDrawerState.DEFAULT)
  const printContractURL = `${PUBLIC_DOMAIN}/contract/${contract?.id}/print-slips`
  const changeContractStatus = () => {
    const { status, sign_payment_status } = contract

    return status === ContractStatus.OPEN
      ? setContractStatus(SignAndPaymentStatus[sign_payment_status])
      : setContractStatus(ContractStatus.CANCELED)
  }

  const isContractActive = contract?.status === 'OPEN'

  const isSignable = !!(
    (isContractActive && contractStatus === SignAndPaymentStatus.PAYMENT_AND_SIGN_PENDING) ||
    contractStatus === SignAndPaymentStatus.SIGN_PENDING
  )

  const printCarne = () => {
    window.open(printContractURL)
  }
  const hasPaidReceivables = (c: Contract = contract) => {
    let flatReceivables = []
    c.installments.forEach(({ receivables }) => {
      flatReceivables = [...flatReceivables, ...receivables]
    })
    return flatReceivables.some(({ status }) => status === ReceivableStatuses.PAID)
  }

  // feature toggle: remove the line below when the test is over
  const { isFeatureToggleEnabled } = useContractPaidAmountsReport()

  useEffect(() => {
    if (!contract) return
    changeContractStatus()
    // feature toggle: the left side of the condition below must be removed when the test is over
    setShowAnnualPaidAmountsStatement(isFeatureToggleEnabled && hasPaidReceivables(contract))
  }, [contract, isFeatureToggleEnabled])

  const ContractDetailsDrawerMap = {
    [ContractDetailsDrawerState.DEFAULT]: (
      <ContractDetailsDrawerDefaultContent
        contract={contract}
        contractStatus={contractStatus}
        setDrawerState={setDrawerState}
        isSignable={isSignable}
      />
    ),
    [ContractDetailsDrawerState.CONFIRM_MANUAL_SIGN]: (
      <ManualSignDrawer
        setDrawerState={setDrawerState}
        updateContractStatus={getContract}
        setIsFailureDialogOpen={setIsFailureDialogOpen}
        isSignable={isSignable}
      />
    ),
  }
  const HeaderTitleMap = {
    [ContractDetailsDrawerState.DEFAULT]: `Contrato ${contract?.reference_year}`,
    [ContractDetailsDrawerState.CONFIRM_MANUAL_SIGN]: 'Registrar assinatura',
  }
  const headerCheckoutTitle = `${contract?.product?.name} - ${contract?.reference_year}`

  const showGoBack = drawerState !== ContractDetailsDrawerState.DEFAULT
  return (
    <StyledDrawer anchor="right" variant="persistent" open={isOpen}>
      {!isCheckoutSchool ? (
        <ActionDrawerHeader
          title={HeaderTitleMap[drawerState]}
          onClose={onClose}
          onBack={showGoBack ? goBackToDefaultContent : undefined}
        />
      ) : (
        <ActionDrawerHeader
          title={headerCheckoutTitle}
          onClose={onClose}
          onBack={showGoBack ? goBackToDefaultContent : undefined}
        />
      )}
      {ContractDetailsDrawerMap[drawerState]}
      {!isCheckoutSchool && showAnnualPaidAmountsStatement && (
        <ButtonDocker>
          <Button
            variation="primary"
            fullWidth
            onClick={() => setIsConfirmationDialogVisible(true)}
            data-testid="generate-statement-button"
          >
            Gerar demonstrativo de pagamento
          </Button>
        </ButtonDocker>
      )}
      {isCheckoutSchool && (
        <ButtonDocker>
          <Button variation="primary" onClick={() => printCarne()}>
            Imprimir carnê
          </Button>
          <ContractCheckoutActions
            canEditDueDay={canEditDueDay}
            openDueDayEdition={openDueDayEdition}
            addContract={addContract}
            isReenrollable={isReenrollable}
            canEditDiscounts={canEditDiscounts}
            openDiscountsEdition={openDiscountsEdition}
            showAnnualPaidAmountsStatement={showAnnualPaidAmountsStatement}
            clickPaidAmountStatement={value => setIsConfirmationDialogVisible(value)}
            tooltip={tooltip}
          />
        </ButtonDocker>
      )}
    </StyledDrawer>
  )
}

export default ContractDetailsDrawer
