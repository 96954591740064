import { useMemo, useState } from 'react'
import { includes, propEq } from 'ramda'

import { Checkbox } from '@olaisaac/design-system'

import { Installment, ProcessedInstallment, ReceivableStatuses } from 'src/shared/interfaces'
import { TableAction } from 'src/escolas/components/Table/TableAction'
import { CombinableReceivable } from 'src/escolas/contexts/receivablesAgglutinationContext'

const findInstallment = (receivableId: string, installments: Installment[]) => {
  return installments?.find(installment =>
    installment?.receivables.some(propEq('id', receivableId))
  )
}

const getOriginalReceivables = (receivable: ProcessedInstallment, installments: Installment[]) => {
  const installment = findInstallment(receivable?.id, installments)
  const root = installment?.receivables?.find(r => r?.id === receivable?.id)
  if (!root?.original_receivables) return []
  return root?.original_receivables
}

type AgglutinationTableCheckboxParams = {
  addReceivable: (receivable: CombinableReceivable) => void
  clearCombinedReceivables: () => void
  combinedReceivables: Array<CombinableReceivable>
  installments: Installment[]
  isCombining: boolean
  processedInstallments: Array<ProcessedInstallment>
  removeReceivable: (receivable: CombinableReceivable) => void
}

export const makeAgglutinationCheckbox = ({
  addReceivable,
  clearCombinedReceivables,
  combinedReceivables,
  installments,
  isCombining,
  processedInstallments,
  removeReceivable,
}: AgglutinationTableCheckboxParams) => {
  const statusOk = status => {
    return includes(status, [
      ReceivableStatuses.OPEN,
      ReceivableStatuses.OVERDUE,
      ReceivableStatuses.DUE_TODAY,
    ])
  }
  const [isProcessingAgglutination, setProcessingAgglutination] = useState<boolean>(false)

  const shouldBeCombinable = (row: ProcessedInstallment) => {
    if (!row?.id) return true // HeaderCheckbox
    const isStatusOk = statusOk(row?.status)
    const originalReceivables = getOriginalReceivables(row, installments)

    const isSplit =
      isStatusOk &&
      originalReceivables?.length === 1 &&
      originalReceivables[0]?.status === ReceivableStatuses.RENEGOTIATED

    const installment = findInstallment(row?.id, installments)
    const isMultipleSplit =
      installment?.receivables.reduce((sum, current) => {
        if (statusOk(current?.status)) return sum + 1
        return sum
      }, 0) > 1

    return (isStatusOk && !isSplit) || (isSplit && !isMultipleSplit)
  }

  const isFromAgglutination = (row: ProcessedInstallment) => {
    const originalReceivables = getOriginalReceivables(row, installments)
    const hasAgglutinated =
      originalReceivables?.findIndex(r => r?.status === ReceivableStatuses.AGGLUTINATED) !== -1
    return hasAgglutinated
  }

  const createAvailableInstallments = () => {
    const installments = []
    const splits: Set<string> = new Set()
    processedInstallments?.forEach(pi => {
      // If it has children (i.e. is split), add the children to a blocklist
      if (!pi.id) pi.children?.forEach(r => splits.add(r.id))
      const isSplitMother = pi.status === ReceivableStatuses.RENEGOTIATED // Is this necessary?
      const isSplitChild = splits.has(pi.id)
      const isAgglutinationRoot = isFromAgglutination(pi)
      // Only add if it's OPEN, OVERDUE OR DUE_TODAY
      const isAgglutinable = shouldBeCombinable(pi)

      if (!isSplitMother && !isSplitChild && isAgglutinable && !isAgglutinationRoot) {
        installments.push(pi)
      }
    })
    return installments
  }
  const availableInstallments = useMemo(createAvailableInstallments, [processedInstallments])

  // TODO: the functions below are running for every row click, find a way to block this behavior (e.g. useCallback)
  const isSelectable = (row: ProcessedInstallment) => () => {
    const showable = shouldBeCombinable(row)
    const isAgglutinationRoot = isFromAgglutination(row)

    return isCombining && !row?.children && showable && !isAgglutinationRoot
  }

  const AgglutinationCheckbox = Component => row => (
    <TableAction Component={() => Component(row)} show={isSelectable(row)} />
  )

  const handleSelectRow = (e, row) => {
    if (e?.target?.checked) {
      addReceivable(row)
    } else {
      removeReceivable(row)
    }
  }

  const combineAllReceivables = () => {
    for (const receivable of availableInstallments) {
      addReceivable(receivable)
    }
  }

  const handleSelectHeader = (e, state) => {
    if (state.isIndeterminate || state.isChecked) {
      clearCombinedReceivables()
      return
    }
    combineAllReceivables()
  }

  const rowDisabledWhileAgglutinating = row => {
    const disabledWhileAgglutinating =
      isCombining &&
      includes(row?.status, [
        ReceivableStatuses.AGGLUTINATED,
        ReceivableStatuses.PAID,
        ReceivableStatuses.RENEGOTIATED,
      ])
    const agglutinated = includes(row?.status, [ReceivableStatuses.AGGLUTINATED])
    return disabledWhileAgglutinating || agglutinated
  }

  const AgglutinationRowCheckbox = row => (
    <Checkbox
      onChange={e => handleSelectRow(e, row)}
      checked={combinedReceivables.findIndex(rcv => rcv.id === row.id) !== -1}
      disabled={isProcessingAgglutination}
    />
  )

  const AgglutinationHeaderCheckbox = () => {
    const hasCombinedReceivables = combinedReceivables.length > 0
    const isIndeterminate =
      hasCombinedReceivables && combinedReceivables?.length < availableInstallments?.length
    const isChecked =
      hasCombinedReceivables && combinedReceivables?.length === availableInstallments?.length

    const checkedState = { isIndeterminate, isChecked }

    return (
      <Checkbox
        // indeterminate={checkedState.isIndeterminate}
        checked={checkedState.isChecked}
        onChange={e => handleSelectHeader(e, checkedState)}
        disabled={isProcessingAgglutination}
      />
    )
  }

  const RowCheckbox = AgglutinationCheckbox(AgglutinationRowCheckbox)
  const HeaderCheckbox = AgglutinationCheckbox(AgglutinationHeaderCheckbox)
  return {
    HeaderCheckbox,
    RowCheckbox,
    isProcessingAgglutination,
    isSelectable,
    rowDisabledWhileAgglutinating,
    setProcessingAgglutination,
  }
}
