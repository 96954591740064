import { FC } from 'react'
import styled from 'styled-components'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Container from '@material-ui/core/Container'
import IsaacLogo from './IsaacLogo'
import Menu from './Menu/Menu'

export type NavbarProps = {
  inverted?: boolean
  noShadow?: boolean
  showMenu?: boolean
}

const StyledToolbar = styled(Toolbar)`
  justify-content: space-between;
  flex: 1;
`

const Navbar: FC<NavbarProps> = ({ inverted = false, noShadow = false, showMenu = true }) => {
  const StyledAppBar = styled(AppBar)`
    background-color: ${inverted ? '#ffffff' : '#3d4ed7'};
    height: 56px;

    ${noShadow ? 'box-shadow: none' : ''}
  `

  return (
    <StyledAppBar position="fixed" color="inherit">
      <Container maxWidth="md">
        <StyledToolbar>
          <IsaacLogo inverted={inverted} />
          {showMenu && <Menu inverted={inverted} />}
        </StyledToolbar>
      </Container>
    </StyledAppBar>
  )
}

export default Navbar
