import { FC, useState, useEffect } from 'react'
import envConfig from 'src/config'
import { useParams } from 'react-router'
import dayjs, { Dayjs } from 'dayjs'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import MuiTypography from '@material-ui/core/Typography'
import ConfirmationDialog, {
  FailureFeedbackContent,
  FailureFeedbackDialog,
} from 'src/escolas/components/modal/ConfirmationDialog'
import {
  formatCentsToReal,
  date2PTFormat,
  isDayBeforeToday,
  isDayToday,
  dateToOnlyDateISO,
  formatToMonthYear,
} from 'src/shared/utils'
import {
  Contract,
  Receivable,
  ReceivableStatuses,
  PathParams,
  ProcessedInstallment,
  DebtStatus,
  Installment,
  InstallmentType,
  InstallmentTypeLabel,
  InstallmentStatuses,
} from 'src/shared/interfaces'
import { useApi, useJWT } from 'src/shared/hooks'
import { assoc, includes } from 'ramda'
import GuardianCard from 'src/escolas/components/contract/GuardianCard'
import StatusBadge, { StatusBadgeColor } from 'src/escolas/components/StatusBadge'
import Table, { TableColumn, TableColumns, TableRowParams } from 'src/escolas/components/Table'
import { makeAgglutinationCheckbox as makeCheckbox } from 'src/escolas/components/Table/AgglutinationCheckbox'
import ContractTableActions from 'src/escolas/components/contract/ContractTableActions'
import { useAgglutination, useContract, useNavigation } from 'src/escolas/hooks'
import { Button, ButtonGroup, Typography } from '@olaisaac/design-system'
import ContractDetailsFailureDialog from './ContractDetailsFailureDialog'
import CircularProgress from '@material-ui/core/CircularProgress'
import AgglutinationDrawer from './AgglutinationDrawer/AgglutinationDrawer'
import { useForm } from 'react-hook-form'
import InstallmentDrawer from 'src/escolas/components/contract/InstallmentDrawer'
import { formOfPaymentOptions } from './AgglutinationDrawer/AgglutinationPayment'
import CancellationDrawer from 'src/escolas/components/contract/CancellationDrawer'
import ContractDetailsDrawer from 'src/escolas/components/contract/ContractDetailsDrawer'
import {
  ContractEditDiscountsDrawer,
  ContractEditDueDayDrawer,
} from 'src/escolas/components/contract/ContractEditDrawer'
import { ContractDetailsDrawerState } from 'src/escolas/components/contract/ContractDetailsDrawer/constants'

import { InstallmentChange } from './ContractEditDrawer/ContractEditDueDayDrawer'
import TableFilter, {
  TableFilterOptions,
  TableFilterParam,
} from 'src/escolas/components/TableFilter'
import {
  StatementEventMetadataProps,
  useStatementEventHandler,
} from 'src/escolas/router/contrato/[contractId]/demonstrativo-pagos/statement'
import { parseUpdatedInstallments } from './ContractEditDrawer/utils'
import styled from 'styled-components'
import RouterBreadcrumbs from 'src/escolas/components/RouterBreadcrumbs/RouterBreadcrumbs'
import useInstallments from 'src/escolas/hooks/useInstallments'

const ContainerWithActionDrawer = styled(Grid)<{ $isActionDrawerOpen?: boolean }>(
  ({ $isActionDrawerOpen }) => ({
    transition:
      'flex-basis 0.3s cubic-bezier(0.42,0.29,0.39,0.83), max-width 0.3s cubic-bezier(0.42,0.29,0.39,0.83)',
    ...($isActionDrawerOpen
      ? { flexGrow: 0, maxWidth: '66.666667%', flexBasis: '66.666667%' }
      : {}),
  })
)

export const paramsDict: PartialRecord<
  ReceivableStatuses,
  { color: StatusBadgeColor; text: string }
> = {
  AGGLUTINATED: { color: 'grey', text: 'Aglutinada' },
  CANCELED: { color: 'grey', text: 'Cancelada' },
  DUE_TODAY: { color: 'warning', text: 'Vence hoje' },
  OPEN: { color: 'primary', text: 'A vencer' },
  OVERDUE: { color: 'error', text: 'Vencida' },
  PAID: { color: 'success', text: 'Paga' },
  PAID_AND_CANCELED: { color: 'grey', text: 'Paga e cancelada' },
  RENEGOTIATED: { color: 'grey', text: 'Renegociada' },
}

export const renderStatus = (status: ReceivableStatuses) => (
  <>
    <StatusBadge color={paramsDict[status]?.color} />
    <Box ml={1}>
      <MuiTypography variant="body2">{paramsDict[status]?.text}</MuiTypography>
    </Box>
  </>
)

export const debtParamsDict: PartialRecord<
  DebtStatus,
  { color: StatusBadgeColor; text: string }
> = {
  WARNING: { color: 'warning', text: 'Atenção' },
  UP_TO_DATE: { color: 'success', text: 'Em dia' },
  OVERDUE: { color: 'error', text: 'Com pendências' },
}

export const renderDebtStatus = (status: DebtStatus) => {
  return (
    <>
      <StatusBadge color={debtParamsDict[status]?.color} />
      <Box ml={1}>
        <Typography variation="subtitleDesktopMedium">{debtParamsDict[status]?.text}</Typography>
      </Box>
    </>
  )
}

const StyledTypography = styled(MuiTypography)`
  margin-top: 15px;
`

const getReceivableStatus = (
  receivable: Receivable,
  installmentStatus: InstallmentStatuses
): ReceivableStatuses => {
  if (
    receivable.status === ReceivableStatuses.PAID &&
    installmentStatus === InstallmentStatuses.CANCELED
  ) {
    return ReceivableStatuses.PAID_AND_CANCELED
  }
  if (receivable.status !== ReceivableStatuses.OPEN) {
    return receivable.status
  }
  if (isDayBeforeToday(dateToOnlyDateISO(receivable.due_date))) {
    return ReceivableStatuses.OVERDUE
  }
  if (isDayToday(dateToOnlyDateISO(receivable.due_date))) {
    return ReceivableStatuses.DUE_TODAY
  }
  return receivable.status
}

const getProcessedInstallmentId = (activeReceivable: Receivable, isSplitAgglutinated: boolean) => {
  if (isSplitAgglutinated) {
    return `installment::${activeReceivable?.installment_id}`
  } else {
    return activeReceivable?.id
  }
}

const buildProcessedInstallment = (
  receivable: Receivable,
  index: number,
  array: any[],
  children?: ProcessedInstallment[],
  key?: string
): ProcessedInstallment => {
  return {
    amount: receivable?.current_amount,
    children,
    due_date: receivable?.due_date,
    due_month: dayjs(receivable?.installment?.due_date).utc(),
    id: receivable?.id,
    installment_id: receivable?.installment_id,
    key: key || receivable?.id,
    orderReference: `${index + 1} de ${array.length}`,
    status: receivable?.status,
    type: receivable?.installment?.type,
  }
}

const recurBuildProcessedInstallment = (
  receivable: Receivable,
  processedReceivables: Receivable[],
  idx: number,
  arr: any[],
  isSplitAgglutinated?: boolean
) => {
  const id = getProcessedInstallmentId(receivable, isSplitAgglutinated)

  return buildProcessedInstallment(
    {
      ...receivable,
      id,
    },
    idx,
    arr,
    processedReceivables
      ?.filter(filterReceivable =>
        filterReceivable?.original_receivables?.some(someReceivable => {
          if (!receivable) {
            return false
          }
          let isCheckoutPaid = false

          if (
            someReceivable.status === ReceivableStatuses.CHECKOUTED &&
            filterReceivable.status === ReceivableStatuses.PAID
          ) {
            const checkoutedReceivable = processedReceivables.find(
              pr => pr.id === someReceivable.id
            )
            if (
              checkoutedReceivable &&
              checkoutedReceivable.original_receivables &&
              checkoutedReceivable.original_receivables.length > 0
            ) {
              isCheckoutPaid = checkoutedReceivable.original_receivables.some(
                or => or.id === receivable.id
              )
            }
          }

          return (
            (someReceivable.id === receivable.id || isCheckoutPaid) &&
            filterReceivable.status !== ReceivableStatuses.CHECKOUTED
          )
        })
      )
      .map((mapReceivable, mapIndex, mapArr) =>
        recurBuildProcessedInstallment(mapReceivable, processedReceivables, mapIndex, mapArr)
      ),
    `${receivable?.id}::split::${idx}`
  )
}

const isReceivableNotRenegotiatedOrAgglutinated = (receivable: Receivable): boolean => {
  return ![ReceivableStatuses.RENEGOTIATED, ReceivableStatuses.AGGLUTINATED].includes(
    receivable.status
  )
}

const isReceivableRenegotiatedOrAgglutinated = (receivable: Receivable): boolean => {
  return [ReceivableStatuses.RENEGOTIATED, ReceivableStatuses.AGGLUTINATED].includes(
    receivable.status
  )
}

const makeProcessedInstallment = (
  { receivables, status }: { receivables?: Receivable[]; status?: InstallmentStatuses },
  idx: number,
  arr: Installment[]
): ProcessedInstallment | ProcessedInstallment[] => {
  let processedReceivables = receivables
    ?.filter(rec => {
      if (rec.base_amount < 0) {
        return false
      }
      return includes(rec.status, [
        ReceivableStatuses.OPEN,
        ReceivableStatuses.PAID,
        ReceivableStatuses.OVERDUE,
        ReceivableStatuses.DUE_TODAY,
        ReceivableStatuses.AGGLUTINATED,
        ReceivableStatuses.RENEGOTIATED,
        ReceivableStatuses.CHECKOUTED,
      ])
    })
    ?.map(rec => assoc('status', getReceivableStatus(rec, status), rec))

  const activeReceivables = processedReceivables?.filter(({ status }) =>
    includes(status, [
      ReceivableStatuses.OPEN,
      ReceivableStatuses.PAID,
      ReceivableStatuses.OVERDUE,
      ReceivableStatuses.DUE_TODAY,
      ReceivableStatuses.PAID_AND_CANCELED,
    ])
  )

  const isCanceledWithNoActiveReceivables =
    status === InstallmentStatuses.CANCELED && activeReceivables.length === 0

  if (isCanceledWithNoActiveReceivables) {
    processedReceivables = [
      receivables
        .filter(rec => rec.status === ReceivableStatuses.CANCELED)
        .sort((a, b) => (dayjs(b.created_at).isBefore(dayjs(a.created_at)) ? -1 : 1))[0], // get the most recent receivable only
    ]
  }

  const agglutinatedReceivableIndex = processedReceivables?.findIndex(
    r => r?.status === ReceivableStatuses.AGGLUTINATED
  )

  const hasAgglutinatedReceivable = agglutinatedReceivableIndex !== -1
  const isSplitReceivable = activeReceivables?.length > 1

  const sortedProcessedReceivables = [...processedReceivables]?.sort((a, b) =>
    dayjs(b.created_at).isBefore(dayjs(a.created_at)) ? 1 : -1
  )
  const oldestRenegotiatedReceivableIndex = sortedProcessedReceivables?.findIndex(
    r => r?.status === ReceivableStatuses.RENEGOTIATED
  )
  const hasRenegotiatedReceivable = agglutinatedReceivableIndex !== -1

  const isSimpleRenegotiation =
    processedReceivables.length >= 2 &&
    processedReceivables.filter(isReceivableNotRenegotiatedOrAgglutinated).length === 1 &&
    processedReceivables.filter(isReceivableRenegotiatedOrAgglutinated).length ===
      processedReceivables.length - 1
  const hasMultipleReceivablesWithNoRenegotiation = isSplitReceivable && !hasRenegotiatedReceivable
  if (isSimpleRenegotiation || hasMultipleReceivablesWithNoRenegotiation) {
    return processedReceivables.map(receivable => buildProcessedInstallment(receivable, idx, arr))
  }

  const isSplitAgglutinated = isSplitReceivable && hasAgglutinatedReceivable

  if (hasAgglutinatedReceivable) {
    // Case: split simples (split dividido em uma parcela) que foi aglutinado com uma parcela de fora
    if (processedReceivables[agglutinatedReceivableIndex]?.original_receivables?.length > 0) {
      const original = processedReceivables[agglutinatedReceivableIndex]?.original_receivables[0]

      const hasOpen = activeReceivables?.length > 0

      if (original?.status === ReceivableStatuses.RENEGOTIATED && !hasOpen) {
        return buildProcessedInstallment(
          processedReceivables[agglutinatedReceivableIndex],
          idx,
          arr
        )
      }
    }
  }

  if (hasAgglutinatedReceivable && processedReceivables.length === 1) {
    // Problema: ao incluir receivables aglutinados em activeReceivables,
    // a funcao entenderia que o receivable que recebeu a aglutinacao eh um split (L176).
    // Ainda, ao nao inclui-los nessa lista, caso o receivable fosse um aglutinado,
    // ele nao mostraria o receivable, ja que a funcao usa o activeReceivables como default pra pegar os dados.
    return buildProcessedInstallment(processedReceivables[agglutinatedReceivableIndex], idx, arr)
  }

  const activeReceivable = isCanceledWithNoActiveReceivables
    ? processedReceivables?.[0]
    : activeReceivables?.[0]

  if (isSplitReceivable) {
    return recurBuildProcessedInstallment(
      sortedProcessedReceivables[oldestRenegotiatedReceivableIndex],
      processedReceivables,
      idx,
      arr,
      isSplitAgglutinated
    )
  }

  return buildProcessedInstallment(activeReceivable, idx, arr)
}

export const processInstallments = (resp: Contract): ProcessedInstallment[] =>
  resp?.installments.map(makeProcessedInstallment).flat()

export let installmentsTableColumns: TableColumn[]

const makeInstallmentsTableColumns = isAdmin => {
  const InstallmentTypeMap = {
    [InstallmentType.ENROLLMENT]: InstallmentTypeLabel.ENROLLMENT,
    [InstallmentType.TUITION]: InstallmentTypeLabel.TUITION,
  }

  installmentsTableColumns = [{ headerName: 'Parcela', field: 'orderReference' }]

  if (isAdmin) {
    installmentsTableColumns.push({
      headerName: 'Competência',
      field: 'due_month',
      valueFormatter: value => formatToMonthYear(`${value}`),
    })
  }

  installmentsTableColumns.push(
    {
      headerName: 'Vencimento',
      field: 'due_date',
      valueFormatter: value => date2PTFormat(dayjs(value).toISOString()),
    },
    {
      headerName: 'Valor',
      field: 'amount',
      valueFormatter: value => formatCentsToReal(value),
    },
    {
      headerName: 'Tipo',
      field: 'type',
      valueFormatter: value => InstallmentTypeMap[value],
    },
    {
      headerName: 'Situação',
      field: 'status',
      renderCell: value => renderStatus(ReceivableStatuses[value]),
    }
  )

  return installmentsTableColumns
}

export const tableHeaderCheckboxColumn: TableColumn = { field: '', isComponent: true, small: true }
export type AgglutinationMetaData = {
  discount: cents
  due_date: Dayjs
  payment_method: string[]
}

export type InstallmentsFilters = {
  name: TableFilterParam<string>
  status: TableFilterParam<InstallmentType>
}

const defaultFilters = {
  name: { value: '' },
  status: { label: null, value: null },
}

const ContractDetails: FC = () => {
  const { isAdmin } = useJWT()
  const { school } = useNavigation()
  const { api } = useApi()
  const {
    addReceivable,
    clearCombinedReceivables,
    combinedReceivables,
    isCombining,
    removeReceivable,
    setIsCombining,
  } = useAgglutination()
  const [selectedReceivableId, setSelectedReceivableId] = useState<string>(null)
  const [orderReference, setOrderReference] = useState<string>(null)
  const { contract, setContract } = useContract()
  const {
    processedInstallments,
    setProcessedInstallments,
    setSelectedProcessedInstallment,
  } = useInstallments()
  const [installmentsToChangeDueDay, setInstallmentsToChangeDueDay] = useState<
    Array<InstallmentChange>
  >([])
  const [installmentsToEditDiscounts, setInstallmentsToEditDiscounts] = useState<
    Array<InstallmentChange>
  >([])

  const { contractId, schoolSlug } = useParams<PathParams>()
  const [tableColumns, setTableColumns] = useState<TableColumns>(installmentsTableColumns)
  const [showContractDrawer, setShowContractDrawer] = useState<boolean>(false)
  const [showCancellationDrawer, setShowCancellationDrawer] = useState<boolean>(false)
  const [showEditContractDrawer, setShowEditContractDrawer] = useState<boolean>(false)
  const [showContractEditDiscountsDrawer, setShowContractEditDiscountsDrawer] = useState<boolean>(
    false
  )

  const [isFailureDialogOpen, setIsFailureDialogOpen] = useState<boolean>(false)
  const [isFailureFeedbackOpen, setIsFailureFeedbackOpen] = useState<boolean>(false)
  const [resetManualLiquidationForm, setResetManualLiquidationForm] = useState<boolean>(false)

  const [drawerState, setDrawerState] = useState<ContractDetailsDrawerState>(
    ContractDetailsDrawerState.DEFAULT
  )

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [filters, setFilters] = useState<InstallmentsFilters>(defaultFilters)
  const [filteredAndProcessedInstallments, setFilteredAndProcessedInstallments] = useState(
    processedInstallments
  )

  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] = useState<boolean>(false)
  const filterOptions: Array<TableFilterOptions> = [
    {
      filterName: 'status',
      filterTitle: 'tipo de cobrança',
      labelOptions: InstallmentTypeLabel,
    },
  ]

  const formAgglutination = useForm<AgglutinationMetaData>({
    mode: 'onChange',
  })
  const getContract = () => {
    api.contracts
      .get(contractId, {
        include_guardian: true,
        include_installments: true,
        include_invoice: true,
        include_product: true,
        include_receivables: true,
        include_signable_document: true,
        include_student: true,
      })
      .then(setContract)
      .then(() => setIsLoading(false))
  }
  const getEditDueDayInstallments = () => {
    if (!contract) return
    api.contracts
      .changeDueDayInfo(contract.id, {
        change_due_month: false,
        due_day: +contract.due_day,
        installment_id: contract.installments[0].id,
        start_month: dayjs().hour(0).minute(0).second(0).millisecond(0).utc().hour(0).format(),
      })
      .then(data => setInstallmentsToChangeDueDay(parseUpdatedInstallments(data)))
  }

  const getEditDiscountsInstallments = () => {
    if (!contract) return
    api.contracts
      .bulkEditDiscountsInfo(contract.id, {
        installment_id: contract.installments[0].id,
        get_current_amount: true,
        discounts: [],
      })
      .then(data => setInstallmentsToEditDiscounts(parseUpdatedInstallments(data)))
  }

  useEffect(() => {
    setContract(null)
    setProcessedInstallments([])
    if (!contractId) return
    getContract()
  }, [contractId])

  useEffect(() => {
    const processedInstallments = processInstallments(contract)
    setProcessedInstallments(processedInstallments)
    getEditDueDayInstallments()
    getEditDiscountsInstallments()
  }, [contract])

  const {
    status: { value },
  } = filters

  useEffect(() => {
    const filteredInstallments = value
      ? processedInstallments?.filter(installment => installment.type === value)
      : processedInstallments
    setFilteredAndProcessedInstallments(filteredInstallments)
  }, [processedInstallments, value])

  const {
    HeaderCheckbox,
    RowCheckbox,
    rowDisabledWhileAgglutinating,
    isSelectable,
    isProcessingAgglutination,
    setProcessingAgglutination,
  } = makeCheckbox({
    addReceivable,
    clearCombinedReceivables,
    combinedReceivables,
    installments: contract?.installments,
    isCombining,
    processedInstallments,
    removeReceivable,
  })

  const handleAgglutinationRowClick = row => {
    const receivable = combinedReceivables.find(r => r?.id === row?.id)
    if (!receivable) {
      const isRowSelectable = isSelectable(row)()

      if (isRowSelectable) {
        addReceivable(row)
        return
      }
    }
    removeReceivable(row)
  }

  const handleInstallmentDrawerClose = () => {
    setSelectedReceivableId(null)
  }

  const handleAgglutinationClose = () => {
    clearCombinedReceivables()
    formAgglutination.reset({
      due_date: dayjs().add(1, 'day').toISOString(),
      payment_method: formOfPaymentOptions.filter(option => option.key === 'PIX_BANKSLIP')[0].value,
      discount: 0,
    })
    setIsCombining(false)
    setSelectedReceivableId(null)
  }

  const handleContractDrawerClose = () => {
    setShowContractDrawer(false)
  }

  const handleCancellationDrawerClose = () => {
    setShowCancellationDrawer(false)
  }

  const handleEditContractDrawerClose = () => {
    setShowEditContractDrawer(false)
  }

  const handleContractEditDiscountsDrawerClose = () => {
    setShowContractEditDiscountsDrawer(false)
  }

  const closeAllDrawers = () => {
    handleCancellationDrawerClose()
    handleInstallmentDrawerClose()
    handleAgglutinationClose()
    handleContractDrawerClose()
    handleEditContractDrawerClose()
    handleContractEditDiscountsDrawerClose()
  }

  const handleCancellationDrawerOpen = () => {
    closeAllDrawers()
    setShowCancellationDrawer(true)
  }

  const resetManualLiquidationFormErrors = () => {
    setResetManualLiquidationForm(true)
    setIsFailureFeedbackOpen(false)
  }

  const handleRowClick = (params: TableRowParams<ProcessedInstallment>, e: any = null) => {
    if (
      e?.target?.type === 'checkbox' ||
      [ReceivableStatuses.CANCELED, ReceivableStatuses.PAID_AND_CANCELED].includes(
        ReceivableStatuses[params.row.status]
      )
    )
      return
    if (isProcessingAgglutination) return
    if (isCombining) {
      handleAgglutinationRowClick(params?.row)
      return
    }
    closeAllDrawers()
    setSelectedReceivableId(params?.row?.id)
    setOrderReference(params?.row?.orderReference)
    setSelectedProcessedInstallment(params?.row)
  }

  const handleManageContractClick = () => {
    closeAllDrawers()
    setDrawerState(ContractDetailsDrawerState.DEFAULT)
    setShowContractDrawer(true)
  }

  const handleEditContractDrawerClick = () => {
    closeAllDrawers()
    setShowEditContractDrawer(true)
  }

  const handleContractEditDiscountsDrawerClick = () => {
    closeAllDrawers()
    setShowContractEditDiscountsDrawer(true)
  }

  const handleAgglutinationClick = () => {
    closeAllDrawers()
    setIsCombining(true)
  }

  const showAgglutinationDrawer = isCombining && combinedReceivables?.length >= 1
  useEffect(() => {
    const formattedTableColumns = makeInstallmentsTableColumns(isAdmin)
    if (!isCombining) {
      setTableColumns(formattedTableColumns)
    } else {
      setTableColumns([tableHeaderCheckboxColumn, ...formattedTableColumns])
    }
  }, [isCombining, contract])
  useEffect(() => {
    if (!combinedReceivables?.length) {
      formAgglutination.reset({
        due_date: dayjs().add(1, 'day').toISOString(),
        payment_method: formOfPaymentOptions.filter(option => option.key === 'PIX_BANKSLIP')[0]
          .value,
        discount: 0,
      })
    }
  }, [combinedReceivables])

  const sendStatementEvent = useStatementEventHandler()

  const showStatement = () => {
    const metadata: StatementEventMetadataProps = {
      contract_id: contractId,
      product_id: contract.product_id,
    }
    sendStatementEvent(metadata)
    window.open(`/${schoolSlug}/contrato/${contract.id}/demonstrativo-pagos`)
    setIsConfirmationDialogVisible(false)
  }

  const getDaysSinceContractCreation = () => {
    const contractCreationDate = dayjs(contract?.created_at).utc().format()
    const today = dayjs().utc().format()

    return Number(dayjs(today).diff(contractCreationDate, 'day'))
  }

  const hasInstallmentsToChangeDueDay = installmentsToChangeDueDay.length > 0

  const maxDaysToChangeContractDueDay = Number(envConfig.MAX_DAYS_TO_CHANGE_CONTRACT_DUE_DAY)
  const maxDaysToChangeContractDueDayIsEnabled = maxDaysToChangeContractDueDay >= 0

  const canEditDueDay = () => {
    if (isAdmin || !maxDaysToChangeContractDueDayIsEnabled) {
      return hasInstallmentsToChangeDueDay
    }

    return (
      hasInstallmentsToChangeDueDay &&
      getDaysSinceContractCreation() <= maxDaysToChangeContractDueDay
    )
  }

  const isAnyDrawerOpen =
    Boolean(selectedReceivableId) ||
    showAgglutinationDrawer ||
    showContractDrawer ||
    showEditContractDrawer ||
    showContractEditDiscountsDrawer ||
    showCancellationDrawer

  return (
    <>
      <Typography
        variation="subtitleDesktopMedium"
        color="secondary"
        style={{ marginTop: 40, marginBottom: 12 }}
      >
        {school.name}
      </Typography>

      <RouterBreadcrumbs />

      <Grid container>
        {isLoading && (
          <Box
            top={300}
            left={50}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        )}
        <ContainerWithActionDrawer item xs={12} $isActionDrawerOpen={isAnyDrawerOpen}>
          <ContractDetailsFailureDialog
            isVisible={isFailureDialogOpen}
            setIsFailureDialogOpen={setIsFailureDialogOpen}
          />
          <Box>
            <Box py={2} textAlign="right">
              <ButtonGroup>
                <Button
                  disabled={isProcessingAgglutination}
                  variation="ghost"
                  onClick={handleManageContractClick}
                  style={{ marginTop: '-58px' }}
                >
                  Ver contrato
                </Button>
              </ButtonGroup>
            </Box>
            <Box pb={6}>
              <GuardianCard
                isProcessingAgglutination={isProcessingAgglutination}
                openCancellationDrawer={handleCancellationDrawerOpen}
              />
            </Box>
            <Box pb={2} display="flex" justifyContent="flex-end" alignItems="center">
              <TableFilter
                filters={filters}
                filtersSetter={setFilters}
                filterOptions={filterOptions}
              />
            </Box>
            <Box position="relative" pb={4}>
              <Table<ProcessedInstallment>
                columns={tableColumns}
                rows={filteredAndProcessedInstallments}
                onRowClick={handleRowClick}
                rowsSelected={isCombining ? combinedReceivables : [selectedReceivableId]}
                RowActionComponent={RowCheckbox}
                HeaderActionComponent={HeaderCheckbox}
                rowDisabled={rowDisabledWhileAgglutinating}
                rowTextDisabled={({ status }) =>
                  [ReceivableStatuses.CANCELED, ReceivableStatuses.PAID_AND_CANCELED].includes(
                    ReceivableStatuses[status]
                  )
                }
              />
              <ContractTableActions
                contractId={contractId}
                guardianId={contract?.guardian_id}
                isAgglutination={isCombining}
                canEditDueDay={canEditDueDay}
                hasInstallmentsToChangeDueDay={hasInstallmentsToChangeDueDay}
                canEditDiscounts={installmentsToEditDiscounts.length > 0}
                openDueDayEdition={handleEditContractDrawerClick}
                openDiscountsEdition={handleContractEditDiscountsDrawerClick}
                openAgglutination={handleAgglutinationClick}
                closeAgglutination={handleAgglutinationClose}
                isProcessingAgglutination={isProcessingAgglutination}
              />
            </Box>
          </Box>
        </ContainerWithActionDrawer>
      </Grid>

      <div>
        <InstallmentDrawer
          isOpen={Boolean(selectedReceivableId)}
          onClose={handleInstallmentDrawerClose}
          selectedReceivableId={selectedReceivableId}
          orderReference={orderReference}
          setSelectedReceivableId={setSelectedReceivableId}
          resetManualLiquidationForm={resetManualLiquidationForm}
          setIsFailureFeedbackOpen={setIsFailureFeedbackOpen}
        />
        <AgglutinationDrawer
          isOpen={showAgglutinationDrawer}
          onClose={handleAgglutinationClose}
          selectedReceivableId={selectedReceivableId}
          orderReference={orderReference}
          receivables={combinedReceivables}
          form={formAgglutination}
          setContract={setContract}
          isProcessingAgglutination={isProcessingAgglutination}
          disableChecks={() => setProcessingAgglutination(true)}
          enableChecks={() => setProcessingAgglutination(false)}
        />
        <ContractDetailsDrawer
          contract={contract}
          getContract={getContract}
          isOpen={showContractDrawer}
          onClose={handleContractDrawerClose}
          setIsFailureDialogOpen={setIsFailureDialogOpen}
          drawerState={drawerState}
          setDrawerState={setDrawerState}
          setIsConfirmationDialogVisible={setIsConfirmationDialogVisible}
        />
        <ContractEditDueDayDrawer
          isOpen={showEditContractDrawer}
          onClose={handleEditContractDrawerClose}
          availableInstallments={installmentsToChangeDueDay}
        />

        <ContractEditDiscountsDrawer
          isOpen={showContractEditDiscountsDrawer}
          onClose={handleContractEditDiscountsDrawerClose}
          availableInstallments={installmentsToEditDiscounts}
        />

        <CancellationDrawer
          isOpen={showCancellationDrawer}
          onClose={handleCancellationDrawerClose}
          processedInstallments={processedInstallments}
          onCancellationSuccess={() => {
            getContract()
            handleCancellationDrawerClose()
          }}
        />
        <ConfirmationDialog
          isVisible={isConfirmationDialogVisible}
          onClose={() => setIsConfirmationDialogVisible(false)}
          submitHandler={showStatement}
          buttonLabel="Ok, gerar demonstrativo"
          title="Aviso"
        >
          <MuiTypography>
            O demonstrativo só apresenta valores pagos que são de competência do isaac, ou seja,
            valores anteriores à parceria não constam no demonstrativo.
          </MuiTypography>
          <StyledTypography>
            Este informativo também poderá ser utilizado para declaração de imposto de renda.
          </StyledTypography>
        </ConfirmationDialog>
        <FailureFeedbackDialog
          buttonLabel="Voltar"
          isVisible={isFailureFeedbackOpen}
          onClose={resetManualLiquidationFormErrors}
          submitHandler={resetManualLiquidationFormErrors}
          title="Volte e registre novamente"
          centered
        >
          <FailureFeedbackContent message="Houve uma falha inesperada e não conseguimos registrar o recebimento." />
        </FailureFeedbackDialog>
      </div>
    </>
  )
}

export default ContractDetails
