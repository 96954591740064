import { FC } from 'react'
import * as FullStory from '@fullstory/browser'
import Box from '@material-ui/core/Box'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import envConfig from 'src/config'
import { InstallmentStatuses, Invoice } from 'src/shared/interfaces'
import { EVENT_CATEGORIES, EVENT_GROUPS, REALMS } from 'src/shared/constants'
import SendEvent from 'src/shared/api/eventsClient'
import { ReceivableAccordionData } from '.'
import ExpiredInvoiceMenu from './ExpiredInvoiceMenu'
import { StyledButton } from './ReceivableAccordion'

type PayOrContactButtonsProps = {
  eventHandlerCopy: (barcode: string) => () => void
  eventHandlerOpen: (checkoutUrl: string) => () => void
  guardianId: uuid
  invoice: Invoice
  isFetching?: boolean
  modalHandler: (e: () => void) => () => void
  productName: string
  props: ReceivableAccordionData
  showWarningModal: boolean
}

const PayOrContactButtons: FC<PayOrContactButtonsProps> = ({
  props,
  showWarningModal,
  guardianId,
  invoice,
  isFetching,
  modalHandler,
  eventHandlerCopy,
  eventHandlerOpen,
  productName,
}) => {
  const today = new Date()
  const datePlus30 = props.dueDate.add(30, 'day')

  const paymentDateExpired = datePlus30.isBefore(today)

  const clickOpenHandle = (() => {
    const eventHandler = () => {
      if (!invoice.checkout_url) return
      const { checkout_url } = invoice
      const handlerFn = eventHandlerOpen(checkout_url)
      const handler = showWarningModal ? modalHandler(handlerFn) : handlerFn

      SendEvent({
        category: EVENT_CATEGORIES.INVOICE_CATEGORY,
        group: EVENT_GROUPS.GUARDIANS_GROUP,
        metadata: { invoice_id: invoice.id },
        realm: REALMS.GUARDIAN_REALM,
        type: 'invoice-opened',
        user_id: guardianId,
      })
      handler()
    }

    if (envConfig.FULLSTORY_ORG_ID) {
      return function () {
        FullStory.event('invoice_open_request', {
          guardian_id: guardianId,
        })

        eventHandler()
      }
    }

    return eventHandler
  })()

  const clickCopyHandle = (code, codeType) =>
    (() => {
      const eventHandler = () => {
        if (!code) return
        const handlerFn = eventHandlerCopy(code)
        const handler = showWarningModal ? modalHandler(handlerFn) : handlerFn

        SendEvent({
          category: EVENT_CATEGORIES.INVOICE_CATEGORY,
          group: EVENT_GROUPS.GUARDIANS_GROUP,
          metadata: { invoice_id: invoice.id, code_type: codeType },
          realm: REALMS.GUARDIAN_REALM,
          type: 'invoice-code-copied',
          user_id: guardianId,
        })
        handler()
      }
      if (envConfig.FULLSTORY_ORG_ID) {
        return function () {
          FullStory.event('invoice_code_copy_request', {
            guardian_id: guardianId,
            code_type: codeType,
          })

          eventHandler()
        }
      }

      return eventHandler
    })()

  type CopyAndDownloadButtonProps = {
    isFullWidth?: boolean
  }

  const CopyAndDownloadButton: FC<CopyAndDownloadButtonProps> = ({ isFullWidth }) => {
    const digitableLine = invoice?.bank_slip?.digitable_line
    const pixQrCodeText = invoice?.additional_informations?.payment_method?.PixQrCodeText

    const hasPix = invoice?.available_payment_methods?.includes('pix') && pixQrCodeText
    const pixButton = hasPix ? (
      <StyledButton
        variation="ghost"
        fullWidth={isFullWidth}
        onClick={clickCopyHandle(pixQrCodeText, 'pix')}
      >
        Copiar chave Pix
      </StyledButton>
    ) : null

    return isFetching ? (
      <Box py={6}>
        <Typography variant="caption">Gerando boleto</Typography>
        <LinearProgress variant="indeterminate" />
      </Box>
    ) : (
      <Box display="flex" flexDirection="column" mb={2}>
        <StyledButton variation="primary" fullWidth={isFullWidth} onClick={clickOpenHandle}>
          Acessar fatura
        </StyledButton>
        <StyledButton
          variation="ghost"
          fullWidth={isFullWidth}
          onClick={clickCopyHandle(digitableLine, 'bank_slip')}
        >
          Copiar código do boleto
        </StyledButton>
        {pixButton}
      </Box>
    )
  }

  if (props.status === InstallmentStatuses.PAID) {
    return null
  }

  if (paymentDateExpired) {
    return <ExpiredInvoiceMenu productName={productName} dueDate={props.dueDate} />
  }
  return (
    <>
      <Hidden mdUp>
        <CopyAndDownloadButton isFullWidth />
      </Hidden>
      <Hidden smDown>
        <Box display="flex" alignItems="flex-end" flexDirection="column">
          <CopyAndDownloadButton isFullWidth />
        </Box>
      </Hidden>
    </>
  )
}

export default PayOrContactButtons
