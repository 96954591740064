import { FC, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { useForm, Controller } from 'react-hook-form'
import {
  Button,
  DialogContent,
  Radio,
  FormControlLabel,
  ActionDrawerHeader,
  ButtonDocker,
  Notification,
} from '@olaisaac/design-system'
import Box from '@material-ui/core/Box'
import Drawer from '@material-ui/core/Drawer'
import MenuItem from '@material-ui/core/MenuItem'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import InputLabel from '@material-ui/core/InputLabel'
import { propEq } from 'ramda'
import { ContractCancellationReason, ProcessedInstallment } from 'src/shared/interfaces'
import { useContract, useSnackbar } from 'src/escolas/hooks'
import { useApi } from 'src/shared/hooks'
import { date2PTFormat, formatCentsToReal, formatToMonthYear } from 'src/shared/utils'

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    width: calc(((100% - 160px) / 12) * 4);
    transition: transform 0.3s cubic-bezier(0.42, 0.29, 0.39, 0.83) !important;
  }
`

export const contractCancellationReasonOptions = [
  { name: 'Alteração da data', value: ContractCancellationReason.WRONG_DATE },
  { name: 'Alteração de valor', value: ContractCancellationReason.WRONG_AMOUNT },
  {
    name: 'Alteração dos dados do responsável',
    value: ContractCancellationReason.WRONG_GUARDIAN_INFO,
  },
  { name: 'Aluno saiu da escola', value: ContractCancellationReason.STUDENT_LEFT },
  { name: 'Contrato duplicado', value: ContractCancellationReason.DUPLICATED_CONTRACT },
  { name: 'Outro (digite)', value: ContractCancellationReason.OTHER },
]

type ContractCancellationForm = {
  cancellation_description: string
  cancellation_reason: ContractCancellationReason
  installment_id: uuid
}

export type CancellationDrawerProps = {
  isOpen: boolean
  onCancellationSuccess: () => void
  onClose: () => void
  processedInstallments: ProcessedInstallment[]
}

const CancellationDrawer: FC<CancellationDrawerProps> = ({
  isOpen,
  onClose,
  processedInstallments,
  onCancellationSuccess,
}) => {
  const { api } = useApi()
  const { contract } = useContract()
  const form = useForm<ContractCancellationForm>({
    mode: 'all',
    defaultValues: {
      installment_id: '',
      cancellation_description: '',
    },
  })
  const {
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    formState: { isSubmitting, isValid, isDirty, errors },
  } = form
  const [isOnTheFirstPage, setIsOnTheFirstPage] = useState(true)
  const { setMessage: setSnackbarMessage, setIsOpen: setSnackbarIsOpen } = useSnackbar()

  const submitHandler = async (form: ContractCancellationForm) => {
    const { cancellation_reason, installment_id, cancellation_description } = form
    return await api.contracts
      .revoke(contract?.id, { cancellation_reason, installment_id, cancellation_description })
      .then(() => {
        setSnackbarMessage('Contrato cancelado com sucesso.')
        setSnackbarIsOpen(true)
        onCancellationSuccess()
        setIsOnTheFirstPage(true)
        reset()
      })
  }

  watch(['cancellation_reason', 'installment_id', 'cancellation_description'])
  const { cancellation_reason, installment_id, cancellation_description } = getValues()
  const isReasonOther = cancellation_reason === ContractCancellationReason.OTHER
  const isNextPageButtonEnabled =
    Boolean(cancellation_reason) && (!isReasonOther || Boolean(cancellation_description))
  const isActionButtonDisabled =
    !isNextPageButtonEnabled || (!isOnTheFirstPage && (isSubmitting || !isValid || !isDirty))
  const invalidDescription = Boolean(errors?.cancellation_description)

  const installment = contract?.installments?.find(propEq('id', installment_id))
  const orderReference = processedInstallments?.find(propEq('installment_id', installment_id))
    ?.orderReference

  useEffect(() => {
    if (!isReasonOther) {
      setValue('cancellation_description', '', { shouldValidate: true })
    }
  }, [isReasonOther])

  return (
    <StyledDrawer anchor="right" variant="persistent" open={isOpen}>
      <ActionDrawerHeader
        onBack={!isOnTheFirstPage ? () => setIsOnTheFirstPage(true) : undefined}
        onClose={onClose}
        title="Cancelar contrato"
      />

      <Notification
        description="As parcelas canceladas serão removidas dos próximos repasses da escola."
        title="Essa ação é irreversível e altera o repasse"
        variation="warning"
      />

      <DialogContent>
        <Box display={isOnTheFirstPage ? 'block' : 'none'}>
          <Box my={3}>
            <Typography variant="subtitle1">Qual é o motivo do cancelamento?</Typography>
          </Box>
          <FormControl component="fieldset">
            <Controller
              rules={{ required: true }}
              control={control}
              name="cancellation_reason"
              defaultValue=""
              render={({ field }) => (
                <RadioGroup aria-label="source" {...field}>
                  {contractCancellationReasonOptions.map(({ name, value }) => (
                    <FormControlLabel key={value} value={value} control={<Radio />} label={name} />
                  ))}
                </RadioGroup>
              )}
            />
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <Controller
              rules={{
                required: isReasonOther,
              }}
              name="cancellation_description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="cancellation_description"
                  disabled={!isReasonOther}
                  variant="outlined"
                  label="Motivo"
                  error={invalidDescription}
                  helperText={invalidDescription ? 'Esse campo é obrigatório' : ''}
                />
              )}
            />
          </FormControl>
        </Box>
        <Box display={isOnTheFirstPage ? 'none' : 'block'}>
          <Box mb={2.5}>
            <Typography variant="subtitle1">A partir de qual parcela?</Typography>
          </Box>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="installment_id">Número da parcela</InputLabel>
            <Controller
              rules={{ required: true }}
              control={control}
              name="installment_id"
              defaultValue=""
              render={({ field: { value, onChange } }) => (
                <Select
                  labelId="installment_id"
                  label="Número da parcela"
                  value={value}
                  onChange={e => onChange(e.target.value)}
                  fullWidth
                >
                  {contract?.installments?.map(({ id, due_date, amount }, idx) => (
                    <MenuItem key={id} value={id}>
                      {`${idx + 1}. ${formatToMonthYear(due_date)} - ${formatCentsToReal(amount)}`}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
          {installment_id && (
            <Box mt={4}>
              <Typography>O cancelamento será feito a partir da seguinte parcela:</Typography>
              <Box mt={2.5} display="flex" justifyContent="space-between">
                <Typography color="textSecondary" variant="body2">
                  Parcela
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  {orderReference}
                </Typography>
              </Box>
              <Box mt={2} display="flex" justifyContent="space-between">
                <Typography color="textSecondary" variant="body2">
                  Valor da parcela
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  {formatCentsToReal(installment?.amount)}
                </Typography>
              </Box>
              <Box mt={2} display="flex" justifyContent="space-between">
                <Typography color="textSecondary" variant="body2">
                  Vencimento
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  {date2PTFormat(dayjs(installment?.due_date).toISOString())}
                </Typography>
              </Box>
              <Box mt={2} display="flex" justifyContent="space-between">
                <Typography color="textPrimary" variant="body2">
                  Parcelas alteradas
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  {date2PTFormat(dayjs(installment?.due_date).toISOString())}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>

      <ButtonDocker>
        <Button
          type={isOnTheFirstPage ? 'button' : 'submit'}
          onClick={
            isOnTheFirstPage ? () => setIsOnTheFirstPage(false) : handleSubmit(submitHandler)
          }
          disabled={isActionButtonDisabled}
          loading={isSubmitting}
          fullWidth
        >
          {isOnTheFirstPage ? 'Próximo' : 'Confirmar'}
        </Button>
      </ButtonDocker>
    </StyledDrawer>
  )
}

export default CancellationDrawer
