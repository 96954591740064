import { useContext } from 'react'
import { useTreatments, SplitContext } from '@splitsoftware/splitio-react'

export const useSplitIO = (feature: string) => {
  const { isReady } = useContext(SplitContext)
  const treatments = useTreatments([feature])
  const { treatment: value, config } = treatments[feature]

  return {
    value,
    config,
    feature,
    isLoading: !isReady,
  }
}
