import { Dispatch, FC, SetStateAction } from 'react'
import Box from '@material-ui/core/Box'
import { PathParams } from 'src/shared/interfaces'
import { ManualSignDrawerActions } from 'src/escolas/components/contract/ManualSignDrawer/ManualSignDrawerActions'
import { useApi } from 'src/shared/hooks'
import { useParams } from 'react-router-dom'
import {
  EnrollmentEventType,
  useSendEnrollmentEvent,
} from 'src/escolas/router/[schoolSlug]/contratos/enrollmentEvents'
import { useSnackbar } from 'src/escolas/hooks'
import { ContractDetailsDrawerState } from 'src/escolas/components/contract/ContractDetailsDrawer/constants'
import { Typography, DialogContent } from '@olaisaac/design-system'

export type ManualSignDrawerProps = {
  isSignable: boolean
  setDrawerState: Dispatch<SetStateAction<ContractDetailsDrawerState>>
  setIsFailureDialogOpen: (value: boolean) => void
  updateContractStatus: () => void
}

const ManualSignDrawer: FC<ManualSignDrawerProps> = ({
  updateContractStatus,
  setIsFailureDialogOpen,
  setDrawerState,
  isSignable,
}) => {
  const { api } = useApi()
  const { contractId } = useParams<PathParams>()
  const sendEnrollmentEvent = useSendEnrollmentEvent()
  const {
    setMessage: setSnackbarMessage,
    setIsOpen: setSnackbarIsOpen,
    isOpen: isSnackbarOpen,
  } = useSnackbar()

  const submitHandler = async () => {
    if (!contractId) return
    await api.contracts
      .manualSign(contractId)
      .then(() => {
        updateContractStatus()
        setSnackbarMessage('Contrato salvo como assinado')
        setSnackbarIsOpen(true)
        !isSnackbarOpen && setDrawerState(ContractDetailsDrawerState.DEFAULT)
        sendEnrollmentEvent(EnrollmentEventType.saveAsSignedClicked, {
          contract_id: contractId,
        })
      })
      .catch(error => {
        const errorMessage = error.data.errors[0]?.message
        sendEnrollmentEvent(EnrollmentEventType.saveAsSignedErrorShowed, {
          contract_id: contractId,
          error: errorMessage,
        })
        setIsFailureDialogOpen(true)
        setDrawerState(ContractDetailsDrawerState.DEFAULT)
      })
  }

  const chooseDrawerActions = (isSignable, submitHandler) =>
    isSignable ? <ManualSignDrawerActions submitHandler={submitHandler} /> : null

  const drawerActions = chooseDrawerActions(isSignable, submitHandler)

  return (
    <>
      <DialogContent>
        <Typography variation="bodyLarge">
          Ao confirmar, a escola assegura que o contrato foi{' '}
          <strong>assinado em via impressa</strong> por todas as partes.
        </Typography>
        <Box py={2}>
          <Typography variation="bodyLarge">
            O responsável não receberá mais lembretes de assinatura via e-mail.
          </Typography>
        </Box>
      </DialogContent>

      {drawerActions}
    </>
  )
}

export default ManualSignDrawer
