import Container from '@material-ui/core/Container'
import dayjs from 'dayjs'
import { append } from 'ramda'
import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import HeadNavigation from 'src/responsaveis/components/HeadNavigation'
import Navbar from 'src/responsaveis/components/Navbar'
import ReceivableAccordion, {
  ReceivableAccordionData,
} from 'src/responsaveis/components/ReceivableAccordion'
import { useApi } from 'src/shared/hooks'
import { Product, ReceivableStatuses } from 'src/shared/interfaces'

export type ReceivablePageParam = {
  contractId: string
}

const buildReceivablesData = (stateSet, installments) => {
  let receivables = []

  for (let i = 0; i < installments.length; i++) {
    const ins = installments[i]

    for (let j = 0; j < ins.receivables.length; j++) {
      const receivable = ins.receivables[j]

      if (
        receivable.status === ReceivableStatuses.CANCELED ||
        receivable.status === ReceivableStatuses.RENEGOTIATED ||
        receivable.status === ReceivableStatuses.AGGLUTINATED
      ) {
        continue
      }

      const item: ReceivableAccordionData = {
        baseAmount: receivable.base_amount,
        currentAmount: receivable.current_amount,
        discount: receivable.current_discount,
        dueDate: dayjs(receivable.due_date).utc(),
        fee: receivable.current_interest,
        fine: receivable.current_fine,
        id: receivable.id,
        invoice_id: receivable.invoice_id,
        status: receivable.status,
      }

      receivables = append(item, receivables)
    }
  }

  return stateSet(receivables)
}

const ReceivablePage: FC = () => {
  const { api } = useApi()
  const { contractId } = useParams<ReceivablePageParam>()
  const [receivables, setReceivables] = useState<ReceivableAccordionData[]>([])
  const [product, setProduct] = useState<Product>(null)
  const [backURL, setBackURL] = useState<string>()
  const [guardianId, setGuardianId] = useState<string>()

  // TODO: remove and replace with proper way of navigation
  const getBackURL = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, path, guardianId, schoolId, studentId, contractId] = window.location.pathname.split(
      '/'
    )
    return `/contratos/${guardianId}/${schoolId}/${studentId}`
  }

  useEffect(() => {
    if (!contractId) return

    api.contracts
      .get(contractId, {
        include_guardian: true,
        include_installments: true,
        include_product: true,
        include_receivables: true,
        include_student: false,
      })
      .then(resp => {
        setProduct(resp.product)
        buildReceivablesData(setReceivables, resp.installments)
        setGuardianId(resp.guardian?.id)
      })

    setBackURL(getBackURL())
  }, [contractId])

  return (
    <>
      <Navbar inverted />
      <Container maxWidth="md">
        <HeadNavigation href={backURL} style={{ marginTop: '64px !important' }}>
          {product?.name}
        </HeadNavigation>
        <ReceivableAccordion
          data={receivables}
          guardianId={guardianId}
          productName={product?.name}
        />
      </Container>
    </>
  )
}

export default ReceivablePage
