const currencyFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
})
const noFractionDigitsCurrencyFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
  minimumFractionDigits: 0,
})
const twoFractionDigitsFormatter = new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 2 })

const oneFractionDigitsFormatter = new Intl.NumberFormat('pt-BR', { maximumFractionDigits: 1 })

export const formatCentsToReal = (v: string | number): string =>
  +v === 0 ? noFractionDigitsCurrencyFormatter.format(+v) : currencyFormatter.format(+v / 100)

export const formatNumberToTwoFractionDigits = (v: string | number): string =>
  twoFractionDigitsFormatter.format(+v)

export const formatNumberToOneFractionDigits = (v: string | number): string =>
  oneFractionDigitsFormatter.format(+v)

export const leftZeroPadding = (num, size) => {
  const s = `000000000000${num}`
  return s.substr(s.length - size)
}

export const cleanSpecialCharacters = (v: string) => v && v.replace(/[^a-zA-Z0-9]/g, '')

export const formatReal = number => currencyFormatter.format(number)

export const formatDecimal = number => formatReal(number).replace(/[R$]\s?/g, '')

export const formatRealToCents = (value: string) =>
  value
    ? (parseFloat(value.split(/\s+/)[1].replace(/\./g, '').replace(/,/g, '.')) * 100).toFixed(0)
    : '0'
