export type ErrorResponse = {
  message: string
  tag: string
}

export type Pagination = Partial<{
  page: number
  per_page: number
  sort_by: string
  sort_order: string
  total: number
}>

export type APIResponse<T = any> = {
  data: T
  errors?: ErrorResponse[]
  pagination?: Pagination
}

export type RequestPresignedURL = {
  extension: string
}

export type PresignedURL = {
  file_id: uuid
  url: string
}

export type PathParams = {
  contractId: string
  guardianId?: uuid
  schoolSlug: string
}

export enum RESPONSE_STATUS {
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
}
