import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router'

const useQuery = () => {
  const query = new URLSearchParams(useLocation().search)
  const history = useHistory()

  const setOnQueryParam = (value: string, queryParam: string) => {
    if (value) {
      query.set(queryParam, value)
    } else {
      query.delete(queryParam)
    }
    history.push({ search: query.toString() })
  }
  return { query, setOnQueryParam }
}

export default useQuery
