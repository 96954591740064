import { FC } from 'react'
import { Button, ButtonDocker } from '@olaisaac/design-system'

type ManualSignDrawerActionsProps = {
  submitHandler: () => void
}

export const ManualSignDrawerActions: FC<ManualSignDrawerActionsProps> = ({ submitHandler }) => (
  <ButtonDocker>
    <Button variation="primary" fullWidth onClick={submitHandler}>
      Confirmar assinatura
    </Button>
  </ButtonDocker>
)
