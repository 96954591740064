import { useNavigation } from 'src/escolas/hooks'
import SendEvent, { SendFullStoryEvent } from 'src/shared/api/eventsClient'
import { EVENT_CATEGORIES, EVENT_GROUPS, REALMS } from 'src/shared/constants'
import { useJWT } from 'src/shared/hooks'

export type EnrollmentEventMetadataProps = {
  additional_information?: string
  city?: string
  contract_id?: string
  email?: string
  error?: string
  is_overdue_student_reenrollment?: boolean
  name?: string
  number?: string
  phone_number?: string
  product_id?: string
  state_code?: string
  street?: string
  student_referral_id?: string
  tax_id?: string
  zip?: string
}

export type GuardianEditEventPayload = {
  guardian_address_additional_information?: string
  guardian_address_city?: string
  guardian_address_number?: string
  guardian_address_state_code?: string
  guardian_address_street?: string
  guardian_address_zip?: cep
  guardian_email?: string
  guardian_name?: string
  guardian_phone_number?: string
  guardian_tax_id?: string
}

export enum EnrollmentEventType {
  contractCreated = 'contract-created',
  contractEdited = 'contract-edited',
  contracts2021Filtered = '2021-contracts-filtered',
  contracts2021Searched = '2021-contracts-searched',
  contracts2022Filtered = '2022-contracts-filtered',
  contracts2022Searched = '2022-contracts-searched',
  dontSendFirstInstallmentChecked = 'dont-send-first-installment-checked',
  dontSendSignableDocumentChecked = 'dont-send-signable-document-checked',
  downloadCsv2021Clicked = 'download-csv-2021-clicked',
  downloadCsv2022Clicked = 'download-csv-2022-clicked',
  editRegistrationClicked = 'edit-registration-clicked',
  errorMessageShowed = 'error-message-showed',
  formStarted = 'form-started',
  guardianEdited = 'guardian-edited',
  newContract2022Clicked = 'new-contract-2022-clicked',
  reenrollClicked = 'reenroll-clicked',
  saveAsSignedClicked = 'save-as-signed-clicked',
  saveAsSignedErrorShowed = 'save-as-signed-error-showed',
  studentEdited = 'student-edited',
}

const buildGuardianEditEventPayload = (
  metadata?: EnrollmentEventMetadataProps
): GuardianEditEventPayload => {
  const {
    additional_information,
    city,
    number,
    state_code,
    street,
    zip,
    email,
    name,
    phone_number,
    tax_id,
  } = metadata || {}
  return {
    guardian_address_additional_information: additional_information,
    guardian_address_city: city,
    guardian_address_number: number,
    guardian_address_state_code: state_code,
    guardian_address_street: street,
    guardian_address_zip: zip,
    guardian_email: email,
    guardian_name: name,
    guardian_phone_number: phone_number,
    guardian_tax_id: tax_id,
  }
}

export const useSendEnrollmentEvent = () => {
  const { isAdmin, userId } = useJWT()
  const { schoolId, schoolSlug } = useNavigation()
  return (type: EnrollmentEventType, metadata?: EnrollmentEventMetadataProps) => {
    const { error, contract_id, is_overdue_student_reenrollment, product_id, student_referral_id } =
      metadata || {}

    const completeMetadata = {
      school_slug: schoolSlug,
      school_id: schoolId,
      error,
      contract_id,
      is_overdue_student_reenrollment,
      product_id,
      student_referral_id,

      ...buildGuardianEditEventPayload(metadata),
    }
    SendEvent({
      category: EVENT_CATEGORIES.ENROLLMENT_CATEGORY,
      group: EVENT_GROUPS.SCHOOLS_GROUP,
      metadata: completeMetadata,
      realm: isAdmin ? REALMS.ADMIN_REALM : REALMS.SCHOOL_REALM,
      type,
      user_id: userId,
    })

    SendFullStoryEvent(completeMetadata, type)
  }
}
