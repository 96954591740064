import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { sortBy, compose, toLower, prop, uniq } from 'ramda'
import { Discount } from 'src/shared/interfaces'
import { CheckoutDrawerState } from '../components/contract/CheckoutDrawer/constants'
import { Dayjs } from 'dayjs'

export type AgglutinationProcessedInstallment = {
  amount: cents
  children?: AgglutinationProcessedInstallment[]
  competence: string[]
  contractID: string
  due_date: datestring
  due_month: Dayjs
  id: uuid
  installment_id: uuid
  key: string
  orderReference: string
  productID: string
  productName: string
  status: string
  studentID: string
  studentName: string
  type?: string
}
export type CheckoutCombinableReceivable = {
  amount: cents
  children?: AgglutinationProcessedInstallment[]
  current_fine: cents // multa
  current_interest: cents // juros
  discounts: Discount[]
  due_date: datestring
  id: uuid
  lost_due_payment_discount: cents
  lost_early_payment_discount: cents
  orderReference: string
  product: string
  product_id: string
  status: string
  student: string
  student_id: string
}
export type ReceivablesByProduct = {
  product_id: uuid
  product_name: string
  receivables: CheckoutCombinableReceivable[]
}
export type CheckoutReceivables = {
  products: ReceivablesByProduct[]
  student_id: uuid
  student_name: string
}

type CheckoutContextType = {
  addAllAvailableReceivableCheckout: (receivables: CheckoutCombinableReceivable[]) => void
  addReceivableCheckout: (receivable: CheckoutCombinableReceivable) => void
  baseAmount: cents
  clearcombinedReceivablesCheckout: () => void
  combinedReceivablesCheckout: CheckoutCombinableReceivable[]
  finalAmount: cents
  finalCheckoutList: CheckoutReceivables[]
  fineAndInterestValue: cents
  includeDuePaymentDiscount: boolean
  includeEarlyPaymentDiscount: boolean
  includeFeesAndFines: boolean
  lostDuePaymentDiscountValue: cents
  lostEarlyPaymentDiscountValue: cents
  numberOfInstallments: number
  paymentMethod: string
  process: CheckoutDrawerState
  removeReceivableCheckout: (receivable: string) => void
  setFineAndInterestValue: (fineAndInterestValue: cents) => void
  setIncludeDuePaymentDiscount: (includeDuePaymentDiscount: boolean) => void
  setIncludeEarlyPaymentDiscount: (includeEarlyPaymentDiscount: boolean) => void
  setIncludeFeesAndFines: (includeFeesAndFines: boolean) => void
  setLostDuePaymentDiscountValue: (hasLostDuePaymentDiscount: cents) => void
  setLostEarlyPaymentDiscountValue: (lostEarlyPaymentDiscountValue: cents) => void
  setNumberOfInstallments: (numberOfInstallments: number) => void
  setPaymentMethod: (paymentMethod: string) => void
  setProcess: (process: CheckoutDrawerState) => void
}
export const CheckoutStore = createContext<CheckoutContextType>({} as CheckoutContextType)

CheckoutStore.displayName = 'Checkout Store'

export const useCheckoutReceivables = () => {
  let checkoutReceivables: CheckoutReceivables[] = []

  const [finalCheckoutList, setFinalCheckoutList] = useState<CheckoutReceivables[]>([])
  const [combinedReceivablesCheckout, setCombinedReceivablesCheckout] = useState<
    CheckoutCombinableReceivable[]
  >([])
  const [finalAmount, setFinalAmount] = useState<cents>(0)
  const [baseAmount, setBaseAmount] = useState<cents>(0)
  const [process, setProcess] = useState<CheckoutDrawerState>(CheckoutDrawerState.INSTALLMENTS)
  const [lostDuePaymentDiscountValue, setLostDuePaymentDiscountValue] = useState<cents>(0)
  const [lostEarlyPaymentDiscountValue, setLostEarlyPaymentDiscountValue] = useState<cents>(0)
  const [fineAndInterestValue, setFineAndInterestValue] = useState<cents>(0)
  const [includeDuePaymentDiscount, setIncludeDuePaymentDiscount] = useState<boolean>(false)
  const [includeEarlyPaymentDiscount, setIncludeEarlyPaymentDiscount] = useState<boolean>(false)
  const [includeFeesAndFines, setIncludeFeesAndFines] = useState<boolean>(true)
  const [numberOfInstallments, setNumberOfInstallments] = useState<number>(null)
  const [paymentMethod, setPaymentMethod] = useState<string>(null)

  const sortReceivablesByKey = (key: string) => sortBy(compose(toLower, prop(key)))

  const setFinalList = (combinedReceivablesCheckout: CheckoutCombinableReceivable[]) => {
    checkoutReceivables = []
    let countFinesAndInterest = 0
    let countLostDueDateDiscount = 0
    let countLostEarlyDiscount = 0

    if (combinedReceivablesCheckout?.length) {
      let listOfStudents = uniq(
        combinedReceivablesCheckout.map(x => {
          const container = {
            student: x.student,
            student_id: x.student_id,
          }
          return container
        })
      )
      let listOfProducts = uniq(
        combinedReceivablesCheckout.map(x => {
          const container = {
            product: x.product,
            product_id: x.product_id,
          }
          return container
        })
      )
      let newList = combinedReceivablesCheckout
      if (listOfStudents?.length && listOfProducts?.length) {
        listOfStudents = listOfStudents.sort((a, b) => a.student.localeCompare(b.student))
        listOfProducts = listOfProducts.sort((a, b) => a.product.localeCompare(b.product))

        for (const student of listOfStudents) {
          const studentReceivable: CheckoutReceivables = {
            student_id: null,
            student_name: '',
            products: [],
          }
          for (const product of listOfProducts) {
            const productReceivable: ReceivablesByProduct = {
              product_id: null,
              product_name: '',
              receivables: [],
            }
            for (const receivable of newList) {
              if (
                receivable?.product === product?.product &&
                receivable?.student_id === student?.student_id
              ) {
                productReceivable.receivables.push(receivable)
                newList = newList.filter(x => x.id !== receivable?.id)
              }
            }
            if (productReceivable.receivables.length > 0) {
              productReceivable.product_name = product?.product
              productReceivable.product_id = product?.product_id

              studentReceivable.products.push(productReceivable)
            }
          }
          if (studentReceivable.products.length > 0) {
            studentReceivable.student_name = student?.student
            studentReceivable.student_id = student?.student_id

            checkoutReceivables.push(studentReceivable)
          }
        }
      }
    }
    combinedReceivablesCheckout?.forEach(element => {
      countFinesAndInterest += element?.current_fine + element?.current_interest
      countLostDueDateDiscount += element?.lost_due_payment_discount
      countLostEarlyDiscount += element?.lost_early_payment_discount
    })
    setFineAndInterestValue(countFinesAndInterest)
    setLostDuePaymentDiscountValue(countLostDueDateDiscount)
    setLostEarlyPaymentDiscountValue(countLostEarlyDiscount)

    setFinalCheckoutList(checkoutReceivables)
  }

  useEffect(() => {
    // discount ? setFinalAmount(baseAmount - discount) : setFinalAmount(baseAmount)
    setFinalAmount(baseAmount)
  }, [baseAmount])

  useEffect(() => {
    let amount = finalAmount
    if (includeFeesAndFines) {
      amount += fineAndInterestValue
    } else {
      amount -= fineAndInterestValue
    }
    setFinalAmount(amount)
  }, [includeFeesAndFines])

  useEffect(() => {
    let amount = finalAmount
    if (!includeDuePaymentDiscount) {
      amount += lostDuePaymentDiscountValue
    } else {
      amount -= lostDuePaymentDiscountValue
    }
    setFinalAmount(amount)
  }, [includeDuePaymentDiscount])

  useEffect(() => {
    let amount = finalAmount
    if (!includeEarlyPaymentDiscount) {
      amount += lostEarlyPaymentDiscountValue
    } else {
      amount -= lostEarlyPaymentDiscountValue
    }
    setFinalAmount(amount)
  }, [includeEarlyPaymentDiscount])

  useEffect(() => {
    setBaseAmount(
      combinedReceivablesCheckout?.reduce(
        (
          acc,
          {
            amount,
            current_fine,
            current_interest,
            lost_due_payment_discount,
            lost_early_payment_discount,
          }
        ) => {
          let newAmount = includeFeesAndFines ? amount : amount - (current_fine + current_interest)
          if (includeDuePaymentDiscount) {
            newAmount -= lost_due_payment_discount
          }
          if (includeEarlyPaymentDiscount) {
            newAmount -= lost_early_payment_discount
          }
          return newAmount + acc
        },
        0
      )
    )
    setFinalList(combinedReceivablesCheckout)
    setIncludeDuePaymentDiscount(includeDuePaymentDiscount)
    setIncludeEarlyPaymentDiscount(includeEarlyPaymentDiscount)
  }, [combinedReceivablesCheckout])

  const clearcombinedReceivablesCheckout = useCallback(() => {
    setCombinedReceivablesCheckout([])
  }, [setCombinedReceivablesCheckout])

  const addAllAvailableReceivableCheckout = useCallback(
    (receivables: CheckoutCombinableReceivable[] = []) => {
      setCombinedReceivablesCheckout(
        receivables.sort((a, b) => a.due_date.localeCompare(b.due_date))
      )
    },
    [combinedReceivablesCheckout]
  )

  const addReceivableCheckout = useCallback(
    (receivable: CheckoutCombinableReceivable) => {
      if (combinedReceivablesCheckout.findIndex(({ id }) => id === receivable.id) > -1) return

      if (combinedReceivablesCheckout && combinedReceivablesCheckout.length > 0) {
        setCombinedReceivablesCheckout(combinedReceivablesCheckout =>
          sortReceivablesByKey('due_date')([...combinedReceivablesCheckout, receivable])
        )
      } else {
        setCombinedReceivablesCheckout([receivable])
      }
      setFinalList(combinedReceivablesCheckout)
    },
    [combinedReceivablesCheckout]
  )

  const removeReceivableCheckout = useCallback(
    receivable => {
      setCombinedReceivablesCheckout(combinedReceivablesCheckout?.filter(r => r.id !== receivable))
    },
    [combinedReceivablesCheckout]
  )

  return {
    addAllAvailableReceivableCheckout,
    addReceivableCheckout,
    baseAmount,
    clearcombinedReceivablesCheckout,
    combinedReceivablesCheckout,
    finalAmount,
    finalCheckoutList,
    fineAndInterestValue,
    lostDuePaymentDiscountValue,
    lostEarlyPaymentDiscountValue,
    includeDuePaymentDiscount,
    includeEarlyPaymentDiscount,
    includeFeesAndFines,
    numberOfInstallments,
    paymentMethod,
    process,
    removeReceivableCheckout,
    setFineAndInterestValue,
    setLostDuePaymentDiscountValue,
    setLostEarlyPaymentDiscountValue,
    setIncludeDuePaymentDiscount,
    setIncludeEarlyPaymentDiscount,
    setIncludeFeesAndFines,
    setNumberOfInstallments,
    setPaymentMethod,
    setProcess,
  }
}

export const ReceivablesCheckoutProvider = ({ children }) => {
  const {
    addReceivableCheckout,
    addAllAvailableReceivableCheckout,
    baseAmount,
    clearcombinedReceivablesCheckout,
    combinedReceivablesCheckout,
    finalAmount,
    finalCheckoutList,
    lostDuePaymentDiscountValue,
    lostEarlyPaymentDiscountValue,
    fineAndInterestValue,
    includeDuePaymentDiscount,
    includeEarlyPaymentDiscount,
    includeFeesAndFines,
    numberOfInstallments,
    paymentMethod,
    process,
    removeReceivableCheckout,
    setLostDuePaymentDiscountValue,
    setLostEarlyPaymentDiscountValue,
    setFineAndInterestValue,
    setIncludeDuePaymentDiscount,
    setIncludeEarlyPaymentDiscount,
    setIncludeFeesAndFines,
    setNumberOfInstallments,
    setPaymentMethod,
    setProcess,
  } = useCheckoutReceivables()

  const contextValue = useMemo(
    () => ({
      addAllAvailableReceivableCheckout,
      addReceivableCheckout,
      baseAmount,
      clearcombinedReceivablesCheckout,
      combinedReceivablesCheckout,
      finalAmount,
      finalCheckoutList,
      fineAndInterestValue,
      lostDuePaymentDiscountValue,
      lostEarlyPaymentDiscountValue,
      includeDuePaymentDiscount,
      includeEarlyPaymentDiscount,
      includeFeesAndFines,
      numberOfInstallments,
      paymentMethod,
      process,
      removeReceivableCheckout,
      setFineAndInterestValue,
      setLostDuePaymentDiscountValue,
      setLostEarlyPaymentDiscountValue,
      setIncludeDuePaymentDiscount,
      setIncludeEarlyPaymentDiscount,
      setIncludeFeesAndFines,
      setNumberOfInstallments,
      setPaymentMethod,
      setProcess,
    }),
    [
      addReceivableCheckout,
      addAllAvailableReceivableCheckout,
      baseAmount,
      clearcombinedReceivablesCheckout,
      combinedReceivablesCheckout,
      finalAmount,
      finalCheckoutList,
      lostDuePaymentDiscountValue,
      lostEarlyPaymentDiscountValue,
      fineAndInterestValue,
      includeDuePaymentDiscount,
      includeEarlyPaymentDiscount,
      includeFeesAndFines,
      numberOfInstallments,
      paymentMethod,
      process,
      removeReceivableCheckout,
      setLostDuePaymentDiscountValue,
      setLostEarlyPaymentDiscountValue,
      setFineAndInterestValue,
      setIncludeDuePaymentDiscount,
      setIncludeEarlyPaymentDiscount,
      setIncludeFeesAndFines,
      setNumberOfInstallments,
      setPaymentMethod,
      setProcess,
    ]
  )

  return <CheckoutStore.Provider value={contextValue}>{children}</CheckoutStore.Provider>
}
