import { FC } from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Email from '@material-ui/icons/Email'
import Phone from '@material-ui/icons/Phone'
import { Guardian } from 'src/shared/interfaces'
import { formatPhoneNumber } from 'src/shared/utils'

export type ContactInformationProps = {
  guardian: Guardian
}

export const ContactInformation: FC<ContactInformationProps> = ({ guardian }) => {
  const phoneNumber = guardian?.phone_number
  const email = guardian?.email

  if (!phoneNumber && !email) {
    return null
  }

  return (
    <>
      <Box>
        <Typography variant="overline" color="textSecondary">
          Contatos do responsável
        </Typography>
      </Box>
      {phoneNumber && (
        <Box display="flex" mt={1}>
          <Box mr={2}>
            <Phone color="action" fontSize="small" />
          </Box>
          <Typography className="fs-mask" display="inline" variant="body2" color="textSecondary">
            {formatPhoneNumber(phoneNumber)}
          </Typography>
        </Box>
      )}
      {email && (
        <Box display="flex" mt={1}>
          <Box mr={2}>
            <Email color="action" fontSize="small" />
          </Box>
          <Typography className="fs-mask" display="inline" variant="body2" color="textSecondary">
            {email.toLowerCase()}
          </Typography>
        </Box>
      )}
    </>
  )
}

export default ContactInformation
