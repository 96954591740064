export type SelfOnboarding = {
  status: OnboardingGroupStatus
}

export enum OnboardingGroupStatus {
  NOT_SELF_ONBOARDING = 'NOT_SELF_ONBOARDING',
  PROCESSINGS_DONE = 'PROCESSINGS_DONE',
  SELF_ONBOARDING_FINISHED = 'SELF_ONBOARDING_FINISHED',
  SELF_ONBOARDING_ONGOING = 'SELF_ONBOARDING_ONGOING',
}
