import { useState, useContext, useRef } from 'react'
import { Divider, Typography } from '@olaisaac/design-system'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'

import sortingHatClient, { clearCookies } from 'src/shared/api/sortingHatClient'
import { ApplicationContext } from 'src/responsaveis/router/router'
import * as S from './styles'

// @TODO refatorar esse prop drilling
type BurguerMenuProps = {
  inverted?: boolean
}

const BurguerMenu = ({ inverted = false }: BurguerMenuProps) => {
  const { context: [appState] = [] } = useContext(ApplicationContext) || {}
  const btnRef = useRef()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isOpen = !!anchorEl

  const handleClick = () => {
    setAnchorEl(btnRef.current)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const logout = () => {
    clearCookies()
    sortingHatClient.services.token.IsAuthenticated()
    // @TODO isso ficou acoplado ao login IAM (sorting-hat) mas o correto seria
    // dar um redirect pra /login ou /logout onde é definido se usa a rota interna
    // de login ou o redirect pro IAM
    // porém o history e <Redirect /> não estão funcionando e precisamos arrumar isso
    // talvez sejam os routers aninhados
  }

  return (
    <>
      <IconButton onClick={handleClick} ref={btnRef}>
        {inverted ? <S.MenuIconIsaacBlue /> : <S.MenuIconWhite />}
      </IconButton>
      <S.Menu open={isOpen} anchorEl={btnRef.current} onClose={handleClose} style={{ top: 52 }}>
        <S.MenuContent>
          <Typography as="span" variation="headlineDesktopXsmall" color="primary">
            {appState?.guardian?.name}
          </Typography>
        </S.MenuContent>
        <Divider variation="secondary" style={{ margin: 16 }} />
        <MenuItem onClick={logout}>
          <S.ExitToAppIcon /> Fazer Logout
        </MenuItem>
      </S.Menu>
    </>
  )
}

export default BurguerMenu
