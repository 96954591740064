import Navbar from 'src/responsaveis/components/Navbar'
import View from 'src/responsaveis/components/View'
import InstallmentCard, {
  contractStatusToInstallmentStatus,
} from 'src/responsaveis/components/cards/Installment'
import HeadNavigation from 'src/responsaveis/components/HeadNavigation'
import dayjs from 'dayjs'
import { FC, useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router'
import { useApi } from 'src/shared/hooks'
import { ContractOverview } from 'src/shared/interfaces'
import { ApplicationContext } from 'src/responsaveis/router/router'
import { prettifyName } from 'src/shared/utils/namePrettifiers'
import Container from '@material-ui/core/Container'

export type ContractsPageParam = {
  schoolId: string
  studentId: string
}

const ContractsPage: FC = () => {
  const { api } = useApi()
  const { schoolId, studentId } = useParams<ContractsPageParam>()
  const [overviews, setOverviews] = useState<ContractOverview[]>([])
  const [backURL, setBackURL] = useState<string>()
  const {
    context: [appState, setAppState],
  } = useContext(ApplicationContext)

  // TODO: remove and replace with proper way of navigation
  const getBackURL = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [empty, dashboard, guardianId, schoolId, studentId] = window.location.pathname.split('/')
    return `/dashboard/${guardianId}`
  }

  // TODO: remove and replace with proper way of navigation
  const getNextURL = contractId => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [empty, contratos, guardianId, schoolId, studentId] = window.location.pathname.split('/')
    return `/recebiveis/${guardianId}/${schoolId}/${studentId}/${contractId}`
  }

  useEffect(() => {
    if (!schoolId || !studentId) return

    api.contracts
      .getOverviewsBySchoolStudent(schoolId, studentId)
      .then((resp: ContractOverview[] = []) => {
        setOverviews(resp)
      })

    if (appState?.studentName === undefined) {
      api.students.get(studentId).then(resp => {
        setAppState({
          ...appState,
          studentName: prettifyName(resp.name),
        })
      })
    }

    setBackURL(getBackURL())
  }, [schoolId, studentId])

  return (
    <>
      <Navbar inverted />
      <View inverted>
        <Container maxWidth="md">
          <HeadNavigation href={backURL}>{appState.studentName}</HeadNavigation>
          {overviews.map(value => (
            <InstallmentCard
              key={value.contract_id}
              dueDate={dayjs(value.next_installment_due_date).utc()}
              href={getNextURL(value.contract_id)}
              productName={value.product_name}
              split={value.next_installment_numeration}
              status={contractStatusToInstallmentStatus(value.status)}
              totalSplit={value.installments_count}
              value={value.next_installment_final_amount}
            />
          ))}
        </Container>
      </View>
    </>
  )
}

export default ContractsPage
