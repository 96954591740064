import { useState, createContext, Dispatch, SetStateAction, useEffect } from 'react'
import { useApi } from 'src/shared/hooks'
import { School } from 'src/shared/interfaces'

export type SchoolsContext = {
  hasFetched: boolean
  schools: School[]
  setHasFetched: Dispatch<SetStateAction<boolean>>
  setSchools: Dispatch<SetStateAction<School[]>>
}
export const SchoolsContext = createContext<SchoolsContext>(null)

export const SchoolSelectorProvider = props => {
  const [schools, setSchools] = useState<School[]>([])
  const [hasFetched, setHasFetched] = useState<boolean>(false)
  const { api } = useApi()

  useEffect(() => {
    const perPage = 100
    if (hasFetched) return
    async function fetchSchools() {
      let schools: Array<School> = []
      for (let page = 1, lastPage = 1; page <= lastPage; page++) {
        await api.schools
          .getList({ per_page: perPage, page })
          .then(({ data, pagination: { total } }) => {
            schools = [...schools, ...data]
            lastPage = total && total > 0 ? Math.ceil(total / perPage) : lastPage
          })
      }
      schools.length && setSchools(schools)
    }
    fetchSchools()
    setHasFetched(true)
  }, [])

  return (
    <SchoolsContext.Provider value={{ schools, setSchools, hasFetched, setHasFetched }}>
      {props.children}
    </SchoolsContext.Provider>
  )
}
