import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import styled from 'styled-components'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Badge from '@material-ui/core/Badge'
import Typography from '@material-ui/core/Typography'
import { FC } from 'react'
import { ReceivableAccordionData, useStyles } from './ReceivableAccordion'
import { InstallmentStatuses } from 'src/shared/interfaces'
import { beforeToday, formatCentsToReal, months } from 'src/shared/utils'

const MonthTitle = styled(Typography)`
  display: block;
  font-size: 16px;
  width: 100%;

  & > span.price {
    width: auto;
    float: right;

    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
  }
`

const StyledBadge = styled(Badge)`
  margin-right: 16px;
  margin-left: 3px;
  margin-top: -2.5px;
`

const StyledAccordionSummary = styled(AccordionSummary)`
  height: 72px;
`

type AccordionHeader = {
  index: number
  receivable: ReceivableAccordionData
}

const AccordionHeader: FC<AccordionHeader> = ({ index, receivable }) => {
  const classes = useStyles()
  return (
    <StyledAccordionSummary
      expandIcon={<ExpandMoreIcon style={{ color: '#3D4ED7' }} />}
      aria-controls={`panel-content-${index + 1}`}
      id={`panel-header-${index + 1}`}
    >
      <MonthTitle variant="caption">
        <StyledBadge
          color={
            receivable.status !== InstallmentStatuses.PAID
              ? beforeToday(receivable.dueDate)
                ? 'secondary'
                : 'error'
              : 'primary'
          }
          variant="dot"
          classes={{
            colorPrimary: classes.colorPrimary,
            colorSecondary: classes.colorSecondary,
            colorError: classes.colorError,
          }}
        />
        {months[receivable.dueDate.month() + 1]}

        <span className="price">{formatCentsToReal(receivable.currentAmount)}</span>
      </MonthTitle>
    </StyledAccordionSummary>
  )
}

export default AccordionHeader
