import React, { Dispatch, FC, SetStateAction } from 'react'
import { Menu, MenuItem } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { propEq } from 'ramda'
import CopyInvoiceLinkButton from 'src/escolas/components/contract/InstallmentDrawer/CopyInvoiceLinkButton'
import PrintReceiptButton from 'src/escolas/components/contract/InstallmentDrawer/PrintReceiptButton'
import { useJWT } from 'src/shared/hooks'
import { useContract, useNavigation } from 'src/escolas/hooks'
import { DrawerState } from 'src/escolas/components/contract/InstallmentDrawer/constants'
import { IconButton, Button, ButtonDocker } from '@olaisaac/design-system'
import {
  InstallmentStatuses,
  LiquidationSource,
  PaymentMethod,
  ReceivableStatuses,
} from 'src/shared/interfaces'
import dayjs from 'dayjs'
import { date2PTFormat } from 'src/shared/utils'
import { FeatureFlags, useFeatureFlag } from 'src/shared/hooks/useFeatureFlag'
import useInstallments from 'src/escolas/hooks/useInstallments'

type InstallmentDrawerActionsProps = {
  selectedReceivableId: uuid
  setDrawerState: Dispatch<SetStateAction<DrawerState>>
}

export const InstallmentDrawerActions: FC<InstallmentDrawerActionsProps> = ({
  selectedReceivableId,
  setDrawerState,
}) => {
  const { isAdmin } = useJWT()
  const { schoolId } = useNavigation()
  const { contract } = useContract()
  const { selectedProcessedInstallment } = useInstallments()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const {
    value: manualLiquidationFeatureFlagValue,
    config: manualLiquidationConfig,
  } = useFeatureFlag(FeatureFlags.MANUAL_LIQUIDATION_PILOT_SCHOOLS_FEATURE_FLAG)

  const IsManualLiquidationFeatureFlagEnabled =
    !isAdmin && manualLiquidationFeatureFlagValue && manualLiquidationConfig.includes(schoolId)

  const { value: addDiscountsFeatureFlagValue, config: addDiscountsConfig } = useFeatureFlag(
    FeatureFlags.ADD_DISCOUNTS_FEATURE_FLAG
  )

  const isAddDiscountFeatureFlagEnabled =
    !isAdmin &&
    addDiscountsFeatureFlagValue &&
    (addDiscountsConfig.length === 0 || addDiscountsConfig.includes(schoolId))

  const installment = contract?.installments.find(installment =>
    installment?.receivables.some(propEq('id', selectedReceivableId))
  )

  const isOverdue = selectedProcessedInstallment?.status === InstallmentStatuses.OVERDUE

  const canAddDiscount = isAdmin || (isAddDiscountFeatureFlagEnabled && !isOverdue)

  const receivables = installment?.receivables
  const receivable = receivables?.find(propEq('id', selectedReceivableId))
  const open = Boolean(anchorEl)
  const isOpen = receivable?.status === 'OPEN'
  const isManualLiquidation = [
    LiquidationSource.ISAAC_ACCOUNT,
    LiquidationSource.SCHOOL_ACCOUNT,
  ].includes(receivable?.payment_source)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const hasAgglutination = receivables?.some(
    receivable => receivable?.status === ReceivableStatuses.AGGLUTINATED
  )

  const PAYMENT_SOURCE_MAP = {
    [PaymentMethod.BANK_SLIP_PAYMENT_METHOD]: 'Pagamento por boleto',
    [PaymentMethod.CREDIT_CARD_PAYMENT_METHOD]: 'Pagamento por cartão de crédito',
    [PaymentMethod.DEBIT_CARD_PAYMENT_METHOD]: 'Pagamento por cartão de débito',
    [LiquidationSource.ISAAC_ACCOUNT]: 'Recebimento manual na conta do isaac',
    [PaymentMethod.PIX_PAYMENT_METHOD]: 'Pagamento por PIX',
    [LiquidationSource.SCHOOL_ACCOUNT]: 'Recebimento manual na conta da escola',
  }

  let info = ''
  if (receivable?.status === ReceivableStatuses.PAID) {
    const date = dayjs(receivable?.paid_date).utc().format()
    let payment = PAYMENT_SOURCE_MAP[receivable.payment_source]
    if (!payment) {
      payment = PAYMENT_SOURCE_MAP[receivable.payment_method]
    }
    info = `${payment} em ${date2PTFormat(date)}`
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const provider = receivable?.invoice?.payment_provider || ''
  const isEditable = isManualLiquidation && !hasAgglutination

  const canEditManualLiquidation = IsManualLiquidationFeatureFlagEnabled
    ? isEditable && receivable?.payment_source === LiquidationSource.ISAAC_ACCOUNT
    : isEditable

  return (
    <>
      {isOpen ? (
        <ButtonDocker>
          <CopyInvoiceLinkButton openReceivable={receivable} />
          <IconButton
            variation="ghost"
            aria-label="mais ações"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
            data-testid="more-actions"
          >
            <MoreVertIcon />
          </IconButton>
        </ButtonDocker>
      ) : (
        <ButtonDocker description={info}>
          <PrintReceiptButton receivableId={selectedReceivableId} provider={provider} />
          {canEditManualLiquidation && (
            <Button
              fullWidth
              variation="ghost"
              onClick={() => setDrawerState(DrawerState.MANUAL_LIQUIDATION)}
            >
              Editar recebimento
            </Button>
          )}
        </ButtonDocker>
      )}

      {isOpen && (
        <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
          <MenuItem onClick={() => setDrawerState(DrawerState.MANUAL_LIQUIDATION)}>
            Registrar recebimento
          </MenuItem>
          <>
            {isAdmin && (
              <MenuItem onClick={() => setDrawerState(DrawerState.RENEGOTIATION)}>
                Renegociar
              </MenuItem>
            )}
            {canAddDiscount && (
              <MenuItem onClick={() => setDrawerState(DrawerState.ADD_DISCOUNTS)}>
                Adicionar desconto
              </MenuItem>
            )}
          </>
        </Menu>
      )}
    </>
  )
}
