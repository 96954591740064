import {
  Contract,
  ContractStatus,
  DebtStatus,
  GroupGuardian,
  Installment,
  InstallmentStatuses,
  InstallmentType,
  ReceivableStatuses,
  SignAndPaymentStatus,
} from 'src/shared/interfaces'
import { student, ogino } from './students'
import { guardian1, guardian2 } from './guardians'
import { product1, product1WithSchoolData } from './products'

export const openContract: Contract = {
  created_at: '2021-01-27T17:29:18.701Z',
  due_day: 15,
  end_month: '2021-12-01',
  guardian: guardian1,
  guardian_id: guardian1.id,
  id: '7883379e-26a3-4d68-ae4c-9de30d2a2c5b',
  next_year_contracts: null,
  product: product1,
  product_id: product1.id,
  reference_year: '2021',
  start_month: '2021-01-01',
  status: ContractStatus.OPEN,
  student,
  student_id: student.id,
  total_amount: 1200000,
}

export const openContract2: Contract = {
  created_at: '2021-01-27T17:29:18.701Z',
  due_day: 15,
  end_month: '2021-12-01',
  guardian: guardian1,
  guardian_id: guardian1.id,
  id: '9d532da5-2c99-40f6-94be-b3e67aec9e18',
  next_year_contracts: null,
  product: product1,
  product_id: product1.id,
  reference_year: '2021',
  start_month: '2021-01-01',
  status: ContractStatus.OPEN,
  student: ogino,
  student_id: ogino.id,
  total_amount: 1200000,
}

export const itemInstallment = (id: uuid): Installment => ({
  amount: 100000,
  contract_id: id,
  created_at: '2021-01-27T17:29:18.701Z',
  due_date: '2021-12-02T16:50:26.393-03:00',
  id: '1402b44d-6629-485a-bdc1-58c854de534c',
  status: InstallmentStatuses.OPEN,
  type: InstallmentType.TUITION,
})

const DIA = 1000 * 60 * 60 * 24

export const addReceivable = (installment: Installment): Installment => {
  const receivable = []
  receivable.push({
    base_amount: 80000,
    created_at: '2021-01-27T17:29:18.701Z',
    current_additional_discount: 0,
    current_amount: 78000,
    current_discount: 2000,
    current_early_payment_discount: 2000,
    current_fine: 0,
    current_interest: 0,
    current_perpetual_discount: 0,
    description: '',
    discounts: [],
    due_date: new Date(new Date().getTime() + 3 * DIA).toISOString(),
    id: '11f820ad-6403-41a2-9fd3-eeaff2e8541b',
    installment: installment,
    installment_id: installment.id,
    original_amount: 80000,
    status: ReceivableStatuses.OPEN,
    type: '',
  })
  installment.receivables = [...receivable]
  return installment
}

export const openContractWithProductSchoolData: Contract = {
  ...openContract,
  debt_status: DebtStatus.OVERDUE,
  product: product1WithSchoolData,
  installments: [addReceivable(itemInstallment(openContract.id))],
}

export const openContractWithProductSchoolDataAndNextYearContracts: Contract = {
  ...openContractWithProductSchoolData,
  next_year_contracts: [
    {
      ...openContract,
      sign_payment_status: SignAndPaymentStatus.PAYMENT_AND_SIGN_PENDING,
    },
  ],
}

export const canceledContract: Contract = {
  created_at: '2021-01-27T17:29:18.701Z',
  due_day: 15,
  end_month: '2021-12-01',
  guardian: guardian1,
  guardian_id: guardian1.id,
  id: 'b87529a0-ed54-4840-84d0-0e59a369738f',
  next_year_contracts: null,
  product: product1,
  product_id: product1.id,
  reference_year: '2021',
  start_month: '2021-01-01',
  status: ContractStatus.CANCELED,
  student: student,
  student_id: student.id,
  total_amount: 1200000,
}

export const openContract2021: Contract = {
  created_at: '2021-01-27T17:29:18.701Z',
  due_day: 15,
  end_month: '2021-12-01',
  guardian: guardian1,
  guardian_id: guardian1.id,
  id: '7883379e-26a3-4d68-ae4c-9de30d2a2c5b',
  next_year_contracts: null,
  product: product1,
  product_id: product1.id,
  reference_year: '2021',
  start_month: '2021-01-01',
  status: ContractStatus.OPEN,
  student,
  student_id: student.id,
  total_amount: 1200000,
}

export const canceledContract2021: Contract = {
  created_at: '2021-01-27T17:29:18.701Z',
  due_day: 15,
  end_month: '2021-12-01',
  guardian: guardian1,
  guardian_id: guardian1.id,
  id: 'b87529a0-ed54-4840-84d0-0e59a369738f',
  next_year_contracts: null,
  product: product1,
  product_id: product1.id,
  reference_year: '2021',
  start_month: '2021-01-01',
  status: ContractStatus.CANCELED,
  student: student,
  student_id: student.id,
  total_amount: 1200000,
}
export const returnWorkingDate = {
  data: ['2021-01-27T17:29:18.701Z'],
}

export const contractsList = [openContract, canceledContract]

export const contractListCheckoutTest = [openContract, openContract2, canceledContract]

export const contractsList2021 = [openContract2021, canceledContract2021]

export const GroupGuardianData: GroupGuardian = {
  name: guardian1.name,
  students_names: ['student1', 'student2', 'student3'],
  products_names: ['product1', 'product2', 'product3'],
  tax_id: guardian1.tax_id,
  debt_status: DebtStatus.UP_TO_DATE,
  next_year_sign_status: SignAndPaymentStatus.PAYMENT_AND_SIGN_PENDING,
  guardian_id: guardian1.id,
}

export const GroupGuardianData2: GroupGuardian = {
  name: guardian2.name,
  students_names: ['child1', 'child2', 'child3'],
  products_names: ['product1', 'product2', 'product3'],
  tax_id: guardian2.tax_id,
  debt_status: DebtStatus.UP_TO_DATE,
  next_year_sign_status: SignAndPaymentStatus.PAYMENT_AND_SIGN_PENDING,
  guardian_id: guardian2.id,
}

export const listGroupGuardian: GroupGuardian[] = [GroupGuardianData, GroupGuardianData2]

export const Response = {
  status: 200,
  data: {
    token: 'qweasdzxc',
  },
}

export const checkDuplicationResponse = {
  is_duplicated: false,
  duplicated_contract_id: '8242cbce-ad7c-4167-8b2d-153abec5d038',
  seconds_elapsed_since_creation: 500,
}
