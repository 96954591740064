import { Student } from 'src/shared/interfaces'
import { guardian1, guardian2 } from './guardians'

export const bob: Student = {
  academic_number: '2811210',
  created_at: '2021-01-27T17:29:18.701Z',
  guardian_id: guardian1.id,
  id: '1092943d-d1f5-4d11-b2c4-0307f88d832b',
  name: 'Bob The Builder',
}
export const anduin: Student = {
  academic_number: '2811211',
  created_at: '2021-01-27T17:29:18.701Z',
  guardian_id: guardian2.id,
  id: 'cdcb8044-ddf1-463c-8717-9adf8e4f3afa',
  name: 'Anduin Wrynn',
}
export const william: Student = {
  academic_number: '2811212',
  created_at: '2021-01-27T17:29:18.701Z',
  guardian_id: guardian2.id,
  id: '200b1d09-0655-4aee-940c-e35a6f31a911',
  name: 'William Baker',
}
export const ogino: Student = {
  academic_number: '2811213',
  created_at: '2021-01-27T17:29:18.701Z',
  guardian_id: guardian1.id,
  id: '20126fd5-d4d7-4d95-aa08-205adf099616',
  name: 'Ogino Chihiro',
}
export const sasuke: Student = {
  academic_number: '2811214',
  created_at: '2021-01-27T17:29:18.701Z',
  guardian_id: guardian2.id,
  id: 'a5571533-466c-4feb-9c01-49d096ad4d06',
  name: 'Sasuke Uchiha',
}

export const student: Student = bob
export const studentList: Student[] = [bob, anduin, william, ogino, sasuke]
