import { useContext } from 'react'
import { REALMS, ROLES } from 'src/shared/constants'
import { JWT } from 'src/shared/contexts/jwtContext'

type UseJWTResponse = {
  hasPayoutReportAccess: boolean
  isAdmin: boolean
  isGuardian: boolean
  isSchool: boolean
  schoolId: string
  schoolIds: string[]
  userId: string
}

export const useJWT = (): UseJWTResponse => {
  const jwtProps = useContext(JWT)

  return {
    isAdmin: jwtProps?.realm === REALMS.ADMIN_REALM,
    isGuardian: jwtProps?.realm === REALMS.GUARDIAN_REALM,
    isSchool: jwtProps?.realm === REALMS.SCHOOL_REALM,
    hasPayoutReportAccess: jwtProps?.roles?.includes(ROLES.PAYOUT_REPORT_ACCESS),
    schoolId: jwtProps?.school_id,
    schoolIds: jwtProps?.school_ids,
    userId: jwtProps?.user_uuid,
  }
}
