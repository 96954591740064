import React, { FC } from 'react'
import EditGuardianMenuItem from './EditGuardianMenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Menu from '@material-ui/core/Menu'
import { SystemButton } from '@olaisaac/design-system'

export type CardMenuProps = {
  isProcessingAgglutination?: boolean
}
export const CardMenu: FC<CardMenuProps> = ({ isProcessingAgglutination }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <SystemButton onClick={handleClick} disabled={isProcessingAgglutination}>
        <MoreVertIcon />
      </SystemButton>
      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <div>
          {/*  Extra div to avoid Material UI forwardRef warning */}
          <EditGuardianMenuItem onClick={handleClose} />
        </div>
      </Menu>
    </>
  )
}
