import { FC, useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router'
import { useApi } from 'src/shared/hooks'
import Navbar from 'src/responsaveis/components/Navbar'
import View from 'src/responsaveis/components/View'
import Typography from '@material-ui/core/Typography'
import ContractCard from 'src/responsaveis/components/cards/Contract'
import { StudentSchoolMatch } from 'src/shared/interfaces'
import { ApplicationContext } from 'src/responsaveis/router/router'
import Container from '@material-ui/core/Container'
import * as FullStory from '@fullstory/browser'
import envConfig from 'src/config'
import SendEvent from 'src/shared/api/eventsClient'
import { EVENT_GROUPS, EVENT_CATEGORIES } from 'src/shared/constants'

export type DashboardPageParam = {
  guardianId: string
}

const DashboardPage: FC = () => {
  const { api } = useApi()
  const { guardianId } = useParams<DashboardPageParam>()
  const [matches, setMatches] = useState<StudentSchoolMatch[]>([])
  const {
    context: [appState, setAppState],
  } = useContext(ApplicationContext)

  useEffect(() => {
    if (!guardianId) return

    api.guardians.get(guardianId).then(resp => {
      setAppState({
        ...appState,
        guardian: resp,
      })
    })

    api.guardians.getStudentSchoolMatches(guardianId).then((resp: StudentSchoolMatch[] = []) => {
      setMatches(resp)
    })

    SendEvent({
      category: EVENT_CATEGORIES.GENERAL_CATEGORY,
      group: EVENT_GROUPS.GUARDIANS_GROUP,
      realm: 'responsaveis',
      type: 'portal-accessed',
      user_id: guardianId,
    })

    if (envConfig.FULLSTORY_ORG_ID) {
      FullStory.event('portal_access', {
        guardian_id: guardianId,
      })
    }
  }, [guardianId])

  // TODO: remove and replace with proper way of navigation
  const getNextURL = (schoolId, studentId) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [empty, dashboard, guardianId] = window.location.pathname.split('/')
    return `/contratos/${guardianId}/${schoolId}/${studentId}`
  }

  return (
    <>
      <View>
        <Container maxWidth="md">
          <Navbar />
          <Container maxWidth="md">
            <Typography variant="h6">Olá!</Typography>
            Estes são os alunos vinculados a você
          </Container>
          {matches.map(value => (
            <Container maxWidth="md" key={`container-${value.student_id}-${value.school_id}`}>
              <ContractCard
                schoolName={value.school_name}
                status={value.contracts_status}
                studentName={value.student_name}
                href={getNextURL(value.school_id, value.student_id)}
              />
            </Container>
          ))}
        </Container>
      </View>
    </>
  )
}

export default DashboardPage
