import Typography from '@material-ui/core/Typography'
import { Checkbox, FormControlLabel } from '@olaisaac/design-system'
import { FC } from 'react'
import Box from '@material-ui/core/Box'
import { formatFilter } from './util'
import theme from 'src/shared/theme'
import { FilterSelection } from './types'

export type ListSectionOptionsCheckboxFilterProps = {
  filterSelection: FilterSelection
  handleclick: (title: string, label: string | number, isChecked: boolean) => void
}

export const ListSectionOptionsCheckboxFilter: FC<ListSectionOptionsCheckboxFilterProps> = ({
  filterSelection,
  handleclick,
}) => {
  const formattedFilter = formatFilter(filterSelection)
  return (
    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
      {formattedFilter.map(section => (
        <Box sx={{ pr: 4, minWidth: '200px' }} key={section.group}>
          <Typography>{section.group[0].toUpperCase() + section.group.substring(1)}</Typography>
          <Box>
            {section.options.map(({ label, checked, value }) => (
              <FormControlLabel
                style={{ display: 'flex', marginTop: `${theme.spacing(2)}px` }}
                key={label}
                checked={checked}
                control={
                  <Checkbox
                    onChange={() => {
                      handleclick(section.group, label, !checked)
                    }}
                  />
                }
                value={value}
                label={label}
              />
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  )
}
