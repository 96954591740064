import { FC, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { PropTypes, Container, Box, Hidden } from '@material-ui/core'
import { formatCentsToReal, monthsMin, beforeOrEqualsToday } from 'src/shared/utils'
import { Dayjs } from 'dayjs'
import { GuardianContractOverviewStatus, InstallmentOverviewStatus } from 'src/shared/interfaces'
import { ApplicationContext } from 'src/responsaveis/router/router'

const useStyles = makeStyles(() =>
  createStyles({
    colorPrimary: {
      backgroundColor: '#E9EAFB',
      color: '#3D4ED7',
    },
    colorSecondary: {
      backgroundColor: '#FFECED',
      color: '#FF4621',
    },
  })
)

const BottomLine = styled.span`
  color: #757575;
`

const BottomRedLine = styled(BottomLine)`
  color: red;
`

const StyledPaper = styled(Paper)`
  margin: 16px 0 16px 0;
  width: 100%;

  padding: 24px 0 30px 22px;
`

const StyledChip = styled(Chip)`
  margin: 24px 0 0 0;
`

const StyledChevronRightIcon = styled(ChevronRightIcon)`
  color: #3d4ed7;
  margin: 28px 0 0 0;
`

// TODO: move this to a proper data location
export const contractStatusToInstallmentStatus = (
  status: GuardianContractOverviewStatus
): InstallmentOverviewStatus =>
  ({
    [GuardianContractOverviewStatus.WAITING_PAYMENT]: InstallmentOverviewStatus.WAITING_PAYMENT,
    [GuardianContractOverviewStatus.NONE_PENDING]: InstallmentOverviewStatus.NONE_PENDING,
  }[status] ?? InstallmentOverviewStatus.ALL_PAID)

export type InstallmentCardProps = {
  dueDate: Dayjs
  href?: string
  productName: string
  split: number
  status: InstallmentOverviewStatus
  totalSplit: number
  value: number
}

type ChipProp = {
  color: Exclude<PropTypes.Color, 'inherit'>
  message: string
}

const getInstallmentMessages = ({
  status,
  split,
  totalSplit,
  dueDate,
  isExpired,
}: {
  dueDate: Dayjs
  isExpired: boolean
  split: number
  status: InstallmentOverviewStatus
  totalSplit: number
}): {
  bottomLineMessage: string
  color: 'primary' | 'default' | 'secondary'
  extraLineMessage?: string
  message: string
} =>
  ({
    [InstallmentOverviewStatus.ALL_PAID]: {
      color: 'default' as const,
      message: 'Tudo pago',
      bottomLineMessage: 'Você já pagou todas as parcelas',
    },
    [InstallmentOverviewStatus.NONE_PENDING]: {
      color: 'primary' as const,
      message: 'Em dia',
      bottomLineMessage: `Parcela ${split} de ${totalSplit} •  Vence em ${dueDate.date()}/${
        monthsMin[dueDate.month() + 1]
      }`,
    },
    [InstallmentOverviewStatus.WAITING_PAYMENT]: {
      color: 'secondary' as const,
      message: 'Aguardando pagamento',
      bottomLineMessage: isExpired
        ? `Parcela ${split} de ${totalSplit} • `
        : `Parcela ${split} de ${totalSplit} • Vence em ${dueDate.date()}/${
            monthsMin[dueDate.month() + 1]
          }`,
      extraLineMessage: isExpired
        ? `Venceu em ${dueDate.date()}/${monthsMin[dueDate.month() + 1]}`
        : undefined,
    },
  }[status])

const InstallmentCard: FC<InstallmentCardProps> = ({
  dueDate,
  href,
  productName,
  split,
  status,
  totalSplit,
  value,
}) => {
  const classes = useStyles()
  const history = useHistory()

  const {
    context: [appState, setAppState],
  } = useContext(ApplicationContext)

  const { bottomLineMessage, extraLineMessage, color, message } = getInstallmentMessages({
    dueDate,
    isExpired: beforeOrEqualsToday(dueDate),
    split,
    status,
    totalSplit,
  })

  const ChipProp: ChipProp = {
    color,
    message,
  }

  const InstallmentStatusText = () => (
    <StyledChip
      color={ChipProp.color}
      label={ChipProp.message}
      classes={{
        colorPrimary: classes.colorPrimary,
        colorSecondary: classes.colorSecondary,
      }}
    />
  )

  const hrefAnchorClick = () => {
    const ids = href.split('/')

    setAppState({
      ...appState,
      contractId: ids[2],
    })

    history.push(href)
  }

  return (
    <Container maxWidth="md">
      <StyledPaper onClick={hrefAnchorClick} square>
        <Box display="flex">
          <Box flexGrow={90}>
            <Typography variant="caption" color="textSecondary">
              {productName}
            </Typography>
            <Typography variant="h4">{formatCentsToReal(value)}</Typography>
            <BottomLine>
              {bottomLineMessage}
              <BottomRedLine>{extraLineMessage}</BottomRedLine>
            </BottomLine>
            <br />
            <Hidden mdUp>
              <InstallmentStatusText />
            </Hidden>
          </Box>

          <Box display="flex" flexGrow={10} alignItems="flex-end" mr="18px" flexDirection="column">
            <Box display="flex" flexDirection="column" flexGrow={10} alignItems="flex-end">
              <Box flexGrow={90} mr="10px" mt="-20px" mb="20px">
                <Hidden smDown>
                  <InstallmentStatusText />
                </Hidden>
              </Box>

              <Box display="flex" flexGrow={10} alignSelf="flex-end">
                <Hidden smDown>
                  <Box flexGrow={90} alignSelf="flex-end" paddingBottom="8px">
                    <Typography color="primary" variant="body2">
                      Ver mais
                    </Typography>
                  </Box>
                </Hidden>
                <Box flexGrow={10} alignSelf="flex-end">
                  <StyledChevronRightIcon />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </StyledPaper>
    </Container>
  )
}

export default InstallmentCard
