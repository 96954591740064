import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { propEq, assocPath } from 'ramda'
import { Receivable } from 'src/shared/interfaces'
import DefaultContent from 'src/escolas/components/contract/InstallmentDrawer/DefaultContent'
import DiscountContent from 'src/escolas/components/contract/InstallmentDrawer/AddDiscountContent'
import ManualLiquidationContent from 'src/escolas/components/contract/InstallmentDrawer/ManualLiquidationContent'
import ConfirmManualLiquidationContent from 'src/escolas/components/contract/InstallmentDrawer/ConfirmManualLiquidationContent'
import RenegotiationContent from 'src/escolas/components/contract/InstallmentDrawer/RenegotiationContent'
import { DrawerState } from 'src/escolas/components/contract/InstallmentDrawer/constants'
import { useContract } from 'src/escolas/hooks'

export type DrawerContentProps = {
  callbackCheckout?: Dispatch<SetStateAction<boolean>>
  drawerState: DrawerState
  removeIsaacOption?: boolean
  resetManualLiquidationForm?: boolean
  selectedReceivableId: uuid
  setDrawerState: Dispatch<SetStateAction<DrawerState>>
  setIsFailureFeedbackOpen?: (value: boolean) => void
  setSelectedReceivableId: Dispatch<SetStateAction<uuid>>
}

const DrawerContent: FC<DrawerContentProps> = ({
  drawerState,
  selectedReceivableId,
  setDrawerState,
  setSelectedReceivableId,
  removeIsaacOption,
  callbackCheckout,
  resetManualLiquidationForm,
  setIsFailureFeedbackOpen,
}) => {
  const { contract, setContract } = useContract()

  const installment = contract?.installments.find(installment =>
    installment?.receivables.some(propEq('id', selectedReceivableId))
  )
  const receivables = installment?.receivables

  const replaceReceivables = (replacingReceivables: Receivable[]) => {
    const installmentIndex = contract?.installments?.findIndex(item => item.id === installment.id)
    setContract(
      assocPath(['installments', installmentIndex, 'receivables'], replacingReceivables, contract)
    )
    if (removeIsaacOption && callbackCheckout) {
      callbackCheckout(true)
    }
  }

  const goBackToDefaultContent = () => setDrawerState(DrawerState.DEFAULT)

  const goBackToConfirmManualLiquidation = () =>
    setDrawerState(DrawerState.CONFIRM_MANUAL_LIQUIDATION)

  const [manualLiquidationInfo, setManualLiquidationInfo] = useState({
    submitHandler: null,
  })

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const goToConfirmManualLiquidationContent = submitHandler => {
    goBackToConfirmManualLiquidation()
    setManualLiquidationInfo({ submitHandler })
  }

  useEffect(() => {
    goBackToDefaultContent()
  }, [selectedReceivableId])

  const drawerContentMap = {
    [DrawerState.DEFAULT]: (
      <DefaultContent
        receivables={receivables}
        selectedReceivableId={selectedReceivableId}
        setDrawerState={setDrawerState}
      />
    ),
    [DrawerState.MANUAL_LIQUIDATION]: (
      <ManualLiquidationContent
        onFinish={goBackToDefaultContent}
        receivables={receivables}
        selectedReceivableId={selectedReceivableId}
        successCallback={replaceReceivables}
        setSelectedReceivableId={setSelectedReceivableId}
        removeIsaacOption={removeIsaacOption}
        goToConfirmManualLiquidationContent={goToConfirmManualLiquidationContent}
        setIsSubmitting={setIsLoading}
        resetManualLiquidationForm={resetManualLiquidationForm}
        setIsFailureFeedbackOpen={setIsFailureFeedbackOpen}
      />
    ),
    [DrawerState.CONFIRM_MANUAL_LIQUIDATION]: (
      <ConfirmManualLiquidationContent
        submitHandler={manualLiquidationInfo.submitHandler}
        isLoading={isLoading}
      />
    ),
    [DrawerState.RENEGOTIATION]: (
      <RenegotiationContent
        onFinish={goBackToDefaultContent}
        receivables={receivables}
        selectedReceivableId={selectedReceivableId}
        successCallback={replaceReceivables}
        setSelectedReceivableId={setSelectedReceivableId}
      />
    ),
    [DrawerState.ADD_DISCOUNTS]: (
      <DiscountContent
        onFinish={goBackToDefaultContent}
        receivables={receivables}
        selectedReceivableId={selectedReceivableId}
        successCallback={replaceReceivables}
        setSelectedReceivableId={setSelectedReceivableId}
      />
    ),
  }

  return drawerContentMap[drawerState]
}

export default DrawerContent
