import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import MuiTableRow from '@material-ui/core/TableRow'
import theme from 'src/shared/theme'
import { alpha } from '@material-ui/core/styles'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { identity, omit, pipe } from 'ramda'
import { TableColumns } from 'src/escolas/components/Table/TableColumnCell'
import TableCell from 'src/escolas/components/Table/TableCell'
import { SystemButton } from '@olaisaac/design-system'
import { CombinableReceivable } from 'src/escolas/contexts/receivablesAgglutinationContext'

const StyledMuiTableRow = styled(MuiTableRow)`
  color: ${({ theme }) => theme.palette.text.secondary};
  &.MuiTableRow-hover:hover {
    background-color: ${({ theme }) => alpha(theme.palette.primary.main, 0.08)};
    color: ${({ theme }) => theme.palette.primary.main};
  }
  &&.Mui-selected {
    background-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }
  &.child {
    background-color: ${({ theme }) => alpha(theme.palette.secondary.light, 0.12)};
  }
  &&.isExpandedWithChildren {
    background-color: ${({ theme }) => theme.palette.secondary.light};
    color: ${({ theme }) => theme.palette.secondary.contrastText};
  }
  &.row-disabled {
    background-color: ${({ theme }) => theme.palette.background.default};
    color: ${({ theme }) => theme.palette.text.disabled};
  }
  &.row-text-disabled {
    color: ${({ theme }) => theme.primitiveTokens.colors.gray[20]};
  }
`

const StyledTableCell = styled(props => <TableCell {...omit(['isOpen'], props)} />)`
  border-width: ${({ isOpen }) => (isOpen ? 1 : 0)}px;
  height: ${({ isOpen }) => (isOpen ? 48 : 0)}px;
  transition: height ${({ theme }) => theme.transitions.duration.standard}ms
    ${({ theme }) => theme.transitions.easing.easeInOut} 0ms;
  padding: 0px 18px;
`

type TableRow<T> = T & {
  children?: Array<TableRow<T>>
  key: string
}

export type TableRowParams<T extends Record<string, any> = Record<string, any>> = {
  columns: TableColumns
  row: TableRow<T>
}

export type TableRowProps<
  T extends Record<string, any> = Record<string, any>
> = TableRowParams<T> & {
  ActionComponent?: (row) => JSX.Element
  disabled?: boolean
  isChild?: boolean
  isParentOpen?: boolean
  onRowClick?: (e: any, params: TableRowParams<T>) => void
  rowsSelected?: Array<string | CombinableReceivable> // https://github.com/microsoft/TypeScript/issues/33591#issuecomment-786443978
  rowTextDisabled?: (row: TableRow<T>) => boolean
}

type ExpandIconAdornmentProps = {
  isOpen?: boolean
  isVisible?: boolean
  onClick?: () => void
}

const ExpandIconAdornment: FC<ExpandIconAdornmentProps> = ({ isOpen, isVisible, onClick }) => {
  if (!isVisible) {
    return null
  }
  return (
    <SystemButton aria-label="expand row" onClick={onClick}>
      {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    </SystemButton>
  )
}

function TableRow<T extends Record<string, any> = Record<string, any>>({
  isChild,
  columns,
  isParentOpen = true,
  onRowClick,
  row,
  rowsSelected,
  ActionComponent,
  disabled,
  rowTextDisabled,
}: TableRowProps<T>) {
  const [open, setOpen] = useState(false)
  const isSelected = ((): boolean => {
    if (!rowsSelected?.length) return false
    if (typeof rowsSelected[0] === 'string') {
      return rowsSelected?.filter(Boolean).includes(row?.id)
    } else {
      const rs = rowsSelected as CombinableReceivable[] // TODO swap with real value
      return Boolean(rs?.find(r => r?.id === row?.id))
    }
  })()

  const isRowExpandedAndHasChildren = open && row?.children

  const handleRowClick = (e: any = null) => {
    if (typeof onRowClick === 'function') {
      onRowClick({ row, columns }, e)
    }
  }

  const colsWidth = columns.map(el => el.small || 'fixed')
  const countFixed = colsWidth.filter(el => el === 'fixed').length
  let fixedPercent = ((countFixed + 1) / (colsWidth.length * countFixed)) * 100
  fixedPercent = countFixed < columns.length ? fixedPercent : 100 / countFixed

  useEffect(() => {
    if (!isParentOpen) {
      setOpen(false)
    }
  }, [isParentOpen])

  return (
    <>
      <StyledMuiTableRow
        className={`${isChild ? 'child' : ''} ${
          isRowExpandedAndHasChildren ? 'isExpandedWithChildren' : ''
        } ${disabled ? 'row-disabled' : ''} ${
          rowTextDisabled && rowTextDisabled(row) ? 'row-text-disabled' : ''
        }`}
        theme={theme}
        hover
        selected={isSelected}
        onClick={handleRowClick}
        data-testid={`table-row${row?.id}`}
      >
        {columns.map(({ field, renderCell = identity, valueFormatter = identity }, index) => {
          const shouldShowExpandIcon = index === 0 && Boolean(row?.children?.length)
          const shouldShowActionComponent = Boolean(ActionComponent) && index === 0
          const formattedValue = row?.[field] && pipe(valueFormatter, renderCell)(row?.[field])
          const colWidth = colsWidth[index] === 'fixed' ? fixedPercent.toPrecision(4) + '%' : 'auto'
          return (
            <StyledTableCell key={field} isOpen={isParentOpen} theme={theme} width={colWidth}>
              <Collapse in={isParentOpen} timeout="auto" unmountOnExit>
                <Box display="flex" alignItems="center">
                  {shouldShowActionComponent && ActionComponent(row)}
                  <ExpandIconAdornment
                    isOpen={open}
                    isVisible={shouldShowExpandIcon}
                    onClick={() => setOpen(!open)}
                  />
                  {formattedValue}
                </Box>
              </Collapse>
            </StyledTableCell>
          )
        })}
      </StyledMuiTableRow>
      {row?.children?.map(childRow => {
        return (
          <TableRow<T>
            isChild
            isParentOpen={open}
            key={childRow.key}
            row={childRow}
            columns={columns}
            onRowClick={onRowClick}
            rowsSelected={rowsSelected}
            rowTextDisabled={rowTextDisabled}
            ActionComponent={ActionComponent}
          />
        )
      })}
    </>
  )
}

export default TableRow
