import styled from 'styled-components'
import MenuMui from '@material-ui/core/Menu'
import ExitToAppIconMui from '@material-ui/icons/ExitToApp'
import MenuIconMui from '@material-ui/icons/Menu'

export const Menu = styled(MenuMui).attrs(() => ({
  transformOrigin: {
    horizontal: 'right',
    vertical: 'bottom',
  },
  anchorOrigin: {
    horizontal: 'right',
    vertical: 'bottom',
  },
}))`
  top: 56px;
`

export const MenuContent = styled.div`
  padding: ${({ theme }) => theme.spacing(1)}px ${({ theme }) => theme.spacing(2)}px;
  margin-right: ${({ theme }) => theme.spacing(8)}px;
`

export const ExitToAppIcon = styled(ExitToAppIconMui)`
  margin-right: ${({ theme }) => theme.spacing(1)}px;
  fill: ${({ theme }) => theme.primitiveTokens.colors.gray[60]};
`

export const MenuIconIsaacBlue = styled(MenuIconMui)`
  fill: ${({ theme }) => theme.primitiveTokens.colors.isaacBlue[60]};
`

export const MenuIconWhite = styled(MenuIconMui)`
  fill: ${({ theme }) => theme.primitiveTokens.colors.gray[0]};
`
