import { FC, useState } from 'react'
import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import Drawer from '@material-ui/core/Drawer'
import { useSnackbar, useAgglutination } from 'src/escolas/hooks'
import { useApi } from 'src/shared/hooks'
import SelectedReceivableAgglutination from './SelectedReceivableAgglutination'
import AgglutinationDueDate from './AgglutionationDueDate'
import AgglutinationPayment from './AgglutinationPayment'
import AgglutinationDiscount from './AgglutinationDiscount'
import { CombinableReceivable } from 'src/escolas/contexts/receivablesAgglutinationContext'
import {
  FailureFeedbackContent,
  FailureFeedbackDialog,
} from 'src/escolas/components/modal/ConfirmationDialog'
import { Contract, Agglutination } from 'src/shared/interfaces'
import { UseFormReturn } from 'react-hook-form'
import dayjs from 'dayjs'
import { formatCentsToReal } from 'src/shared/utils'
import {
  ActionDrawerHeader,
  Button,
  ButtonDocker,
  Divider,
  DialogContent,
} from '@olaisaac/design-system'

const StyledDialogContent = styled(DialogContent)`
  position: relative;
`

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    width: calc(((100% - 160px) / 12) * 4);
    transition: transform 0.3s cubic-bezier(0.42, 0.29, 0.39, 0.83) !important;
  }
`

export type AgglutinationDrawerProps = {
  disableChecks: () => void
  enableChecks: () => void
  form: UseFormReturn<any>
  isOpen: boolean
  isProcessingAgglutination: boolean
  onClose: () => void
  orderReference: string
  receivables: CombinableReceivable[]
  selectedReceivableId: uuid
  setContract: (contract: Contract) => void
}

const AgglutinationDrawer: FC<AgglutinationDrawerProps> = ({
  isOpen,
  onClose,
  form,
  setContract,
  disableChecks,
  isProcessingAgglutination,
  enableChecks,
}) => {
  const { setMessage: setSnackbarMessage, setIsOpen: setSnackbarIsOpen } = useSnackbar()
  const { finalAmount, combinedReceivables, baseAmount, discount, setDiscount } = useAgglutination()
  const { api } = useApi()
  const [apiError, setApiError] = useState(null)
  const [waitingApi, setWaitingApi] = useState<boolean>(false)

  const finishAgglutination = ({ discount_amount, due_date, payment_methods, from }) => {
    const payload: Agglutination = {
      from,
      payment_methods,
      to: { discount_amount, due_date },
    }
    setWaitingApi(true)
    disableChecks()
    api.receivables
      .agglutinate(payload)
      .then(contract => {
        setSnackbarMessage('Aglutinação realizada com sucesso')
        setSnackbarIsOpen(true)
        setContract(contract)
        onClose()
      })
      .catch(setApiError)
      .finally(() => {
        setWaitingApi(false)
        enableChecks()
      })
  }
  const getParameters = () => {
    const from = combinedReceivables.map(x => x.id)
    const date = form.getValues('due_date')
    const dueDate = date ? dayjs(date).utc().set('hour', 0).set('minute', 0).set('second', 0) : null

    finishAgglutination({
      discount_amount: form.getValues('discount') ?? 0,
      payment_methods: form.getValues('payment_method'),
      due_date: dueDate,
      from,
    })
  }

  const formHasErrors = Object.keys(form.formState?.errors).length > 0
  const canSubmit = combinedReceivables.length > 1

  const description = canSubmit
    ? `Valor da nova parcela ${formatCentsToReal(finalAmount)}`
    : 'Selecione mais de uma parcela'

  return (
    <StyledDrawer anchor="right" variant="persistent" open={isOpen}>
      <ActionDrawerHeader onClose={onClose} title="Aglutinar parcelas" />

      <StyledDialogContent>
        <Box mt={4}>
          <SelectedReceivableAgglutination
            receivables={combinedReceivables}
            baseAmount={baseAmount}
          />
        </Box>

        <Divider />
        <AgglutinationDiscount
          totalAmountPaid={baseAmount}
          amountDefaultValue={0}
          discount={discount}
          setDiscount={setDiscount}
          form={form}
          disableForm={isProcessingAgglutination}
        />
        <Divider />
        <AgglutinationDueDate form={form} disableForm={isProcessingAgglutination} />
        <Divider />
        <AgglutinationPayment form={form} disableForm={isProcessingAgglutination} />
      </StyledDialogContent>

      <ButtonDocker description={description}>
        <Button
          variation="primary"
          fullWidth
          onClick={getParameters}
          disabled={formHasErrors || !canSubmit}
          loading={waitingApi}
        >
          Finalizar aglutinação
        </Button>
      </ButtonDocker>

      <FailureFeedbackDialog
        buttonLabel="Fechar"
        isVisible={!!apiError}
        onClose={() => setApiError(null)}
        submitHandler={() => setApiError(null)}
        centered
      >
        <FailureFeedbackContent />
      </FailureFeedbackDialog>
    </StyledDrawer>
  )
}

export default AgglutinationDrawer
