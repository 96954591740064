import { AxiosResponse } from 'axios'
import { assoc, propEq, assocPath } from 'ramda'
import { SEARCH_BY } from 'src/escolas/components/contract/2022/contracts2022Checkout'
import { api, GetContractParams } from 'src/shared/api'
import { GetCheckoutParams, StartCheckoutRequestBody } from 'src/shared/interfaces'

import {
  studentList,
  student,
  invoice1,
  school1,
  schoolsList,
  installment1,
  installmentsList,
  openContract,
  contractsList,
  product1,
  productsList,
  guardiansList,
  guardian1,
  receivables,
  renegotiationResp,
  receivablesPaid,
  LiquidationResponse,
  presigned,
  processingResponse,
  guardiansStudentSchoolMatchesList,
  contractsOverviewsList,
  contractsList2021,
  returnWorkingDate,
  mockCheckout,
  onboardingGroupStatus,
  listGroupGuardian,
  checkDuplicationResponse,
} from 'src/shared/mocks'

export const mockApiMethods: ReturnType<typeof api> = {
  auth: {
    logout: (async () => true) as any,
  },
  checkout: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getCheckout: async (id: uuid, params?: GetCheckoutParams) => mockCheckout,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    startCheckout: async (body: StartCheckoutRequestBody) => mockCheckout,
  },
  contracts: {
    bulkEditDiscountsCreate: async () => contractsList[0],
    bulkEditDiscountsInfo: async () => ({
      original_installments: [installment1],
      new_installments: [installment1],
    }),
    changeDueDayCreate: async () => contractsList[0],
    changeDueDayInfo: async () => ({
      original_installments: [installment1],
      new_installments: [installment1],
    }),
    create: async () => openContract,
    get: async (_, getParams?: GetContractParams) => {
      let contract = openContract
      if (getParams?.include_guardian) {
        contract = assoc('guardian', guardian1, contract)
      }
      if (getParams?.include_student) {
        contract = assoc('student', student, contract)
      }
      if (getParams?.include_installments) {
        contract = assoc('installments', installmentsList, contract)
        if (getParams?.include_receivables) {
          contract = assoc(
            'installments',
            installmentsList.map((inst, idx) =>
              assoc('receivables', idx % 2 ? receivables : receivablesPaid, inst)
            ),
            contract
          )
        }
      }

      return contract
    },
    getList: async ({ status }) => {
      const filteredContracts = contractsList.filter(
        ({ status: currentStatus }) => !status || status === currentStatus
      )
      return {
        data: filteredContracts,
        pagination: { total: filteredContracts.length },
      }
    },
    getListCheckout: async ({ search_by, status }) => {
      if (search_by === SEARCH_BY.GUARDIAN) {
        return {
          data: listGroupGuardian,
          pagination: { total: listGroupGuardian.length },
        }
      }
      const filteredContracts = contractsList.filter(
        ({ status: currentStatus }) => !status || status === currentStatus
      )
      return {
        data: filteredContracts,
        pagination: { total: filteredContracts.length },
      }
    },
    getList2021: async ({ status }) => {
      const filteredContracts = contractsList2021.filter(
        ({ status: currentStatus }) => !status || status === currentStatus
      )
      return {
        data: filteredContracts,
        pagination: { total: filteredContracts.length },
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getOverviewsBySchoolStudent: async (schoolId, studentId) => contractsOverviewsList,
    getReenrollmentStats: async () => ({
      data: {
        in_progress_count: 1,
        reenrolled_count: 2,
        total_open_contracts: 10,
      },
    }),
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    manualSign: async () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    recreateInvoices: async () => {
      return openContract
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    revoke: async () => {},
    checkDuplication: async () => checkDuplicationResponse,
  },
  date: {
    getWorkingDueDates: async () => returnWorkingDate,
  },
  guardians: {
    create: async () => guardian1,
    get: async (id: uuid) => guardiansList.find(propEq('id', id)),
    getAddressFromZip: async () => guardian1.address,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getContracts: async (id: uuid) => contractsList,
    getList: async () => ({ data: guardiansList, pagination: { total: guardiansList.length } }),
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getStudentSchoolMatches: async (id: uuid) => guardiansStudentSchoolMatchesList,
    update: async () => guardian1,
  },
  installments: {
    getList: async () => ({
      data: installmentsList,
      pagination: { total: installmentsList.length },
    }),
    get: async () => installment1,
  },
  invoices: {
    get: async () => invoice1,
  },
  presigned: {
    uploadFile: async () => null,
  },
  products: {
    create: async () => product1,
    delete: async () => null,
    get: async () => product1,
    getList: async () => ({ data: productsList, pagination: { total: productsList.length } }),
    update: async () => product1,
  },
  receivables: {
    addDiscounts: async () => receivables,
    liquidationInfo: async () => ({
      final_amount: 9000,
      final_base_amount: 10000,
      final_discount: 1000,
      final_fine_and_interest: 100,
      fine_and_interest: 100,
      lost_due_payment_discount: 1000,
      lost_early_payment_discount: 1000,
      previous_due_payment_discount: 0,
      previous_early_payment_discount: 0,
      previous_fines_and_interest: 0,
    }),
    manualLiquidation: async () => LiquidationResponse,
    patchManualLiquidation: async () => LiquidationResponse,
    printReceipt: async () => ({ data: '' } as AxiosResponse),
    printReceiptCheckout: async () => ({ data: '' } as AxiosResponse),
    renegotiate: async renegotiation =>
      assocPath(['renegotiated', 'id'], renegotiation.from, renegotiationResp),
    // eslint-disable-next-line sort-keys
    agglutinate: async () => openContract,
  },
  schools: {
    downloadReport: async () => null,
    get: async () => school1,
    getBySlug: async () => school1,
    getList: async () => ({ data: schoolsList, pagination: { total: schoolsList.length } }),
    getPresignedUrl: async () => presigned,
    processUploadedFile: async () => ({ data: processingResponse }),
  },
  students: {
    getList: async () => ({ data: studentList, pagination: { total: studentList.length } }),
    get: async () => student,
    createOrUpdate: async () => student,
    update: async () => student,
  },
  report: {
    download: async () => ({}),
    summary: async () => ({}),
  },
  selfOnboarding: {
    getStatus: async () =>
      new Promise(resolve => {
        setTimeout(
          () =>
            resolve({
              status: onboardingGroupStatus,
            }),
          2000
        )
      }),
  },
}

export default () => mockApiMethods
