import { FC } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import Box from '@material-ui/core/Box'

const HeadBlock = styled.div`
  width: 100%;
  height: 56px;

  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 56px;
  letter-spacing: 0.15000000596046448px;
  text-align: center;

  color: rgba(0, 0, 0, 0.6);

  margin: 0 0 0 0;
`

const StyledChevronLeftIcon = styled(ChevronLeftIcon)`
  color: #3d4ed7;
  margin: 18px 0 0 0;
  position: relative;
  left: 0%;
`

export type HeadNavProps = {
  href?: string
  style?: React.CSSProperties
}

const HeadNavigation: FC<HeadNavProps> = ({ href, style, children }) => {
  const history = useHistory()

  const classes = makeStyles(() =>
    createStyles({
      root: {
        ...style,
      },
    })
  )()

  const hrefAnchorClick = (link: string) => {
    return () => {
      history.push(link)
    }
  }

  return (
    <HeadBlock onClick={hrefAnchorClick(href)} className={classes.root}>
      <Box display="flex" alignItems="flex-start">
        <Box flexGrow={1} alignSelf="flex-start">
          <StyledChevronLeftIcon />
        </Box>
        <Box flexGrow={99} alignSelf="center">
          {children}
        </Box>
      </Box>
    </HeadBlock>
  )
}

export default HeadNavigation
