import styled from 'styled-components'
import { Controller, UseFormReturn } from 'react-hook-form'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { DatePicker } from '@olaisaac/design-system'
import { omit } from 'ramda'
import { DATE_FORMAT } from 'src/shared/utils'
import { useAgglutination } from 'src/escolas/hooks'
import { typography } from '@material-ui/system'

const StyledTypography = styled(Typography)`
  ${typography}
`
export type AgglutinationDueDateProps = {
  disableForm: boolean
  dueDate?: string
  form: UseFormReturn<any>
}

const AgglutinationDueDate = ({ form, disableForm }: AgglutinationDueDateProps) => {
  const { control } = form

  const { dueDate, setDueDate } = useAgglutination()

  return (
    <>
      <Box pb={1} display="flex" justifyContent="space-between" paddingTop="15px">
        <StyledTypography variant="body2" fontSize="18px">
          Nova data de vencimento
        </StyledTypography>
      </Box>
      <Box mt={2} marginBottom="22px">
        <Controller
          rules={{ required: true }}
          name="due_date"
          control={control}
          defaultValue={dueDate}
          render={({ field }) => (
            <DatePicker
              label="Data de vencimento"
              format={DATE_FORMAT}
              disabled={disableForm}
              onAccept={date => setDueDate(date.toISOString())}
              {...omit(['ref'], field)}
              disablePast
              minDateMessage="A data não pode ser anterior a hoje"
              invalidDateMessage="Formato de data inválido"
              fullWidth
            />
          )}
        />
      </Box>
    </>
  )
}

export default AgglutinationDueDate
