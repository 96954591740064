import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Box from '@material-ui/core/Box'
import { Button, DialogActions, DialogContent, DialogTitle } from '@olaisaac/design-system'
import Dialog from '@material-ui/core/Dialog'
import Hidden from '@material-ui/core/Hidden'
import Snackbar from '@material-ui/core/Snackbar'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Dayjs } from 'dayjs'
import { FC, useEffect, useState } from 'react'
import { InstallmentStatuses, Invoice } from 'src/shared/interfaces'
import { beforeToday, formatCentsToReal, months } from 'src/shared/utils'
import styled from 'styled-components'
import AccordionHeader from './AccordionCollapsed'
import PayOrContactButtons from './PayOrContactButtons'
import { useApi } from 'src/shared/hooks'

export const useStyles = makeStyles(() =>
  createStyles({
    body2: {
      color: 'rgba(0, 0, 0, 0.6)',
    },
    colorError: {
      backgroundColor: '#e0e0e0',
    },
    colorPrimary: {
      backgroundColor: '#3D4ED7',
    },
    colorSecondary: {
      backgroundColor: '#FF4621',
    },
    textPrimary: {
      color: '#3D4ED7',
    },
  })
)

const SyledAccordionDetails = styled(AccordionDetails)`
  padding: 0 38px 0 38px;

  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
`

export const StyledButton = styled(Button)`
  margin: 24px 0;
  display: block;
`

const StyledDueDateTypography = styled(Typography)`
  color: ${props => props.theme.color};
`

export type ReceivableAccordionData = {
  baseAmount: cents
  currentAmount: cents
  discount: cents
  dueDate: Dayjs
  fee: cents
  fine: cents
  id: uuid
  invoice_id: uuid
  status: InstallmentStatuses // TODO: change type to ReceivableStatuses
}

export type ReceivableAccordionProps = {
  data: ReceivableAccordionData[]
  guardianId: uuid
  productName: string
}

const buildExpireDateMessage = (expired: boolean, day: number, month: string) => {
  if (expired) {
    return `Venceu em ${day}/${month}`
  }
  return `Vence em ${day}/${month}`
}

const ContactTextElement = (props: ReceivableAccordionData) => {
  const today = new Date()
  const datePlus30 = props.dueDate.add(30, 'day')

  const paymentDateExpired = datePlus30.isBefore(today)
  if (paymentDateExpired) {
    return (
      <Box>
        <Typography color="textSecondary" variant="body2">
          Entre em contato com a gente para efetuar o pagamento
        </Typography>
      </Box>
    )
  }

  return null
}

const ReceivableAccordion: FC<ReceivableAccordionProps> = ({ data, guardianId, productName }) => {
  const classes = useStyles()
  const { api } = useApi()

  const [expanded, setExpanded] = useState<number | false>()

  const [snackOpen, setSnackOpen] = useState<boolean>(false)
  const [snackErrorOpen, setSnackErrorOpen] = useState<boolean>(false)

  const [overdueInvoicesIdxs, setOverdueInvoicesIdxs] = useState<Set<number>>(new Set())
  const [hasOverdueInvoice, setHasOverdueInvoice] = useState<boolean>()
  const [pendingInvoiceFetch, setPendingInvoiceFetch] = useState<number | null>(null)

  const [invoice, setInvoice] = useState<Invoice>({} as Invoice)
  const [modal, setModal] = useState<{
    eventHook?: any
    open: boolean
  }>({
    open: false,
  })

  const fetchInvoice = async (index: number) => {
    const result = await api.invoices.get(data[index].invoice_id, { include_bankSlip: true })
    setInvoice(result)
  }

  useEffect(() => {
    const overdueInvoices: number[] = []
    const isPastInvoice = (index: number) => index < data.length && beforeToday(data[index].dueDate)

    for (let i = 0; isPastInvoice(i); i++) {
      if (data[i].status === InstallmentStatuses.OPEN) overdueInvoices.push(i)
    }

    setOverdueInvoicesIdxs(new Set(overdueInvoices))
    setHasOverdueInvoice(overdueInvoices.length > 0)
  }, [data])

  useEffect(() => {
    const shouldFetch = (index: number) => !data[index].dueDate.add(30, 'day').isBefore(new Date())

    for (let i = 0; i < data.length; i++) {
      if (data[i].status === InstallmentStatuses.OPEN) {
        setExpanded(i)
        if (shouldFetch(i)) {
          fetchInvoice(i)
        }
        return
      }
    }

    setExpanded(0)
    if (data.length > 0 && data[0].status === InstallmentStatuses.OPEN && shouldFetch(0)) {
      fetchInvoice(0)
    }
  }, [data])

  const handleChange = (index: number) => async (event, isExpanded: boolean) => {
    setExpanded(isExpanded ? index : false)
    if (isExpanded) {
      setPendingInvoiceFetch(index)
      await fetchInvoice(index)
      setPendingInvoiceFetch(null)
    }
  }

  const shouldUseInvoice = index => {
    if (expanded === index) return invoice
    return {} as Invoice
  }

  const modalHandler = (nextEvent: () => void) => () => {
    setModal({
      open: true,
      eventHook: nextEvent,
    })
  }

  const setInvoiceDataCopy = (code: string) => async () => {
    try {
      await navigator.clipboard.writeText(code)
      setSnackOpen(true)
      setModal({
        open: false,
      })
    } catch (error) {
      console.log('Error copying barcode', error)
      setSnackErrorOpen(true)
    }
  }

  const setInvoiceDataOpen = (checkoutUrl: string) => () => {
    window.open(checkoutUrl)
    setModal({
      open: false,
    })
  }

  const shouldShowInvoicePaymentWarning = (index: number) => {
    const isAdvancePayment = !overdueInvoicesIdxs.has(index)
    return hasOverdueInvoice && isAdvancePayment
  }

  const handleSnackClose = (event, reason?) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackOpen(false)
  }

  const handleSnackErrorClose = (event, reason?) => {
    if (reason === 'clickaway') {
      return
    }

    setSnackErrorOpen(false)
  }

  const handleModalClose = (event, reason?) => {
    if (reason === 'clickaway') {
      return
    }

    setModal({ open: false })
  }

  return (
    <div>
      {data.map((receivable, index) => (
        <Accordion key={receivable.id} expanded={expanded === index} onChange={handleChange(index)}>
          <AccordionHeader index={index} receivable={receivable} />
          <SyledAccordionDetails>
            <Box display="flex">
              <Box flexGrow={60}>
                <Box mb="16px" display="flex">
                  <Box flexGrow={1}>
                    <Typography color="textSecondary" variant="body2">
                      Valor integral:
                    </Typography>
                  </Box>
                  <Typography color="textSecondary" variant="body2">
                    {formatCentsToReal(receivable.baseAmount)}
                  </Typography>
                </Box>
                {receivable.discount !== 0 && (
                  <Box mb="16px" display="flex">
                    <Box flexGrow={1}>
                      <Typography color="textSecondary" variant="body2">
                        Desconto:
                      </Typography>
                    </Box>
                    <Typography color="textSecondary" variant="body2">
                      {formatCentsToReal(receivable.discount)}
                    </Typography>
                  </Box>
                )}
                {receivable.fine !== 0 && (
                  <Box mb="16px" display="flex">
                    <Box flexGrow={1}>
                      <Typography color="textSecondary" variant="body2">
                        Multa de atraso:
                      </Typography>
                    </Box>
                    <Typography color="textSecondary" variant="body2">
                      {formatCentsToReal(receivable.fine)}
                    </Typography>
                  </Box>
                )}
                {receivable.fee !== 0 && (
                  <Box mb="16px" display="flex">
                    <Box flexGrow={1}>
                      <Typography color="textSecondary" variant="body2">
                        Juros:
                      </Typography>
                    </Box>
                    <Typography color="textSecondary" variant="body2">
                      {formatCentsToReal(receivable.fee)}
                    </Typography>
                  </Box>
                )}

                <Box mb="24px">
                  {receivable.status === InstallmentStatuses.PAID ? (
                    <Typography variant="subtitle2" color="primary">
                      Parcela paga
                    </Typography>
                  ) : (
                    <>
                      <StyledDueDateTypography
                        variant="subtitle2"
                        theme={{
                          color: !beforeToday(receivable.dueDate) ? '#424242' : '#FF5442',
                        }}
                      >
                        {buildExpireDateMessage(
                          beforeToday(receivable.dueDate),
                          receivable.dueDate.date(),
                          months[receivable.dueDate.month() + 1]
                        )}
                      </StyledDueDateTypography>
                      {ContactTextElement(receivable)}
                    </>
                  )}
                </Box>

                <Hidden mdUp>
                  <PayOrContactButtons
                    props={receivable}
                    showWarningModal={shouldShowInvoicePaymentWarning(index)}
                    guardianId={guardianId}
                    invoice={shouldUseInvoice(index)}
                    modalHandler={modalHandler}
                    eventHandlerCopy={setInvoiceDataCopy}
                    eventHandlerOpen={setInvoiceDataOpen}
                    productName={productName}
                    isFetching={pendingInvoiceFetch === index}
                  />
                </Hidden>
              </Box>
              <Hidden smDown>
                <Box flexGrow={40}>
                  <PayOrContactButtons
                    props={receivable}
                    showWarningModal={shouldShowInvoicePaymentWarning(index)}
                    guardianId={guardianId}
                    invoice={shouldUseInvoice(index)}
                    modalHandler={modalHandler}
                    eventHandlerCopy={setInvoiceDataCopy}
                    eventHandlerOpen={setInvoiceDataOpen}
                    productName={productName}
                    isFetching={pendingInvoiceFetch === index}
                  />
                </Box>
              </Hidden>
            </Box>
          </SyledAccordionDetails>
        </Accordion>
      ))}

      <Dialog
        open={modal.open}
        onClose={handleModalClose}
        aria-labelledby="confirm-action-modal"
        aria-describedby="confirme-action-content-modal"
      >
        <DialogTitle>Tem certeza que gostaria de pagar esta parcela?</DialogTitle>
        <DialogContent>
          <Typography
            variant="body2"
            classes={{
              body2: classes.body2,
            }}
          >
            Existem outras parcelas mais recentes em aberto.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            classes={{
              textPrimary: classes.textPrimary,
            }}
            variation="primary"
            onClick={handleModalClose}
          >
            Não pagar
          </Button>
          <Button
            classes={{
              textPrimary: classes.textPrimary,
            }}
            variation="primary"
            // eslint-disable-next-line react/jsx-handler-names
            onClick={modal.eventHook}
          >
            Sim, pagar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackOpen}
        autoHideDuration={1200}
        message="Código copiado"
        onClose={handleSnackClose}
      />

      <Snackbar
        open={snackErrorOpen}
        autoHideDuration={1200}
        message="Falha ao copiar código"
        onClose={handleSnackErrorClose}
      />
    </div>
  )
}

export default ReceivableAccordion
