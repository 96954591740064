import axios from 'axios'
import Cookies, { CookieSetOptions } from 'universal-cookie'
import { school1 } from 'src/shared/mocks'

import Client from '@olaisaac/sorting-hat'
import config from 'src/config'

export type LoginPayload = {
  email?: string
  grant_type: string
  password: string
  tax_id?: string
}

type LoginSH = {
  access_token: string
  refresh_token: string
}

export enum CookiesKeys {
  AUTH = '__OISA-SH-AT',
  REALM = '__OISA-SH-TR',
  REFRESH = '__OISA-SH-RT',
}

// @TODO o sorting-hat pode retornar isso no services.token.IsAuthenticated
export const loginURL = `${config.SORTING_HAT.HOST}${config.SORTING_HAT.LOGIN_PATH}`

const sortingHatClient = () =>
  new Client({
    baseURL: config.SORTING_HAT.HOST,
    token: 'backoffice', // TODO this is not being used at the moment
    loginURL,
    http: null,
  })

const mockSortingHatClient = () => ({
  services: {
    token: {
      IsAuthenticated: () => ({}),
      Get: () => ({ realm: config.SORTING_HAT.REALM_MOCK, school_id: school1.id }),
    },
  },
})

export const AuthAPI = {
  login: async (login: LoginPayload, realm: string) =>
    (
      await axios.post<LoginSH>(`${config.SORTING_HAT.HOST}/api/v1/login/${realm}`, login, {
        withCredentials: false,
      })
    ).data,
}

const cookies = new Cookies()
const cookieDefaults: CookieSetOptions = {
  domain: config.API.COOKIES_DOMAIN,
  path: '/',
  sameSite: 'lax',
}

export const setCookies = (login: LoginSH, realm: string) => {
  cookies.set(CookiesKeys.AUTH, login.access_token, cookieDefaults)
  cookies.set(CookiesKeys.REFRESH, login.refresh_token, cookieDefaults)
  cookies.set(CookiesKeys.REALM, realm, cookieDefaults)
}

export const clearCookies = () => {
  const options: CookieSetOptions = { ...cookieDefaults, expires: new Date(0) }

  cookies.set(CookiesKeys.AUTH, null, options)
  cookies.set(CookiesKeys.REFRESH, null, options)
  cookies.set(CookiesKeys.REALM, null, options)
}

cookies.addChangeListener(({ name, value }) => {
  if (name === CookiesKeys.AUTH && !value) location.href = '/'
})

export default config.API.MOCK ? mockSortingHatClient() : sortingHatClient()
