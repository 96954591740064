import axios, { AxiosRequestConfig, AxiosResponse, AxiosStatic } from 'axios'
import {
  AddDiscountsReqBody,
  Contract,
  Student,
  School,
  Installment,
  Guardian,
  Product,
  APIResponse,
  Invoice,
  Renegotiation,
  Agglutination,
  RenegotiationResponseBody,
  CreateContractPayload,
  Receivable,
  LiquidationPostParams,
  Pagination,
  PresignedURL,
  RequestPresignedURL,
  ContractStatus,
  ContractCancellationReason,
  StudentSchoolMatch,
  ContractOverview,
  LiquidationGetParams,
  LiquidationInfoResponse,
  ReenrollmentStats,
  RESPONSE_STATUS,
  Discount,
  Checkout,
  StartCheckoutRequestBody,
  GetCheckoutParams,
  SelfOnboarding,
  GroupGuardian,
  Address,
  CheckDuplicationPayload,
  CheckDuplicationResponse,
} from 'src/shared/interfaces'
import { mockApiMethods } from 'src/shared/api/__mocks__'
import config from 'src/config'
import { mapObjIndexed } from 'ramda'
import { paramsToQueryString } from 'src/shared/utils'
import { clearCookies } from 'src/shared/api/sortingHatClient'
import wait from 'waait'
import { Report } from '../interfaces/reports'
import { EditedProduct } from 'src/escolas/components/product/EditProduct'
import { SEARCH_BY } from 'src/escolas/components/contract/2022/contracts2022Checkout'

export const PUBLIC_DOMAIN = `${config.API.URL}/api/v1`
const PRIVATE_DOMAIN = `${config.API.URL}/ws/v1`

export type GetContractParams = Partial<{
  include_guardian: boolean
  include_installments: boolean
  include_invoice: boolean
  include_product: boolean
  include_receivables: boolean
  include_signable_document?: boolean
  include_student: boolean
}>

type GetInvoicesParams = Partial<{
  include_bankSlip: boolean
}>

type ListContractsFilters = Partial<{
  name: string
  reference_year: string
  school_id: uuid
  search_by: string
  status: ContractStatus
  tax_id: string
}>

type ListDebtsFilters = Partial<{
  name: string
  school_id: uuid
  school_slug: string
  status: ContractStatus
}>

type ListProductsFilters = Partial<{
  school_id: uuid
}>

type ListStudentsFilters = Partial<{
  name: string
}>

type ListGuardiansFilters = Partial<{
  name: string
}>

type GetReenrollmentStatsParams = Partial<{
  reference_year: string
  school_id: uuid
}>
type WorkingDueDatesParams = {
  date: datestring
  number_due_dates: number
}

const proxyHandler = (setCorrelationId: (correlationId: string) => void): ProxyHandler<any> => ({
  get: function (target, prop) {
    return (...args) =>
      target[prop](...args).then(r => {
        const errorResponse: AxiosResponse = (r as any)?.response
        const statusCode = errorResponse?.status
        if (statusCode && statusCode >= 400) {
          setCorrelationId(errorResponse.headers['correlation-id'])
          if (statusCode === RESPONSE_STATUS.UNAUTHORIZED) return clearCookies()
          throw errorResponse
        }
        return r
      })
  },
})

const injectCorrelationId = (setCorrelationId: (correlationId: string) => void): AxiosStatic =>
  new Proxy(axios, proxyHandler(setCorrelationId))

export const api = (setCorrelationId: (correlationId: string) => void) => ({
  auth: {
    logout: async () => injectCorrelationId(setCorrelationId).get(`${PUBLIC_DOMAIN}/auth/logout`),
  },
  checkout: {
    getCheckout: async (id: uuid, params?: GetCheckoutParams) =>
      (
        await injectCorrelationId(setCorrelationId).get<APIResponse<Checkout>>(
          `${PRIVATE_DOMAIN}/checkout/${id}?${paramsToQueryString(params)}`
        )
      ).data.data,
    startCheckout: async (body: StartCheckoutRequestBody) =>
      (
        await injectCorrelationId(setCorrelationId).post<APIResponse<Checkout>>(
          `${PRIVATE_DOMAIN}/checkout/`,
          body
        )
      ).data.data,
  },
  contracts: {
    bulkEditDiscountsCreate: async (
      id: uuid,
      params: {
        discounts: Discount[]
        edit_reason?: string
        installment_id: uuid
      },
      schoolID: uuid
    ) =>
      (
        await injectCorrelationId(setCorrelationId).patch<APIResponse<Contract>>(
          `${PRIVATE_DOMAIN}/contract/${id}/edit-discounts?school_id=${schoolID}`,
          params
        )
      ).data.data,
    bulkEditDiscountsInfo: async (
      id: uuid,
      params: {
        discounts: Discount[]
        get_current_amount?: boolean
        installment_id: uuid
      }
    ) =>
      (
        await injectCorrelationId(setCorrelationId).post<
          APIResponse<{ new_installments: Installment[]; original_installments: Installment[] }>
        >(`${PRIVATE_DOMAIN}/contract/${id}/edit-discounts-info/`, params)
      ).data.data,
    changeDueDayCreate: async (
      id: uuid,
      params: {
        change_due_month: boolean
        change_reason_additional_information?: string
        due_day: number
        installment_id: uuid
        start_month: datestring
      }
    ) =>
      (
        await injectCorrelationId(setCorrelationId).patch<APIResponse<Contract>>(
          `${PRIVATE_DOMAIN}/contract/${id}/change-due-date/`,
          params
        )
      ).data.data,
    changeDueDayInfo: async (
      id: uuid,
      params: {
        change_due_month: boolean
        due_day: number
        installment_id: uuid
        start_month: datestring
      }
    ) =>
      (
        await injectCorrelationId(setCorrelationId).get<
          APIResponse<{ new_installments: Installment[]; original_installments: Installment[] }>
        >(`${PRIVATE_DOMAIN}/contract/${id}/change-due-date/?${paramsToQueryString(params)}`)
      ).data.data,

    create: async (contract: CreateContractPayload) =>
      (
        await injectCorrelationId(setCorrelationId).post<APIResponse<Contract>>(
          `${PRIVATE_DOMAIN}/contract/`,
          contract
        )
      ).data.data,
    get: async (id: uuid, getParams?: GetContractParams) =>
      (
        await injectCorrelationId(setCorrelationId).get<APIResponse<Contract>>(
          `${PRIVATE_DOMAIN}/contract/${id}?${paramsToQueryString(getParams)}`
        )
      ).data.data,
    getList: async (p?: Pagination & ListContractsFilters) => {
      return (
        await injectCorrelationId(setCorrelationId).get<APIResponse<Contract[]>>(
          `${PRIVATE_DOMAIN}/contract/?${paramsToQueryString(p)}`
        )
      ).data
    },
    getListCheckout: async (p?: Pagination & ListContractsFilters) => {
      return (
        await injectCorrelationId(setCorrelationId).get<APIResponse<Contract[] | GroupGuardian[]>>(
          `${PRIVATE_DOMAIN}/contract/${
            p.search_by === SEARCH_BY.GUARDIAN ? 'guardian/' : ''
          }?${paramsToQueryString(p)}`
        )
      ).data
    },
    getList2021: async (p?: Pagination & ListDebtsFilters) =>
      (
        await injectCorrelationId(setCorrelationId).get<
          APIResponse<(Contract & { contract_id?: string })[]>
        >(`${PRIVATE_DOMAIN}/contract/2021/?${paramsToQueryString(p)}`)
      ).data,
    getOverviewsBySchoolStudent: async (schoolID, studentId) =>
      (
        await injectCorrelationId(setCorrelationId).get<APIResponse<ContractOverview[]>>(
          `${PRIVATE_DOMAIN}/contract/guardian-overviews/${schoolID}/${studentId}`
        )
      )?.data.data,
    getReenrollmentStats: async (getParams?: GetReenrollmentStatsParams) =>
      (
        await injectCorrelationId(setCorrelationId).get<APIResponse<ReenrollmentStats>>(
          `${PRIVATE_DOMAIN}/contract/reenrollment-stats/?${paramsToQueryString(getParams)}`
        )
      ).data,
    manualSign: async (id: uuid) =>
      (
        await injectCorrelationId(setCorrelationId).patch<APIResponse<void>>(
          `${PRIVATE_DOMAIN}/contract/${id}/manual-sign`
        )
      ).data.data,
    recreateInvoices: async (id: uuid) =>
      (
        await injectCorrelationId(setCorrelationId).patch<APIResponse<Contract>>(
          `${PRIVATE_DOMAIN}/contract/${id}/recreate-invoices`
        )
      ).data.data,
    revoke: async (
      id: uuid,
      params: {
        cancellation_description: string
        cancellation_reason: ContractCancellationReason
        installment_id: uuid
      }
    ) =>
      (
        await injectCorrelationId(setCorrelationId).patch<APIResponse<void>>(
          `${PRIVATE_DOMAIN}/contract/${id}/revoke`,
          params
        )
      ).data.data,
    checkDuplication: async (getParams?: CheckDuplicationPayload) =>
      (
        await injectCorrelationId(setCorrelationId).get<APIResponse<CheckDuplicationResponse>>(
          `${PRIVATE_DOMAIN}/contract/check-duplicated?${paramsToQueryString(getParams)}`
        )
      ).data.data,
  },
  date: {
    getWorkingDueDates: async (params?: WorkingDueDatesParams) =>
      (
        await injectCorrelationId(setCorrelationId).post<APIResponse<datestring[]>>(
          `${PRIVATE_DOMAIN}/date/working-date`,
          params
        )
      )?.data,
  },
  guardians: {
    create: async (guardian: Omit<Guardian, 'created_at' | 'id'>) =>
      (
        await injectCorrelationId(setCorrelationId).post<APIResponse<Guardian>>(
          `${PRIVATE_DOMAIN}/guardian`,
          guardian
        )
      ).data.data,
    get: async (id: uuid) =>
      (
        await injectCorrelationId(setCorrelationId).get<APIResponse<Guardian>>(
          `${PRIVATE_DOMAIN}/guardian/${id}`
        )
      )?.data.data,
    getAddressFromZip: async (zip: cep) =>
      (
        await injectCorrelationId(setCorrelationId).get<APIResponse<Address>>(
          `${PRIVATE_DOMAIN}/guardian/address/${zip}`
        )
      )?.data.data,
    getContracts: async (id: uuid, reference_year: string) =>
      (
        await injectCorrelationId(setCorrelationId).get(
          `${PRIVATE_DOMAIN}/contract/list/${id}/${reference_year}`
        )
      )?.data.data,
    getList: async (schoolID: uuid, p?: Pagination & ListGuardiansFilters) =>
      (
        await injectCorrelationId(setCorrelationId).get<APIResponse<Guardian[]>>(
          `${PRIVATE_DOMAIN}/guardian/?${paramsToQueryString(p)}&school_id=${schoolID}`
        )
      ).data,
    getStudentSchoolMatches: async (id: uuid) =>
      (
        await injectCorrelationId(setCorrelationId).get<APIResponse<StudentSchoolMatch[]>>(
          `${PRIVATE_DOMAIN}/guardian/${id}/student-school-matches`
        )
      )?.data.data,
    update: async (
      id: uuid,
      guardian: Omit<Guardian, 'address' | 'address_id' | 'created_at' | 'id'>,
      schoolID: uuid
    ) =>
      (
        await injectCorrelationId(setCorrelationId).patch<APIResponse<Guardian>>(
          `${PRIVATE_DOMAIN}/guardian/${id}?school_id=${schoolID}`,
          guardian
        )
      )?.data.data,
  },
  installments: {
    getList: async () =>
      (
        await injectCorrelationId(setCorrelationId).get<APIResponse<Array<Installment>>>(
          `${PRIVATE_DOMAIN}/installment/`
        )
      ).data,
    get: async (id: uuid) =>
      (
        await injectCorrelationId(setCorrelationId).get<APIResponse<Installment>>(
          `${PRIVATE_DOMAIN}/installment/${id}`
        )
      )?.data.data,
  },
  invoices: {
    get: async (id: uuid, getParams?: GetInvoicesParams) =>
      (
        await injectCorrelationId(setCorrelationId).get<APIResponse<Invoice>>(
          `${PRIVATE_DOMAIN}/invoice/${id}/?${paramsToQueryString(getParams)}`
        )
      )?.data.data,
  },
  presigned: {
    uploadFile: async (url: string, data?: any, config?: AxiosRequestConfig) =>
      await injectCorrelationId(setCorrelationId).put(url, data, config),
  },
  products: {
    create: async (product: Omit<Product, 'created_at' | 'id'>) =>
      (
        await injectCorrelationId(setCorrelationId).post<APIResponse<Product>>(
          `${PRIVATE_DOMAIN}/product`,
          product
        )
      )?.data.data,
    delete: async (id: uuid) =>
      (
        await injectCorrelationId(setCorrelationId).delete<APIResponse<void>>(
          `${PRIVATE_DOMAIN}/product/${id}`
        )
      )?.data.data,
    get: async (id: uuid) =>
      (
        await injectCorrelationId(setCorrelationId).get<APIResponse<Product>>(
          `${PRIVATE_DOMAIN}/product/${id}`
        )
      )?.data.data,
    getList: async (p?: Pagination & ListProductsFilters) =>
      (
        await injectCorrelationId(setCorrelationId).get<APIResponse<Product[]>>(
          `${PRIVATE_DOMAIN}/product/?${paramsToQueryString(p)}`
        )
      ).data,
    update: async (product: EditedProduct, id: uuid) =>
      (
        await injectCorrelationId(setCorrelationId).patch<APIResponse<Product>>(
          `${PRIVATE_DOMAIN}/product/${id}`,
          product
        )
      )?.data.data,
  },
  receivables: {
    addDiscounts: async (data: AddDiscountsReqBody, schoolID: uuid) =>
      (
        await injectCorrelationId(setCorrelationId).post<APIResponse<Receivable[]>>(
          `${PRIVATE_DOMAIN}/receivable/add-discounts?school_id=${schoolID}`,
          data
        )
      )?.data.data,
    liquidationInfo: async (id: uuid, data: LiquidationGetParams) =>
      (
        await injectCorrelationId(setCorrelationId).get<APIResponse<LiquidationInfoResponse>>(
          `${PRIVATE_DOMAIN}/receivable/${id}/liquidation-info?${paramsToQueryString(data)}`
        )
      )?.data.data,
    manualLiquidation: async (id: uuid, data: LiquidationPostParams, schoolID: uuid) =>
      (
        await injectCorrelationId(setCorrelationId).post<APIResponse<Receivable[]>>(
          `${PRIVATE_DOMAIN}/receivable/${id}/manual-liquidation-with-discounts?school_id=${schoolID}`,
          data
        )
      )?.data.data,
    patchManualLiquidation: async (id: uuid, data: LiquidationPostParams, schoolID: uuid) =>
      (
        await injectCorrelationId(setCorrelationId).patch<APIResponse<Receivable[]>>(
          `${PRIVATE_DOMAIN}/receivable/${id}/manual-liquidation-with-discounts?school_id=${schoolID}`,
          data
        )
      )?.data.data,
    printReceipt: async (receivableId: uuid) =>
      await injectCorrelationId(setCorrelationId).get(
        `${PRIVATE_DOMAIN}/receivable/${receivableId}/print-receipt`,
        {
          responseType: 'blob',
        }
      ),
    printReceiptCheckout: async (receivableId: uuid) =>
      await injectCorrelationId(setCorrelationId).get(
        `${PRIVATE_DOMAIN}/receivable/${receivableId}/print-receipt-checkout`,
        {
          responseType: 'blob',
        }
      ),
    renegotiate: async (renegotiation: Renegotiation) =>
      (
        await injectCorrelationId(setCorrelationId).post<APIResponse<RenegotiationResponseBody>>(
          `${PRIVATE_DOMAIN}/receivable/renegotiate`,
          renegotiation
        )
      )?.data.data,
    // eslint-disable-next-line sort-keys
    agglutinate: async (agglutination: Agglutination) =>
      (
        await injectCorrelationId(setCorrelationId).post<APIResponse<Contract>>(
          `${PRIVATE_DOMAIN}/receivable/agglutinate`,
          agglutination
        )
      )?.data.data,
  },
  schools: {
    downloadReport: async (schoolID: uuid, reportType: Report) => {
      return await injectCorrelationId(setCorrelationId).get(
        `${PRIVATE_DOMAIN}/school/report?school_id=${schoolID}&report=${reportType}`,
        {
          responseType: 'blob',
        }
      )
    },
    get: async (id: uuid) =>
      (
        await injectCorrelationId(setCorrelationId).get<APIResponse<School>>(
          `${PRIVATE_DOMAIN}/school/${id}/`
        )
      )?.data.data,
    getBySlug: async (slug: string) =>
      (
        await injectCorrelationId(setCorrelationId).get<APIResponse<School>>(
          `${PRIVATE_DOMAIN}/school/slug/${slug}`
        )
      )?.data.data,
    getList: async (p?: Pagination) =>
      (
        await injectCorrelationId(setCorrelationId).get<APIResponse<School[]>>(
          `${PRIVATE_DOMAIN}/school/?${paramsToQueryString(p)}`
        )
      ).data,
    getPresignedUrl: async (body: RequestPresignedURL) =>
      (
        await injectCorrelationId(setCorrelationId).post<APIResponse<PresignedURL>>(
          `${PRIVATE_DOMAIN}/school/signed-url`,
          body
        )
      )?.data.data,
    processUploadedFile: async (schoolID: uuid, fileId: uuid, extension: string) =>
      (
        await injectCorrelationId(setCorrelationId).post<APIResponse<string>>(
          `${PRIVATE_DOMAIN}/school/process/${schoolID}`,
          {
            id: fileId,
            extension,
          }
        )
      ).data,
  },
  students: {
    getList: async (p?: Pagination & ListStudentsFilters) =>
      (
        await injectCorrelationId(setCorrelationId).get<APIResponse<Student[]>>(
          `${PRIVATE_DOMAIN}/student/?${paramsToQueryString(p)}`
        )
      ).data,
    get: async (id: uuid) =>
      (
        await injectCorrelationId(setCorrelationId).get<APIResponse<Student>>(
          `${PRIVATE_DOMAIN}/student/${id}`
        )
      )?.data.data,
    createOrUpdate: async (student: Partial<Student>) =>
      (
        await injectCorrelationId(setCorrelationId).post<APIResponse<Student>>(
          `${PRIVATE_DOMAIN}/student/`,
          student
        )
      )?.data.data,
    update: async (id: uuid, student: Partial<Student>, schoolID: uuid) =>
      (
        await injectCorrelationId(setCorrelationId).patch<APIResponse<Student>>(
          `${PRIVATE_DOMAIN}/student/${id}?school_id=${schoolID}`,
          student
        )
      )?.data.data,
  },
  report: {
    download: async (filename: string, extension: string) =>
      (
        await injectCorrelationId(setCorrelationId).get(
          `${PRIVATE_DOMAIN}/report/files/${filename}.${extension}`,
          {
            responseType: 'blob',
          }
        )
      )?.data,
    summary: async (slug: string) =>
      (
        await injectCorrelationId(setCorrelationId).get(
          `${PRIVATE_DOMAIN}/report/detailed-payouts/summarized/${slug}`
        )
      )?.data.data,
  },
  selfOnboarding: {
    getStatus: async () =>
      (
        await injectCorrelationId(setCorrelationId).get<APIResponse<SelfOnboarding>>(
          `${PRIVATE_DOMAIN}/school/onboarding-group-status`
        )
      )?.data.data,
  },
})

export default (config.API.MOCK
  ? () =>
      mapObjIndexed(
        r =>
          mapObjIndexed(
            m => async (...args) => {
              await wait(config.API.MOCK_DELAY)
              return (m as any)(...args)
            },
            r as any
          ),
        mockApiMethods
      ) as typeof mockApiMethods
  : api) as typeof api
