import { FC } from 'react'
import Chip from '@material-ui/core/Chip'
import styled from 'styled-components'
import { formatFilter } from './util'
import theme from 'src/shared/theme'
import { Box } from '@material-ui/core'
import { FilterSelection } from './types'

const StyledFilterChip = styled(Chip)`
  margin: ${theme.spacing(1)}px ${theme.spacing(1)}px;
  color: ${theme.primitiveTokens.colors.isaacBlue[60]};
  background: ${theme.primitiveTokens.colors.isaacBlue[10]};
  border: 0;
`

export type ListAppliedFilterChipProps = {
  appliedFilter: FilterSelection
  maxListCharacter: number
  removeFilter: (group: string, label: string | number, isChecked: boolean) => void
}

export const ListAppliedFilterChip: FC<ListAppliedFilterChipProps> = ({
  appliedFilter,
  removeFilter,
  maxListCharacter,
}) => {
  const formattedFilter = formatFilter(appliedFilter)
  const listedChecked = []
  Object.keys(formattedFilter).forEach(key =>
    formattedFilter[key].options.forEach(value => {
      if (value.checked) {
        listedChecked.push({ ...value, group: formattedFilter[key].group })
      }
    })
  )
  const listedCheckedFilterChip = (): Array<FC<ListAppliedFilterChipProps>> => {
    const list = []
    let countCharacter = 0
    let maxIndex = 0
    listedChecked.forEach(({ group, label }, i) => {
      countCharacter += label.length
      if (maxIndex === 0 && countCharacter > maxListCharacter) maxIndex = i
      if (maxIndex !== 0) {
        list[maxIndex] = (
          <StyledFilterChip
            key={group + '-' + label}
            label={'+' + (i - maxIndex + 1)}
            onDelete={() => removeFilter(group, label, false)}
            variant="outlined"
            color="primary"
            size="small"
          />
        )
      } else {
        list.push(
          <StyledFilterChip
            key={group + '-' + label}
            label={label}
            onDelete={() => removeFilter(group, label, false)}
            variant="outlined"
            color="primary"
            size="small"
          />
        )
      }
    })
    return list
  }

  return <Box sx={{ display: 'flex', alignItems: 'center' }}>{listedCheckedFilterChip()}</Box>
}
