import { FC, Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'

const StyledBox = styled(Box)`
  cursor: pointer;
`

export type ExpandButtonProps = {
  isExpanded: boolean
  setIsExpanded: Dispatch<SetStateAction<boolean>>
}

export const ExpandButton: FC<ExpandButtonProps> = ({ isExpanded, setIsExpanded }) => {
  const Icon = isExpanded ? ArrowDropUp : ArrowDropDown
  const text = isExpanded ? 'Mostrar menos' : 'Mostrar mais'
  const toggleExpand = () => {
    setIsExpanded(expand => !expand)
  }
  return (
    <StyledBox display="flex" alignItems="center" onClick={toggleExpand}>
      <Typography variant="caption" color="primary">
        {text}
      </Typography>
      <Icon color="primary" />
    </StyledBox>
  )
}

export default ExpandButton
