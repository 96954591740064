import React, { FC } from 'react'
import Menu from '@material-ui/core/Menu'
import { MenuItem } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import { PUBLIC_DOMAIN } from 'src/shared/api'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import CloseIcon from '@material-ui/icons/Close'
import { SystemButton } from '@olaisaac/design-system'
import Tooltip from '@material-ui/core/Tooltip'
import { useJWT } from 'src/shared/hooks'
import { useNavigation } from 'src/escolas/hooks'
import config from 'src/config'

import { EVENT_CATEGORIES, EVENT_GROUPS, REALMS } from 'src/shared/constants'
import SendEvent from 'src/shared/api/eventsClient'

export type ContractTableActionsProps = {
  canEditDiscounts: boolean
  canEditDueDay: () => boolean
  closeAgglutination: () => void
  contractId: uuid
  guardianId: uuid
  hasInstallmentsToChangeDueDay: boolean
  isAgglutination: boolean
  isProcessingAgglutination: boolean
  openAgglutination: () => void
  openDiscountsEdition: () => void
  openDueDayEdition: () => void
}
export const ContractTableActions: FC<ContractTableActionsProps> = ({
  contractId,
  guardianId: responsibleId,
  isAgglutination,
  closeAgglutination,
  openAgglutination,
  canEditDueDay,
  canEditDiscounts,
  openDiscountsEdition,
  openDueDayEdition,
  isProcessingAgglutination,
  hasInstallmentsToChangeDueDay,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { isAdmin, userId } = useJWT()
  const { schoolSlug } = useNavigation()

  const open = Boolean(anchorEl)
  const printContractURL = `${PUBLIC_DOMAIN}/contract/${contractId}/print-slips`
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (isAgglutination) {
      closeAgglutination()
    } else {
      setAnchorEl(event.currentTarget)
    }
  }
  const close = () => {
    setAnchorEl(null)
  }

  const sendPrintSlipEvent = () => {
    SendEvent({
      category: EVENT_CATEGORIES.INVOICE_CATEGORY,
      group: EVENT_GROUPS.SCHOOLS_GROUP,
      metadata: {
        contract_id: contractId,
        guardian_id: responsibleId,
        school_slug: schoolSlug,
      },
      realm: isAdmin ? REALMS.ADMIN_REALM : REALMS.SCHOOL_REALM,
      type: 'print-slip',
      user_id: userId,
    })
  }

  const getHandleClick = callback => () => {
    callback()
    close()
  }

  const maxDaysToChangeContractDueDay = Number(config.MAX_DAYS_TO_CHANGE_CONTRACT_DUE_DAY)

  const getTooltipContent = () => {
    if (!hasInstallmentsToChangeDueDay) {
      return 'Contratos sem parcelas com vencimento editável, não podem ter seu vencimento alterado.'
    }

    if (maxDaysToChangeContractDueDay > 0) {
      return `Contratos criados a mais de ${maxDaysToChangeContractDueDay} dias não podem ter seu vencimento alterado.`
    }

    if (maxDaysToChangeContractDueDay === 0) {
      return `Somente contratos criados hoje podem ter seu vencimento alterado.`
    }
  }

  const tooltipContent = getTooltipContent()

  return (
    <Box position="absolute" top={10} right={8}>
      <SystemButton
        aria-label="imprimir"
        aria-controls="menu-imprimir"
        aria-haspopup="true"
        onClick={handleClick}
        disabled={isProcessingAgglutination}
      >
        {isAgglutination ? <CloseIcon /> : <MoreVertIcon />}
      </SystemButton>
      <Menu id="menu-imprimir" anchorEl={anchorEl} keepMounted open={open} onClose={close}>
        <MenuItem
          onClick={getHandleClick(sendPrintSlipEvent)}
          component="a"
          href={printContractURL}
          target="blank"
        >
          Imprimir parcelas abertas
        </MenuItem>
        {isAdmin && config.REACT_APP_AGGLUTINATION_ENABLED === 'true' ? (
          <MenuItem onClick={getHandleClick(openAgglutination)} component="a" target="blank">
            Aglutinar parcelas abertas
          </MenuItem>
        ) : null}
        {canEditDueDay() ? (
          <MenuItem onClick={getHandleClick(openDueDayEdition)} component="a" target="blank">
            Editar vencimentos
          </MenuItem>
        ) : (
          <Tooltip title={tooltipContent}>
            <Box>
              <MenuItem
                disabled
                onClick={getHandleClick(openDueDayEdition)}
                component="a"
                target="blank"
              >
                Editar vencimentos
              </MenuItem>
            </Box>
          </Tooltip>
        )}

        <MenuItem
          disabled={!canEditDiscounts}
          onClick={getHandleClick(openDiscountsEdition)}
          component="a"
          target="blank"
        >
          Editar descontos de mensalidades
        </MenuItem>
      </Menu>
    </Box>
  )
}
export default ContractTableActions
