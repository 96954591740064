import envConfig from 'src/config'

export enum FeatureFlags {
  ADD_DISCOUNTS_FEATURE_FLAG = 'ADD_DISCOUNTS_FEATURE_FLAG',
  EDIT_GUARDIAN_PILOT_SCHOOLS_FEATURE_FLAG = 'EDIT_GUARDIAN_PILOT_SCHOOLS_FEATURE_FLAG',
  MANUAL_LIQUIDATION_PILOT_SCHOOLS_FEATURE_FLAG = 'MANUAL_LIQUIDATION_PILOT_SCHOOLS_FEATURE_FLAG',
  PILOT_SCHOOLS_FEATURE_FLAG = 'PILOT_SCHOOLS_FEATURE_FLAG',
}

export const useFeatureFlag = (feature: FeatureFlags) => {
  if (!envConfig[feature]) {
    return {
      value: false,
      config: [''],
    }
  }

  const values: string[] = envConfig[feature].split(',')

  return {
    value: values[0] === 'ON',
    config: values.slice(1),
  }
}
