import { identity } from 'ramda'
import { useContext } from 'react'
import { Breadcrumbs } from 'src/escolas/contexts/breadcrumbsContext'

const useBreadcrumbs = () => {
  const { breadcrumbs, setBreadcrumbs } = useContext(Breadcrumbs) ?? {
    breadcrumbs: [],
    setBreadcrumbs: identity,
  }
  return { breadcrumbs, setBreadcrumbs }
}

export default useBreadcrumbs
