import { useState, useRef } from 'react'
import MuiPopover from '@material-ui/core/Popover'
import { IconButton } from '@olaisaac/design-system'
import { useNavigation } from 'src/escolas/hooks'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { AVAILABLE_CONTRACT_YEARS } from 'src/escolas/components/contract/constants'
import styled from 'styled-components'

const IconButtonWrapper = styled.div`
  margin-left: 16px;
`

const StyledList = styled(props => <List {...props} />)`
  min-width: 225px;
`

const StyledListItem = styled(props => <ListItem {...props} />)`
  min-height: 48px;
  font-size: 1rem;
`

export function ContractYearPopover() {
  const anchorEl = useRef<HTMLButtonElement | null>(null)
  const [open, setOpen] = useState<boolean>(false)
  const { getNavigationUrl } = useNavigation()

  const handlePopoverClose = () => {
    setOpen(false)
  }

  const handlePopoverClick = () => {
    setOpen(true)
  }

  return (
    <>
      <IconButtonWrapper>
        <IconButton ref={anchorEl} onClick={handlePopoverClick} variation="ghost">
          <ExpandMoreIcon />
        </IconButton>
      </IconButtonWrapper>

      <MuiPopover
        open={open}
        anchorEl={anchorEl.current}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <StyledList component="nav">
          {AVAILABLE_CONTRACT_YEARS.map(year => {
            const path = getNavigationUrl({ path: `/contratos/${year}` })
            return (
              <StyledListItem key={year} button component="a" href={path}>
                {`Contratos ${year}`}
              </StyledListItem>
            )
          })}
        </StyledList>
      </MuiPopover>
    </>
  )
}

export default ContractYearPopover
