import React from 'react'
import MuiTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import MuiTableRow from '@material-ui/core/TableRow'
import TablePagination from 'src/escolas/components/Table/TablePagination'
import Paper from '@material-ui/core/Paper'
import TableColumnCell, { TableColumns } from 'src/escolas/components/Table/TableColumnCell'
import TableRow, { TableRowProps } from 'src/escolas/components/Table/TableRow'
import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@material-ui/core/Box'
import { CombinableReceivable } from 'src/escolas/contexts/receivablesAgglutinationContext'
import { NoResults, TableFilterParam } from 'src/escolas/components/TableFilter'

export type TableProps<T extends Record<string, any> = Record<string, any>> = {
  columns: TableColumns
  count?: number
  filters?: Record<string, TableFilterParam<any>>
  handleChangePage?: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void
  handleChangeRowsPerPage?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
  HeaderActionComponent?: any
  isLoading?: boolean
  onRowClick?: TableRowProps<T>['onRowClick']
  orderBy?: string
  page?: number
  paginated?: boolean
  RowActionComponent?: any
  rowDisabled?: (params: TableRow<T>) => boolean
  rows: TableRow<T>[]
  rowsPerPage?: number
  rowsSelected?: Array<string | CombinableReceivable>
  rowTextDisabled?: (params: TableRow<T>) => boolean
  secondaryText?: string
  sorted?: boolean
  sortingHandler?: (field: string) => void
}

function Table<T extends Record<string, any> = Record<string, any>>({
  columns,
  filters,
  onRowClick,
  rows,
  rowsSelected,
  paginated,
  sorted,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  rowsPerPage,
  count,
  sortingHandler,
  orderBy,
  isLoading,
  RowActionComponent,
  HeaderActionComponent,
  rowDisabled,
  secondaryText,
  rowTextDisabled,
}: TableProps<T>) {
  const sortedRows = orderBy ? [...rows].sort((a, b) => a[orderBy].localeCompare(b[orderBy])) : rows

  const useRowAction = typeof RowActionComponent === 'function'

  return (
    <>
      {isLoading ? (
        <Box
          top={300}
          left={100}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      ) : !rows?.length && filters?.name.value ? (
        <NoResults filterParam={filters.name.value} secondaryText={secondaryText} />
      ) : (
        <TableContainer component={Paper}>
          <MuiTable aria-label="tabela" data-testid="installment-table">
            <TableHead>
              <MuiTableRow>
                {columns?.map((column, index) => {
                  return (
                    <TableColumnCell
                      key={`${column?.field}${index}`}
                      column={column}
                      direction={orderBy === column.field ? 'asc' : 'desc'}
                      orderBy={orderBy}
                      sorted={sorted}
                      sortingHandler={sortingHandler}
                      ActionComponent={HeaderActionComponent}
                    />
                  )
                })}
              </MuiTableRow>
            </TableHead>
            <TableBody>
              {sortedRows?.map(row => {
                return (
                  <TableRow<T>
                    key={row.key}
                    row={row}
                    columns={columns}
                    onRowClick={onRowClick}
                    rowsSelected={rowsSelected}
                    ActionComponent={item => useRowAction && RowActionComponent(item)}
                    disabled={rowDisabled && rowDisabled(row)}
                    rowTextDisabled={rowTextDisabled}
                  />
                )
              })}
            </TableBody>
            {paginated && (
              <TablePagination
                count={count}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPage={rowsPerPage}
              />
            )}
          </MuiTable>
        </TableContainer>
      )}
    </>
  )
}

export default Table
