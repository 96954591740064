import { CommonMetadata } from './metadata'
import { PaymentMethod } from './paymentMethod'
import { Receivable } from './receivable'
import { School } from './school'

export enum CheckoutType {
  STONE_CHECKOUT = 'stone_checkout',
}

export enum CheckoutStatus {
  ABORTED = 'aborted',
  FINISHED = 'finished',
  ON_HOLD = 'on_hold',
  PROCESSING = 'processing',
}

export type Checkout = CommonMetadata & {
  amount: number
  external_transaction_id: uuid
  installments_count: number
  keep_due_payment_discounts: boolean
  keep_early_payment_discounts: boolean
  keep_fine_and_interest: boolean
  payment_method: PaymentMethod
  receivables?: Receivable[]
  school?: School
  school_id: uuid
  status: CheckoutStatus
  type: CheckoutType
}

export type GetCheckoutParams = {
  include_receivables: boolean
  include_school: boolean
}

export type StartCheckoutRequestBody = {
  information_title: string
  installments_count: number
  keep_due_payment_discounts: boolean
  keep_early_payment_discounts: boolean
  keep_fine_and_interest: boolean
  payment_method: PaymentMethod
  receivables_ids: uuid[]
}
