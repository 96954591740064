import { Checkout, CheckoutStatus, CheckoutType, PaymentMethod } from 'src/shared/interfaces'

export const mockCheckout: Checkout = {
  created_at: '2021-01-27T17:29:18.701Z',
  updated_at: '2021-01-27T17:29:18.701Z',
  id: '259261f8-a056-4789-921d-a3270114aeba' as uuid,
  amount: 100,
  external_transaction_id: '12dc3398-d7d2-4c63-8ad8-772be07a0bbf' as uuid,
  installments_count: 3,
  keep_due_payment_discounts: true,
  keep_early_payment_discounts: true,
  keep_fine_and_interest: true,
  payment_method: PaymentMethod.CREDIT_CARD_PAYMENT_METHOD,
  school_id: 'bbc1aa59-4894-41c5-895d-e2f13b61ed72' as uuid,
  status: CheckoutStatus.ON_HOLD,
  type: CheckoutType.STONE_CHECKOUT,
}

export const mockCheckoutProcess: Checkout = {
  ...mockCheckout,
  status: CheckoutStatus.PROCESSING,
}

export const mockCheckoutFinish: Checkout = {
  ...mockCheckout,
  status: CheckoutStatus.FINISHED,
}

export const mockCheckoutAbort: Checkout = {
  ...mockCheckout,
  status: CheckoutStatus.ABORTED,
}
