import { useNavigation } from 'src/escolas/hooks'
import SendEvent, { SendFullStoryEvent } from 'src/shared/api/eventsClient'
import { EVENT_CATEGORIES, EVENT_GROUPS, REALMS } from 'src/shared/constants'
import { useJWT } from 'src/shared/hooks'

export type StatementEventMetadataProps = {
  contract_id: string
  product_id: string
}

export enum StatementEventType {
  statementOfPaidAmount = 'statement-of-paid-amount',
}

const getSendStatementEvent = (type: string) => {
  const { isAdmin, userId } = useJWT()
  const { schoolId, schoolSlug } = useNavigation()

  return (metadata?: StatementEventMetadataProps) => {
    const completeMetadata = {
      school_slug: schoolSlug,
      school_id: schoolId,
      ...metadata,
    }
    SendEvent({
      category: EVENT_CATEGORIES.STATEMENT_CATEGORY,
      group: EVENT_GROUPS.SCHOOLS_GROUP,
      metadata: completeMetadata,
      realm: isAdmin ? REALMS.ADMIN_REALM : REALMS.SCHOOL_REALM,
      type,
      user_id: userId,
    })

    SendFullStoryEvent(completeMetadata, type)
  }
}
export const useStatementEventHandler = () => {
  return getSendStatementEvent(StatementEventType.statementOfPaidAmount)
}
