import { FC } from 'react'

type TableActionProps = {
  Component?: (props?: any) => JSX.Element
  show: () => boolean
}

export const TableAction: FC<TableActionProps> = ({ Component, show }) => {
  return <>{show() ? <Component /> : null}</>
}
