import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { Button, IconButton } from '@olaisaac/design-system'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import useTheme from '@material-ui/core/styles/useTheme'
import { useApi, useJWT } from 'src/shared/hooks'
import { Receivable, Discount } from 'src/shared/interfaces'
import { prop, propEq, reject, map, dissoc, drop, times } from 'ramda'
import DrawerForm from 'src/escolas/components/contract/InstallmentDrawer/DrawerForm'
import DiscountFormFields, { discountsOptions } from 'src/escolas/components/DiscountFormFields'
import {
  formatCentsToReal,
  leftZeroPadding,
  formatNumberToTwoFractionDigits,
} from 'src/shared/utils'
import { useNavigation } from 'src/escolas/hooks'
import { FeatureFlags, useFeatureFlag } from 'src/shared/hooks/useFeatureFlag'

const StyledTypography = styled(Typography)`
  font-weight: 700;
`

const StyledButton = styled(Button)`
  text-transform: unset;
  font-weight: 700;
  align-self: flex-end;
`

const StyledPaper = styled(Paper)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  padding: ${({ theme }) => theme.spacing(2)}px;
  position: relative;
  display: flex;
  flex-direction: column;
`

export type AddDiscountFormType = Discount & {
  isFinished: boolean
}

export type AddDiscountsFormType = {
  discounts: AddDiscountFormType[]
  reason: string
}

export type AddDiscountContentProps = {
  onFinish: () => void
  receivables: Array<Receivable>
  selectedReceivableId: uuid
  setSelectedReceivableId: Dispatch<SetStateAction<uuid>>
  successCallback: (receivables: Array<Receivable>) => void
}

const AddDiscountContent: FC<AddDiscountContentProps> = ({
  onFinish,
  receivables,
  selectedReceivableId,
  setSelectedReceivableId,
  successCallback,
}) => {
  const { api } = useApi()
  const form = useForm<AddDiscountsFormType>({
    mode: 'onChange',
  })
  const { control, watch, trigger } = form
  const { fields: discountFields, append, remove } = useFieldArray({
    control,
    name: 'discounts',
  })
  const theme = useTheme()
  const { schoolId } = useNavigation()
  const { isAdmin } = useJWT()

  const { value: featureFlagValue, config } = useFeatureFlag(
    FeatureFlags.PILOT_SCHOOLS_FEATURE_FLAG
  )
  const isFeatureFlagEnabled = !isAdmin && featureFlagValue && config.includes(schoolId)

  const [discountsToAppendQueue, setDiscountsToAppendQueue] = useState<Discount[]>([])
  const [newReceivableId, setNewReceivableId] = useState<uuid>()

  const receivable = receivables?.find(propEq('id', selectedReceivableId))

  const submitHandler = async (form: AddDiscountsFormType) => {
    const { discounts, reason } = form

    return await api.receivables
      .addDiscounts(
        {
          discounts: map<AddDiscountFormType, Discount>(dissoc('isFinished'), discounts),
          reason,
          receivable_id: selectedReceivableId,
        },
        schoolId
      )
      .then(updatedReceivables => {
        successCallback([
          ...reject(propEq('id', selectedReceivableId), receivables),
          ...updatedReceivables,
        ])
        const newReceivableId = updatedReceivables.find(({ id }) => id !== selectedReceivableId)?.id
        setNewReceivableId(newReceivableId)
      })
  }

  const onSuccessSetReceivableId = () => {
    onFinish()
    // This unmounts the form and should be run only after the success message appears
    setSelectedReceivableId(newReceivableId)
  }

  const appendDiscount = (discount: Discount) => {
    append({
      amount: discount?.amount,
      days_before_due_date: discount?.days_before_due_date,
      description: discount?.description,
      isFinished: false,
    })
  }

  const appendNewDiscount = () => {
    appendDiscount({
      amount: null,
      days_before_due_date: null,
      description: '',
    })
  }

  useEffect(() => {
    const discounts = receivable?.discounts
    if (!discounts?.length) {
      appendNewDiscount()
      return
    }
    setDiscountsToAppendQueue(discounts)
  }, [receivable?.discounts])

  useEffect(() => {
    if (!discountsToAppendQueue.length) {
      times(index => trigger(`discounts.${index}.amount`), discountFields.length)
      return
    }
    appendDiscount(discountsToAppendQueue[0])
    setDiscountsToAppendQueue(drop(1, discountsToAppendQueue))
  }, [discountsToAppendQueue])

  return (
    <>
      <DrawerForm
        form={form}
        errorTitle="Volte e adicione novamente"
        errorMessage="Houve uma falha inesperada e não conseguimos adicionar o desconto."
        successMessage="O desconto foi adicionado com sucesso."
        onFinish={onSuccessSetReceivableId}
        onSubmit={submitHandler}
      >
        <Box mt={2} mb={2}>
          <Typography display="inline" variant="body2" color="textSecondary">
            Você pode adicionar
          </Typography>
          <StyledTypography display="inline" variant="body2">
            {' '}
            até 3 descontos{' '}
          </StyledTypography>
          <Typography display="inline" variant="body2" color="textSecondary">
            nesta parcela.
          </Typography>
        </Box>
        {discountFields.map((data, index) => {
          const [amount, days_before_due_date, isFinished] = watch([
            `discounts.${index}.amount` as const,
            `discounts.${index}.days_before_due_date` as const,
            `discounts.${index}.isFinished` as const,
          ])
          const daysBeforeDueDateOptionDescription = discountsOptions.find(
            propEq('value', days_before_due_date)
          )?.name
          const isValidAmount = amount && amount <= receivable?.original_amount && amount > 0
          const isValidDaysBeforeDueDate = discountsOptions
            .map(prop('value'))
            .includes(days_before_due_date)
          const isFinishButtonDisabled = !isValidAmount || !isValidDaysBeforeDueDate
          const percentage = formatNumberToTwoFractionDigits(
            (amount / receivable?.original_amount) * 100
          )
          return (
            <StyledPaper
              key={data.id}
              variant="outlined"
              theme={theme}
              data-testid="add-discount-content"
            >
              <Box mb={2}>
                <Typography variant="caption" color="textSecondary">
                  Desconto nº {leftZeroPadding(index + 1, 2)}
                </Typography>
                <Box position="absolute" top={14} right={16}>
                  <IconButton
                    variation="ghost"
                    size="small"
                    aria-label="remover desconto"
                    onClick={() => remove(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
              {isFinished && (
                <>
                  <Box mb={1}>
                    <Typography display="inline" color="textSecondary">
                      Valor:{' '}
                    </Typography>
                    <StyledTypography display="inline">
                      {formatCentsToReal(amount)}
                    </StyledTypography>
                    <Typography display="inline" color="textSecondary">
                      {' '}
                      — %
                    </Typography>
                    <StyledTypography display="inline">{percentage}</StyledTypography>
                  </Box>
                  <Box mb={2}>
                    {days_before_due_date > 0 && (
                      <Typography display="inline" color="textSecondary">
                        Válido até{' '}
                      </Typography>
                    )}
                    <Typography display="inline" color="textSecondary">
                      {daysBeforeDueDateOptionDescription}
                    </Typography>
                  </Box>
                </>
              )}
              <Box display={isFinished ? 'none' : 'block'}>
                <DiscountFormFields
                  form={form}
                  amountDefaultValue={data.amount}
                  amountFieldName={`discounts.${index}.amount`}
                  daysBeforeDueDateDefaultValue={data.days_before_due_date}
                  daysBeforeDueDateFieldName={`discounts.${index}.days_before_due_date`}
                  descriptionDefaultValue={data.description}
                  descriptionFieldName={`discounts.${index}.description`}
                  totalAmountPaid={receivable?.original_amount}
                />
              </Box>
              <Controller
                rules={{ required: true, validate: Boolean }}
                control={control}
                name={`discounts.${index}.isFinished` as const}
                render={({ field: { onChange, value } }) => (
                  <>
                    <Box
                      position="absolute"
                      top={14}
                      right={56}
                      display={isFinished ? 'block' : 'none'}
                    >
                      <IconButton
                        variation="ghost"
                        size="small"
                        aria-label="editar desconto"
                        onClick={() => onChange(!value)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Box>
                    <StyledButton
                      style={{ display: isFinished ? 'none' : 'block' }}
                      variation="ghost"
                      fullWidth
                      onClick={() => onChange(!value)}
                      disabled={isFinishButtonDisabled}
                    >
                      Salvar desconto
                    </StyledButton>
                  </>
                )}
              />
            </StyledPaper>
          )
        })}
        {isFeatureFlagEnabled ? (
          <Box mt={2} mb={2}>
            <Box mt={2} mb={2}>
              <Typography display="inline" variant="body2" color="textSecondary">
                Qual é o motivo da edição de desconto?
              </Typography>
            </Box>
            <FormControl fullWidth variant="outlined">
              <Controller
                control={control}
                name="reason"
                defaultValue=""
                render={({ field }) => <TextField id="reason" variant="outlined" {...field} />}
              />
            </FormControl>
          </Box>
        ) : null}
        <Button
          variation="ghost"
          startIcon={<AddIcon />}
          fullWidth
          onClick={appendNewDiscount}
          disabled={discountFields.length >= 3}
        >
          Novo desconto
        </Button>
      </DrawerForm>
    </>
  )
}

export default AddDiscountContent
