import { useEffect } from 'react'
import Container from '@material-ui/core/Container'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

import View from 'src/responsaveis/components/View'
import sortingHatClient, { clearCookies, loginURL } from 'src/shared/api/sortingHatClient'

export default () => {
  useEffect(() => {
    clearCookies()
    sortingHatClient.services.token.IsAuthenticated()
  }, [])

  return (
    <View>
      <Container maxWidth="md">
        <Container maxWidth="md">
          <Typography variant="h6">
            Aguarde. Você será redirecionado para a página de login
          </Typography>
          <Typography>
            Se você não foi redirecionado,{' '}
            <Link
              href={loginURL}
              title="Ir para a página de login"
              color="inherit"
              underline="always"
            >
              clique aqui
            </Link>
          </Typography>
        </Container>
      </Container>
    </View>
  )
}
