import React, { FC } from 'react'
import styled from 'styled-components'
import { Button, DialogTitle, DialogContent, DialogActions } from '@olaisaac/design-system'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import booksAndApple from 'src/escolas/assets/booksAndApple.png'
import meditating from 'src/escolas/assets/meditating.png'
import { useApi, useJWT } from 'src/shared/hooks'
import { FeatureFlags, useFeatureFlag } from 'src/shared/hooks/useFeatureFlag'
import { useNavigation } from 'src/escolas/hooks'

const StyledImage = styled.img`
  text-align: center;
  width: 50%;
  padding: ${({ theme }) => theme.spacing(4)}px 0px;
`

const StyledPaddingBox = styled(Box)`
  padding-top: 20px;
`

const TextAndImageContent: FC<{
  imageSrc: string
  message: string
  secondaryMessage?: React.ReactNode
}> = ({ imageSrc, message, secondaryMessage }) => (
  <>
    <Box display="flex" justifyContent="center" textAlign="center">
      <Typography variant="body2">{message}</Typography>
    </Box>
    {secondaryMessage ? (
      <StyledPaddingBox display="flex" justifyContent="center" textAlign="center">
        {secondaryMessage}
      </StyledPaddingBox>
    ) : null}
    <Box display="flex" justifyContent="center">
      <StyledImage src={imageSrc} alt="" />
    </Box>
  </>
)

const getDefaultErrorMessage = () => {
  const { isAdmin } = useJWT()
  const { schoolId } = useNavigation()

  const { value: featureFlagValue, config } = useFeatureFlag(
    FeatureFlags.PILOT_SCHOOLS_FEATURE_FLAG
  )

  const isFeatureFlagEnabled = !isAdmin && featureFlagValue && config.includes(schoolId)

  return isFeatureFlagEnabled
    ? 'Por favor, tente novamente ou entre em contato com seu gerente no isaac.'
    : 'Por favor, tente novamente ou entre em contato com a equipe responsável.'
}

export const FailureFeedbackContent: FC<{ message?: string }> = ({
  message = getDefaultErrorMessage(),
}) => {
  const { correlationId } = useApi()
  const secondaryMessage = correlationId ? (
    <Typography variant="body2">
      Se o erro persistir, informe este código ao suporte:{' '}
      <strong>#{correlationId.split('-')[0]}</strong>
    </Typography>
  ) : null
  return (
    <TextAndImageContent
      message={message}
      imageSrc={booksAndApple}
      secondaryMessage={secondaryMessage}
    />
  )
}

export type ConfirmationDialogProps = {
  buttonLabel?: string
  centered?: boolean
  isConfirmDisabled?: () => boolean
  isVisible: boolean
  onClose: () => void
  submitHandler: () => void
  title?: string
}

const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  buttonLabel = 'Ok, entendi!',
  centered,
  children,
  isVisible,
  onClose,
  submitHandler,
  title,
  isConfirmDisabled,
}) => (
  <Dialog data-testid="feedback-dialog" open={isVisible} onClose={onClose} maxWidth="xs" fullWidth>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>{children}</DialogContent>
    <DialogActions className={`${centered ? 'centered' : ''}`}>
      <Button variation="primary" onClick={submitHandler} disabled={isConfirmDisabled?.()}>
        {buttonLabel}
      </Button>
    </DialogActions>
  </Dialog>
)

export default ConfirmationDialog

export const failureFeedbackTitle = 'Aconteceu um erro inesperado'

export const FailureFeedbackDialog: FC<ConfirmationDialogProps> = ({
  title = failureFeedbackTitle,
  ...rest
}) => (
  <ConfirmationDialog title={title} {...rest}>
    {rest.children ?? <FailureFeedbackContent />}
  </ConfirmationDialog>
)

export const SuccessFeedbackContent: FC<{ message?: string }> = ({
  message = 'Ação realizada com sucesso.',
}) => <TextAndImageContent message={message} imageSrc={meditating} />

export const successFeedbackTitle = 'Sucesso!'

export const SuccessFeedbackDialog: FC<ConfirmationDialogProps> = ({
  title = successFeedbackTitle,
  ...rest
}) => (
  <ConfirmationDialog title={title} {...rest}>
    {rest.children ?? <SuccessFeedbackContent />}
  </ConfirmationDialog>
)
