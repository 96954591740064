import { createContext, FC, useState } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Dashboard from 'src/responsaveis/router/dashboard'
import Contracts from 'src/responsaveis/router/contracts'
import Receivables from 'src/responsaveis/router/receivables'
import { Guardian } from 'src/shared/interfaces'
import { useLogin } from 'src/responsaveis/hooks'

export type ApplicationState = {
  contractId?: string
  guardian?: Guardian
  studentId?: string
  studentName?: string
}

export const ApplicationContext = createContext(null)

const Routes: FC = () => {
  const [appState, setAppState] = useState<ApplicationState>({})
  const Login = useLogin()

  const Context = {
    context: [appState, setAppState],
  }

  return (
    <ApplicationContext.Provider value={Context}>
      <Route path="/dashboard/:guardianId">
        <Dashboard />
      </Route>
      <Route path="/contratos/:guardianId/:schoolId/:studentId">
        <Contracts />
      </Route>
      <Route path="/recebiveis/:guardianId/:schoolId/:studentId/:contractId">
        <Receivables />
      </Route>
      <Route exact path={['/', '/login']}>
        <Login />
      </Route>
    </ApplicationContext.Provider>
  )
}

export default () => (
  <Router>
    <Routes />
  </Router>
)
