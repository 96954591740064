import { FC, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { PropTypes, Box, Hidden } from '@material-ui/core'
import { ApplicationContext } from 'src/responsaveis/router/router'
import { GuardianContractOverviewStatus } from 'src/shared/interfaces'

const useStyles = makeStyles(() =>
  createStyles({
    colorPrimary: {
      backgroundColor: '#E9EAFB',
      color: '#3D4ED7',
    },
    colorSecondary: {
      backgroundColor: '#FFECED',
      color: '#FF4621',
    },
  })
)

const StyledPaper = styled(Paper)`
  margin: 16px 0 16px 0;
  width: 100%;

  padding: 24px 0 30px 22px;
`

const StyledChip = styled(Chip)`
  margin: 24px 0 0 0;
  position: relative;
`

const StyledChevronRightIcon = styled(ChevronRightIcon)`
  color: #3d4ed7;
  margin: 28px 0 0 0;
`

export type ContractCardProps = {
  href?: string
  schoolName: string
  status: GuardianContractOverviewStatus
  studentName: string
}

type ChipProp = {
  color: Exclude<PropTypes.Color, 'inherit'>
  message: string
}

const ContractCard: FC<ContractCardProps> = ({ href, schoolName, status, studentName }) => {
  const history = useHistory()
  const classes = useStyles()

  const {
    context: [appState, setAppState],
  } = useContext(ApplicationContext)

  const ChipProp: ChipProp = {
    color: 'default',
    message: '',
  }

  switch (status) {
    case GuardianContractOverviewStatus.ALL_PAID:
      ChipProp.color = 'primary'
      ChipProp.message = 'Tudo pago'
      break

    case GuardianContractOverviewStatus.NONE_PENDING:
      ChipProp.color = 'primary'
      ChipProp.message = 'Em dia'
      break

    case GuardianContractOverviewStatus.WAITING_PAYMENT:
      ChipProp.color = 'secondary'
      ChipProp.message = 'Aguardando pagamento'
      break

    case GuardianContractOverviewStatus.CANCELED:
      ChipProp.color = 'default'
      ChipProp.message = 'Cancelado'
      break

    default:
      break
  }

  const hrefAnchorClick = (link: string) => {
    return () => {
      const ids = link.split('/')

      setAppState({
        ...appState,
        schoolId: ids[2],
        studentId: ids[3],
        studentName: studentName,
      })

      history.push(link)
    }
  }

  const GuardianContractStatus = () => {
    return (
      <StyledChip
        color={ChipProp.color}
        label={ChipProp.message}
        classes={{
          colorPrimary: classes.colorPrimary,
          colorSecondary: classes.colorSecondary,
        }}
      />
    )
  }

  return (
    <StyledPaper onClick={hrefAnchorClick(href)} square>
      <Box display="flex" flexDirection="row">
        <Box flexGrow={90}>
          <Typography variant="caption" color="textSecondary">
            {schoolName}
          </Typography>
          <Typography variant="h6">{studentName}</Typography>

          <Hidden mdUp>
            <Box pb="8px">
              <GuardianContractStatus />
            </Box>
          </Hidden>
        </Box>
        <Box flexDirection="column" display="flex" flexGrow={10} alignItems="flex-end">
          <Box flexGrow={90} mr="30px" mt="-20px" mb="20px">
            <Hidden smDown>
              <GuardianContractStatus />
            </Hidden>
          </Box>

          <Box flexGrow={10} display="flex" alignSelf="auto" mr="18px" flexDirection="row">
            <Hidden smDown>
              <Box flexGrow={90} alignSelf="flex-end" pb="8px">
                <Typography color="primary" variant="body2">
                  Ver mais
                </Typography>
              </Box>
            </Hidden>
            <Box flexGrow={10} alignSelf="flex-end">
              <StyledChevronRightIcon />
            </Box>
          </Box>
        </Box>
      </Box>
    </StyledPaper>
  )
}

export default ContractCard
