import { Dayjs } from 'dayjs'
import { months } from 'src/shared/utils'
import { StyledButton } from './ReceivableAccordion'
import Hidden from '@material-ui/core/Hidden'
import Box from '@material-ui/core/Box'
import { useContext, FC } from 'react'
import { ApplicationContext } from 'src/responsaveis/router/router'

const buildWppMessage = (respName, cpf, student, productName, dueDate: Dayjs) =>
  `Olá, meu nome é “${respName}”, CPF: ${cpf}. 
Sou responsável pelo aluno “${student}” e estou entrando em contato para falar sobre a parcela de “${
    months[dueDate.month()]
  }” do “${productName}” com vencimento no dia “${dueDate.format('DD/MM/YYYY')}”`

const buildContactURL = (respName, cpf, student, productName, dueDate: Dayjs) =>
  'https://wa.me/+5511972851717?text=' +
  buildWppMessage(respName, cpf, student, productName, dueDate)

type ContactButton = {
  dueDate: Dayjs
  productName: string
  props: Record<string, any>
}

const ContactButton: FC<ContactButton> = ({ props, productName, dueDate }) => {
  const {
    context: [appState],
  } = useContext(ApplicationContext)
  return (
    <Box alignSelf="flex-end">
      <StyledButton
        variation="primary"
        href={buildContactURL(
          appState?.guardian?.name,
          appState?.guardian?.tax_id,
          appState?.studentName,
          productName,
          dueDate
        )}
        {...props}
      >
        Entrar em contato
      </StyledButton>
    </Box>
  )
}

type ExpiredInvoiceMenu = {
  dueDate: Dayjs
  productName: string
}

const ExpiredInvoiceMenu: FC<ExpiredInvoiceMenu> = ({ productName, dueDate }) => {
  return (
    <>
      <Hidden mdUp>
        <ContactButton dueDate={dueDate} productName={productName} props={{ fullWidth: true }} />
      </Hidden>
      <Hidden smDown>
        <Box display="flex" alignItems="flex-end" flexDirection="column">
          <ContactButton dueDate={dueDate} productName={productName} props={{ fullWidth: true }} />
        </Box>
      </Hidden>
    </>
  )
}

export default ExpiredInvoiceMenu
