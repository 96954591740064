import { FC } from 'react'
import styled from 'styled-components'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { formatCentsToReal } from 'src/shared/utils'
import { ContractInstallments } from 'src/escolas/components/contract/ContractDetailsDrawer/ContractDetailsDrawerDefaultContent'

export type ContractDetailsSummaryProps = {
  contractInstallments: ContractInstallments
}

const Discount = styled(Typography)`
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  & span {
    font-size: 15px;
  }
  font-weight: normal;
`

const StyledTypography = styled(Typography)`
  font-weight: 400;
`

const ContractDetailsSummary: FC<ContractDetailsSummaryProps> = ({ contractInstallments }) => {
  const { annuity, enrollment, tuition } = contractInstallments

  return (
    <Box mt={2} mb={4}>
      {enrollment.installments_quantity > 0 && (
        <Box mb={3}>
          <Typography variant="subtitle1">Matrícula</Typography>
          <Box display="flex" flexDirection="row" justifyContent="space-between" mt={1.5}>
            <Typography variant="body1" color="textSecondary">
              Total
            </Typography>
            <Typography variant="body1" color="textSecondary" data-testid="enrollment-total-amount">
              {formatCentsToReal(enrollment.totalAmount)}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="space-between" my={1}>
            <Discount variant="body1" color="textSecondary">
              Descontos
            </Discount>
            <Discount
              variant="body1"
              data-testid="enrollment-discounts-total-amount"
              color="textSecondary"
            >
              {`- ${formatCentsToReal(enrollment.discounts)}`}
            </Discount>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <StyledTypography variant="body1">Parcelas</StyledTypography>
            <StyledTypography variant="body1" data-testid="enrollment-installments">{`${
              enrollment.installments_quantity
            }x de ${formatCentsToReal(
              enrollment.installment - enrollment.discounts
            )}`}</StyledTypography>
          </Box>
        </Box>
      )}
      <Box mb={3}>
        <Typography variant="subtitle1">Mensalidades</Typography>
        <Box display="flex" flexDirection="row" justifyContent="space-between" mt={1.5}>
          <Typography variant="body1" color="textSecondary">
            Total
          </Typography>
          <Typography variant="body1" color="textSecondary" data-testid="tuition-total-amount">
            {formatCentsToReal(tuition.totalAmount)}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between" my={1}>
          <Discount variant="body1" color="textSecondary">
            Descontos
          </Discount>
          <Discount
            variant="body1"
            data-testid="tuition-discounts-total-amount"
            color="textSecondary"
          >
            {`- ${formatCentsToReal(tuition.discounts)}`}
          </Discount>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <StyledTypography variant="body1">Parcelas</StyledTypography>
          <StyledTypography variant="body1" data-testid="tuition-installments">{`${
            tuition.installments_quantity
          }x de ${
            tuition.installments_quantity > 0
              ? formatCentsToReal(tuition.installment - tuition.discounts)
              : formatCentsToReal(0)
          }`}</StyledTypography>
        </Box>
      </Box>
      <Divider />
      <Box mt={3}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="body1" color="textSecondary">
            Anuidade
          </Typography>
          <Typography variant="body1" color="textSecondary">
            {formatCentsToReal(annuity.totalAmount)}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between" my={1}>
          <StyledTypography variant="body1" color="textSecondary">
            Total de descontos
          </StyledTypography>
          <StyledTypography variant="body1" color="textSecondary">
            {formatCentsToReal(annuity.totalDiscounts)}
          </StyledTypography>
        </Box>
      </Box>
    </Box>
  )
}
export default ContractDetailsSummary
