import { PresignedURL, School } from 'src/shared/interfaces'

export const school1: School = {
  cnpj: '50368864000139',
  created_at: '2021-01-27T17:29:18.701Z',
  id: 'e5224cb4-45d1-45da-b0c8-45b889f4f088',
  name: 'Escolinha do Professor Raimundo',
  slug: 'escolinha-professor-raimundo',
}

export const school2: School = {
  cnpj: '70368564000139',
  created_at: '2021-01-27T17:29:18.701Z',
  id: 'deb31214-e268-46e9-a2ac-ed2fe4ae403d8',
  name: 'Escola de Magia e Bruxaria de Hogwarts',
  slug: 'escolinha-de-hogwarts',
}

export const presigned: PresignedURL = {
  file_id: 'deb31214-e268-46e9-a2ac-ed2fe4ae403d8',
  url: 'https://test.signed.url/fake',
}
export const processingResponse = 'The file was received successfully and is being processed'

export const schoolsList = [school1, school2]
