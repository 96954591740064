import { FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { Box, FormControl, FormControlLabel, InputLabel, MenuItem, Select } from '@material-ui/core'
import { assocPath, pick } from 'ramda'
import { Address, Contract, Guardian, Student } from 'src/shared/interfaces'
import { UFs, validateCpf, validateEmail, validatePhoneNumber } from 'src/shared/utils'
import FormDialog from 'src/escolas/components/modal/FormDialog'
import { useApi, useJWT } from 'src/shared/hooks'
import useContract from 'src/escolas/hooks/useContract'
import {
  EnrollmentEventType,
  useSendEnrollmentEvent,
} from 'src/escolas/router/[schoolSlug]/contratos/enrollmentEvents'
import { getWrongFieldNames } from 'src/escolas/components/contract/create/utils'
import { defaultAddress, fieldsNamesMapping } from '../create/constants'
import { Checkbox, TextField, Typography } from '@olaisaac/design-system'
import { useFeatureFlag, FeatureFlags } from 'src/shared/hooks/useFeatureFlag'
import { useNavigation, useSnackbar } from 'src/escolas/hooks'
import ConfirmationDialog, {
  FailureFeedbackContent,
  failureFeedbackTitle,
} from '../../modal/ConfirmationDialog'

type EditGuardianMenuItemProps = {
  onClick: () => void
}

type EditGuardianFormType = Omit<Guardian, 'address_id'> & {
  recreate_invoices: boolean
  student_name: string
}

const EditGuardianMenuItem: FC<EditGuardianMenuItemProps> = ({ onClick }) => {
  const { api } = useApi()
  const { isAdmin } = useJWT()
  const [isVisible, setIsVisible] = useState(false)
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)
  const [isZipValid, setIsZipValid] = useState<boolean>(true)
  const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState<boolean>(false)
  const [errorFieldNames, setErrorFieldNames] = useState(null)
  const { setMessage: setSnackbarMessage, setIsOpen: setSnackbarIsOpen } = useSnackbar()
  const { schoolId } = useNavigation()
  const form = useForm<EditGuardianFormType>({
    mode: 'onChange',
  })
  const { contract, setContract } = useContract()
  const sendEnrollmentEvent = useSendEnrollmentEvent()
  const guardian = contract?.guardian
  const student = contract?.student
  const defaultValue = ''

  const { value: featureFlagValue, config } = useFeatureFlag(
    FeatureFlags.EDIT_GUARDIAN_PILOT_SCHOOLS_FEATURE_FLAG
  )

  if (!guardian) {
    return null
  }

  const { control, formState, trigger, setValue, reset, clearErrors, setError, getValues } = form
  const formValues = getValues()

  const setFormAddressValues = (address: Omit<Address, 'created_at' | 'updated_at' | 'id'>) => {
    const { zip, street, number, city, state_code, additional_information } = address

    setValue('address.zip', zip)
    setValue('address.street', street)
    setValue('address.number', number)
    setValue('address.city', city)
    setValue('address.state_code', state_code)
    setValue('address.additional_information', additional_information)

    trigger()
  }

  const setFormValues = () => {
    const { email, name, tax_id, phone_number } = guardian

    setValue('student_name', student.name)
    setValue('email', email)
    setValue('name', name)
    setValue('phone_number', phone_number)
    setValue('tax_id', tax_id)

    setFormAddressValues(guardian.address)
    setIsFirstRender(false)
  }

  const clearAddressErrors = fields => {
    clearErrors([...fields])
  }

  const OnZipFieldFilled = async (zip: cep) => {
    await api.guardians
      .getAddressFromZip(zip)
      .then(validAddress => {
        setIsZipValid(true)
        setFormAddressValues(validAddress)
      })
      .catch(() => {
        setIsZipValid(false)
        setFormAddressValues({ ...defaultAddress, zip })
      })
  }

  const flatGuardian = (guardian: Guardian) => ({
    ...pick(['name', 'email', 'tax_id', 'phone_number'], guardian),
    ...pick(
      ['additional_information', 'city', 'number', 'state_code', 'street', 'zip'],
      guardian.address
    ),
  })

  const updateGuardian = (updatedGuardian: Guardian) => {
    setContract(contract => assocPath(['guardian'], updatedGuardian, contract))
    const flatOldGuardian = flatGuardian(contract.guardian)
    const flatNewGuardian = flatGuardian(updatedGuardian)

    const difference = Object.keys(flatOldGuardian).filter(
      k => flatOldGuardian[k] !== flatNewGuardian[k]
    )
    const differenceObj = pick(difference, flatNewGuardian)
    sendEnrollmentEvent(EnrollmentEventType.guardianEdited, {
      contract_id: contract?.id,
      ...differenceObj,
    })
  }

  const updateStudent = (updatedStudent: Student) => {
    setContract(contract => assocPath(['student'], updatedStudent, contract))
    sendEnrollmentEvent(EnrollmentEventType.studentEdited, {
      contract_id: contract?.id,
    })
  }

  const updateContract = (newContract: Contract) => {
    setContract(newContract)
    sendEnrollmentEvent(EnrollmentEventType.contractEdited, {
      contract_id: contract?.id,
    })
  }

  const closeModal = () => {
    setIsVisible(false)
  }

  const handleMenuItemClick = () => {
    setFormValues()
    sendEnrollmentEvent(EnrollmentEventType.editRegistrationClicked, {
      contract_id: contract?.id,
    })

    setIsVisible(true)

    if (typeof onClick === 'function') {
      onClick()
    }
  }

  const submitHandler = async (form: EditGuardianFormType) => {
    const { student_name } = form

    try {
      await api.guardians.update(guardian.id, form, schoolId).then(updateGuardian)

      student_name !== student.name &&
        (await api.students
          .update(student.id, { name: student_name }, schoolId)
          .then(updateStudent))

      form.recreate_invoices &&
        (await api.contracts.recreateInvoices(contract.id).then(updateContract))

      closeModal()
      setSnackbarMessage('Cadastro editado com sucesso.')
      setSnackbarIsOpen(true)
    } catch (errors) {
      setIsFeedbackDialogOpen(true)

      if (!errors) {
        setErrorFieldNames(null)
        return
      }

      const wrongFieldNames = getWrongFieldNames(errors)
      setErrorFieldNames(wrongFieldNames)
    }
  }
  const invalidTaxId = Boolean(formState.errors.tax_id)

  const isFeatureFlagEnabled = !isAdmin && featureFlagValue && config.includes(schoolId)

  const handleCloseFeedbackDialog = () => {
    setIsFeedbackDialogOpen(false)
  }

  const getFeedbackDialogParameters = (): {
    feedbackDialogContent: React.ReactNode
    feedbackDialogTitle: string
  } => {
    if (errorFieldNames?.length) {
      return {
        feedbackDialogTitle: 'Por favor, revise os seguintes campos:',
        feedbackDialogContent: (
          <ul>
            {errorFieldNames.map((name, idx) => (
              <li key={idx}>{fieldsNamesMapping[name]}</li>
            ))}
          </ul>
        ),
      }
    }

    return {
      feedbackDialogTitle: failureFeedbackTitle,
      feedbackDialogContent: <FailureFeedbackContent />,
    }
  }

  const { feedbackDialogTitle, feedbackDialogContent } = getFeedbackDialogParameters()

  return (
    <>
      <ConfirmationDialog
        isVisible={isFeedbackDialogOpen}
        onClose={handleCloseFeedbackDialog}
        submitHandler={handleCloseFeedbackDialog}
        title={feedbackDialogTitle}
        centered
      >
        {feedbackDialogContent}
      </ConfirmationDialog>
      <MenuItem onClick={handleMenuItemClick}>Editar cadastro</MenuItem>
      <FormDialog
        form={form}
        isVisible={isVisible}
        disableFeedback
        title="Editar cadastro"
        onClose={closeModal}
        onSubmit={submitHandler}
      >
        <Typography variation="bodySmall" color="secondary">
          Lembre-se de cadastrar os dados corretamente. Essas informações são essenciais para
          garantirmos o contato com o responsável financeiro. As alterações irão refletir em todos
          os contratos desse responsável.
        </Typography>
        <Box mt={5}>
          <FormControl fullWidth variant="outlined">
            <Controller
              rules={{ required: true }}
              name="student_name"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  disabled={isFeatureFlagEnabled}
                  label="Nome do Aluno"
                  id="edit-student-name-input"
                  fullWidth
                  error={Boolean(error?.type)}
                  helperText={error?.type ? 'Nome do aluno inválido' : ''}
                />
              )}
            />
          </FormControl>
        </Box>
        <Box mt={5}>
          <FormControl fullWidth variant="outlined">
            <Controller
              rules={{ required: true }}
              name="name"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  disabled={isFeatureFlagEnabled}
                  label="Nome do Responsável"
                  id="edit-guardian-name-input"
                  fullWidth
                  error={Boolean(error?.type)}
                  helperText={error?.type ? 'Nome do responsável inválido' : ''}
                />
              )}
            />
          </FormControl>
        </Box>
        <Box mt={4}>
          <FormControl fullWidth variant="outlined">
            <Controller
              rules={{ required: true, validate: validateCpf }}
              name="tax_id"
              control={control}
              render={({ field: { onChange, value } }) => (
                <NumberFormat
                  label="CPF"
                  disabled={isFeatureFlagEnabled}
                  type="tel"
                  format="###.###.###-##"
                  id="edit-guardian-tax-id-input"
                  onValueChange={currentValue => {
                    onChange(currentValue.value)
                  }}
                  value={value}
                  customInput={TextField}
                  variant="outlined"
                  error={invalidTaxId}
                  helperText={invalidTaxId ? 'CPF inválido' : ''}
                />
              )}
            />
          </FormControl>
        </Box>
        <Box mt={4}>
          <FormControl fullWidth variant="outlined">
            <Controller
              rules={{
                validate: v => (v ? validateEmail(v) : true),
                max: 254,
                min: 3,
              }}
              name="email"
              control={control}
              render={({ field, fieldState: { error, invalid } }) => (
                <TextField
                  {...field}
                  label="Email"
                  id="edit-guardian-email-input"
                  fullWidth
                  error={Boolean(error?.type) || invalid}
                  helperText={error?.type || invalid ? 'E-mail inválido' : ''}
                />
              )}
            />
          </FormControl>
        </Box>
        <Box mt={4}>
          <FormControl fullWidth variant="outlined">
            <Controller
              rules={{
                required: true,
                validate: validatePhoneNumber,
              }}
              name="phone_number"
              control={control}
              render={({ field: { onChange, value, ...rest }, fieldState: { error } }) => (
                <NumberFormat
                  {...rest}
                  label="Celular"
                  id="edit-guardian-phone-number-input"
                  type="tel"
                  format="(##) #####-####"
                  mask="_"
                  onValueChange={currentValue => {
                    onChange(currentValue.value)
                  }}
                  value={value}
                  customInput={TextField}
                  variant="outlined"
                  error={Boolean(error?.type)}
                  helperText={error?.type ? 'Telefone inválido' : ''}
                />
              )}
            />
          </FormControl>
        </Box>
        <Box mt={4}>
          <FormControl fullWidth variant="outlined">
            <Controller
              rules={{
                required: true,
                minLength: 8,
                maxLength: 8,
                validate: () => isZipValid,
              }}
              name="address.zip"
              control={control}
              render={({ field: { onChange, value, ...rest }, fieldState: { error, invalid } }) => (
                <NumberFormat
                  {...rest}
                  label="CEP"
                  id="edit-guardian-zip-input"
                  type="tel"
                  format="#####-###"
                  mask="_"
                  onValueChange={currentValue => {
                    if (!currentValue.value) {
                      reset(
                        {
                          ...formValues,
                          address: { ...defaultAddress },
                        },
                        { keepErrors: true }
                      )

                      setError('address.zip', {
                        type: 'required',
                      })

                      return clearAddressErrors([
                        'address.street',
                        'address.number',
                        'address.city',
                        'address.state_code',
                      ])
                    }

                    onChange(currentValue.value)

                    if (!isFirstRender) {
                      currentValue.value.length === 8 && OnZipFieldFilled(currentValue.value)
                    }
                  }}
                  value={value}
                  customInput={TextField}
                  variant="outlined"
                  error={Boolean(error?.type || invalid)}
                  helperText={error?.type || invalid ? 'CEP inválido' : ''}
                />
              )}
            />
          </FormControl>
        </Box>
        <Box mt={4}>
          <FormControl fullWidth variant="outlined">
            <Controller
              rules={{ required: true }}
              name="address.street"
              control={control}
              render={({ field: { value, ...rest }, fieldState: { error } }) => (
                <TextField
                  {...rest}
                  label="Rua"
                  id="edit-guardian-street-input"
                  fullWidth
                  value={value || defaultValue}
                  error={Boolean(error?.type && isZipValid)}
                  helperText={error?.type && isZipValid ? 'Rua inválida' : ''}
                />
              )}
            />
          </FormControl>
        </Box>
        <Box mt={4}>
          <FormControl fullWidth variant="outlined">
            <Controller
              rules={{
                required: true,
              }}
              name="address.number"
              control={control}
              render={({ field: { value, ...rest }, fieldState: { error } }) => (
                <TextField
                  {...rest}
                  label="Número"
                  id="edit-guardian-number-input"
                  value={value || defaultValue}
                  error={Boolean(error?.type && isZipValid)}
                  helperText={error?.type && isZipValid ? 'Número inválido' : ''}
                />
              )}
            />
          </FormControl>
        </Box>
        <Box mt={4}>
          <FormControl fullWidth variant="outlined">
            <Controller
              rules={{
                required: false,
              }}
              name="address.additional_information"
              control={control}
              render={({ field: { value, ...rest } }) => (
                <TextField
                  {...rest}
                  label="Complemento (opcional)"
                  id="edit-guardian-additional-information-input"
                  value={value || defaultValue}
                />
              )}
            />
          </FormControl>
        </Box>
        <Box mt={4}>
          <FormControl fullWidth variant="outlined">
            <Controller
              rules={{ required: true }}
              name="address.city"
              control={control}
              render={({ field: { value, ...rest }, fieldState: { error } }) => (
                <TextField
                  {...rest}
                  label="Cidade"
                  id="edit-guardian-city-input"
                  value={value || defaultValue}
                  error={Boolean(error?.type && isZipValid)}
                  helperText={error?.type && isZipValid ? 'Cidade inválida' : ''}
                />
              )}
            />
          </FormControl>
        </Box>
        <Box mt={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="edit-guardian-state">Estado</InputLabel>
            <Controller
              rules={{ required: true }}
              name="address.state_code"
              control={control}
              render={({ field: { value, ...rest }, fieldState: { error } }) => (
                <Select
                  {...rest}
                  labelId="edit-guardian-state"
                  label="Estado"
                  value={value || defaultValue}
                  error={Boolean(error?.type && isZipValid)}
                >
                  {UFs.map(state => (
                    <MenuItem key={state} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Box>
        <Box mt={4}>
          <Controller
            defaultValue={false}
            name="recreate_invoices"
            control={control}
            render={({ field }) => (
              <>
                <FormControlLabel
                  control={<Checkbox {...field} />}
                  label="Atualizar próximos boletos"
                />
                <Box pl={2.5}>
                  <Typography variation="bodySmall" color="secondary">
                    Ao marcar a opção, novos boletos vão ser emitidos, cancelando aqueles com dados
                    desatualizados. Os boletos com datas anteriores à atualização dos dados não vão
                    sofrer alterações.
                  </Typography>
                </Box>
              </>
            )}
          />
        </Box>
      </FormDialog>
    </>
  )
}

export default EditGuardianMenuItem
