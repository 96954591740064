import { FC, useState } from 'react'
import NumberFormat from 'react-number-format'
import { useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'

import { Button, Checkbox, FormControlLabel } from '@olaisaac/design-system'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'

import config from 'src/config'
import Navbar from 'src/responsaveis/components/Navbar'
import View from 'src/responsaveis/components/View'
import { validateCpf } from 'src/shared/utils'
import sortingHatClient, {
  AuthAPI,
  setCookies,
  LoginPayload,
  clearCookies,
} from 'src/shared/api/sortingHatClient'

export enum FormNames {
  PASSWORD = 'password',
  TAX_ID = 'tax_id',
}

type FormType = {
  password: string
  tax_id: cpf
}

const LoginPage: FC = () => {
  const { handleSubmit, control } = useForm<FormType>({
    mode: 'onChange',
  })

  const history = useHistory()

  const [invalidPassword, setInvalidPassword] = useState<boolean>(false)
  const [invalidTaxId, setInvalidTaxId] = useState<boolean>(false)
  const [viewPassword, setViewPassword] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const clearFeedbackState = () => {
    setInvalidTaxId(false)
    setInvalidPassword(false)
  }

  const AuthMethod = async (form: FormType) => {
    clearFeedbackState()

    if (!validateCpf(form.tax_id)) {
      setInvalidTaxId(true)
      return
    }

    const body: LoginPayload = {
      grant_type: 'cpf',
      tax_id: form.tax_id,
      password: form.password,
    }

    try {
      const login = await AuthAPI.login(body, config.SORTING_HAT.REALM)
      setCookies(login, config.SORTING_HAT.REALM)

      const { guardian_id }: any = sortingHatClient.services.token.Get({ key: 'props' })

      if (guardian_id === undefined) {
        clearCookies()
        setInvalidTaxId(true)
        return
      }

      history.push(`dashboard/${guardian_id}`)
    } catch (err) {
      if (!err) return
      setInvalidPassword(true)
    }
  }

  const handlerMiddleware = async (form: FormType) => {
    setIsLoading(true)
    await AuthMethod(form)
    setIsLoading(false)
  }

  return (
    <>
      <Navbar inverted noShadow showMenu={false} />
      <View color="white">
        <Container maxWidth="md">
          <Typography variant="h6" color="textPrimary">
            Faça o seu login
          </Typography>
          <br />
          <form onSubmit={handleSubmit(handlerMiddleware)}>
            <Box mb={3}>
              <FormControl fullWidth variant="outlined">
                <Controller
                  rules={{ required: true, validate: () => true }}
                  name={FormNames.TAX_ID}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <NumberFormat
                      type="tel"
                      format="###.###.###-##"
                      onValueChange={value => {
                        onChange(value.value)
                      }}
                      value={value}
                      customInput={TextField}
                      label="CPF"
                      variant="outlined"
                      error={invalidTaxId}
                      helperText={invalidTaxId ? 'O CPF digitado não é válido!' : ''}
                    />
                  )}
                />
              </FormControl>
            </Box>

            <Box mb={3}>
              <FormControl fullWidth variant="outlined">
                <Controller
                  rules={{ required: true, validate: () => true }}
                  name={FormNames.PASSWORD}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      variant="outlined"
                      label="Senha"
                      className="fs-mask"
                      value={value}
                      onChange={value => {
                        onChange(value)
                      }}
                      type={viewPassword ? 'text' : 'password'}
                      error={invalidPassword}
                      helperText={invalidPassword ? 'Senha incorreta!' : ''}
                    />
                  )}
                />
              </FormControl>
            </Box>

            <Box mb={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={viewPassword}
                    onChange={event => setViewPassword(event.target.checked)}
                    name="viewPassword"
                  />
                }
                label={
                  <Typography variant="body2" color="textPrimary">
                    Mostrar senha
                  </Typography>
                }
              />
            </Box>

            <Box display="flex" justifyContent="flex-end">
              <Button type="submit" variation="primary" loading={isLoading} disabled={false}>
                Entrar
              </Button>
            </Box>
          </form>
        </Container>
      </View>
    </>
  )
}

export default LoginPage
