import { useEffect, useState } from 'react'

export type StatusSearch = {
  isCPF: boolean
  isGuardian: boolean
  searchText: string
}

const checkIsNumberAndLenghtLesserThan11 = (value: string): boolean => /^\d{1,11}$/.test(value)

type CallbackChangeText = (text: string, isCPF: boolean) => void

const useSearchByStudentOrGuardian = (
  text: string,
  callbackChangeSearch: CallbackChangeText,
  search: (statusSearch: StatusSearch) => void
) => {
  const [statusSearch, setStatusSearch] = useState<StatusSearch>({
    searchText: text,
    isGuardian: false,
    isCPF: checkIsNumberAndLenghtLesserThan11(text),
  })

  const handleStatusSearch = (key: string, value: any): void => {
    if (key === 'searchText') {
      return setStatusSearch({
        ...statusSearch,
        [key]: value,
        isCPF: checkIsNumberAndLenghtLesserThan11(value),
      })
    }
    setStatusSearch({ ...statusSearch, [key]: value })
  }

  const changeTypeSearch = (isGuardian: boolean) => {
    if (isGuardian === statusSearch.isGuardian) return
    handleStatusSearch('isGuardian', isGuardian)
  }

  useEffect(() => {
    callbackChangeSearch(statusSearch.searchText, statusSearch.isCPF)
  }, [statusSearch])

  useEffect(() => {
    search(statusSearch)
  }, [text, statusSearch.isGuardian])

  return {
    statusSearch,
    handleStatusSearch,
    changeTypeSearch,
  }
}

export default useSearchByStudentOrGuardian
