import { useEffect, useState } from 'react'
import MuiPopover from '@material-ui/core/Popover'
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded'
import { Box } from '@material-ui/core'
import { ListSectionOptionsCheckboxFilter } from './ListSectionOptionsCheckboxFilter'
import { Button, ButtonGroup } from '@olaisaac/design-system'
import { ListAppliedFilterChip } from './ListAppliedFilterChip'
import theme from 'src/shared/theme'
import { FilterSelection, TableFilterOptionsGeneric, ValueFilter } from './types'

export type TableFilterProps = {
  callbackFilters: (values: Array<ValueFilter>) => void
  filterEventHandler?: () => void
  filterOptions: Array<TableFilterOptionsGeneric>
  maxListCharacter: number
}

const defaultFilterOptions = (
  optionsFilter: TableFilterOptionsGeneric[],
  clear?: boolean
): FilterSelection => {
  const filterSelection = {}
  optionsFilter.forEach(option => {
    filterSelection[option?.filterTitle] = {}
    option?.labelOptions.forEach(opt => {
      Object.entries(opt?.option).forEach(([key, value]) => {
        filterSelection[option?.filterTitle][value] = {
          label: value,
          value: key,
          checked: clear ? false : opt?.checked,
          group: option?.filterName,
        }
      })
    })
  })
  return filterSelection
}

export function TableFilterCheckout({
  filterOptions,
  callbackFilters,
  filterEventHandler,
  maxListCharacter,
}: TableFilterProps) {
  const [filterAnchor, setFilterAnchor] = useState<HTMLButtonElement | null>(null)
  const [filterSelection, setFilterSelection] = useState<FilterSelection>(
    defaultFilterOptions(filterOptions)
  )
  const [appliedFilter, setAppliedFilter] = useState<FilterSelection>(
    defaultFilterOptions(filterOptions)
  )
  const [updatedAppliedFilter, setUpdatedAppliedFilter] = useState<boolean>(false)

  const clearFilterSelection = () => {
    setFilterSelection(defaultFilterOptions(filterOptions, true))
  }

  const handleFilterSelection = (group: string, label: string | number, isChecked: boolean) => {
    setFilterSelection({
      ...filterSelection,
      [group]: {
        ...filterSelection[group],
        [label]: {
          ...filterSelection[group][label],
          checked: isChecked,
        },
      },
    })
  }

  const getValuesFromFilter = (filter: FilterSelection): Array<ValueFilter> => {
    const selectedFilter = []
    Object.keys(filter).forEach((title: string) => {
      Object.keys(filter[title]).forEach((option: string) => {
        const { group, value, label, checked } = filter[title][option]
        if (checked) {
          selectedFilter.push({ group, label, value })
        }
      })
    })
    return selectedFilter
  }

  useEffect(() => {
    if (updatedAppliedFilter) {
      setAppliedFilter(filterSelection)
      callbackFilters(getValuesFromFilter(filterSelection))
      setUpdatedAppliedFilter(false)
    }
  }, [updatedAppliedFilter])

  const applyFilter = () => {
    setUpdatedAppliedFilter(true)
  }

  const removeFilter = (group: string, label: string | number, isChecked: boolean) => {
    handleFilterSelection(group, label, isChecked)
    applyFilter()
  }

  const handleFilterPopoverClose = () => {
    setFilterAnchor(null)
  }

  const applyFilterAndClosePopOver = () => {
    handleFilterPopoverClose()
    applyFilter()
  }

  const handleFilterPopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    filterEventHandler && filterEventHandler()
    setFilterAnchor(event.currentTarget)
  }

  useEffect(() => {
    if (filterSelection) {
      setAppliedFilter(filterSelection)
      callbackFilters(getValuesFromFilter(filterSelection))
    }
  }, [])
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row-reverse',
      }}
    >
      <Button
        startIcon={<FilterListRoundedIcon />}
        onClick={handleFilterPopoverClick}
        variation="ghost"
      >
        Filtro
      </Button>
      <MuiPopover
        open={Boolean(filterAnchor)}
        anchorEl={filterAnchor}
        onClose={handleFilterPopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            minHeight: '334px',
            minWidth: '472px',
            padding: `${theme.spacing(3)}px`,
            width: 'auto',
          },
        }}
      >
        <ListSectionOptionsCheckboxFilter
          filterSelection={filterSelection}
          handleclick={handleFilterSelection}
        />
        <ButtonGroup style={{ marginTop: `${theme.spacing(2)}px` }}>
          <Button
            style={{ width: '124px' }}
            variation="ghost"
            onClick={() => clearFilterSelection()}
          >
            Limpar
          </Button>
          <Button
            style={{ width: '124px' }}
            variation="primary"
            onClick={applyFilterAndClosePopOver}
          >
            Aplicar
          </Button>
        </ButtonGroup>
      </MuiPopover>
      <ListAppliedFilterChip
        appliedFilter={appliedFilter}
        removeFilter={removeFilter}
        maxListCharacter={maxListCharacter}
      />
    </Box>
  )
}

export default TableFilterCheckout
