import { useState, createContext, useRef } from 'react'
import { useApi } from 'src/shared/hooks'
import { Guardian } from 'src/shared/interfaces'

type GuardianContext = {
  clearGuardian: () => void
  guardian: Guardian
  loadGuardianById: (guardianId: string) => void
}

export const GuardianContext = createContext<GuardianContext>(null)

export const GuardianProvider = props => {
  const { api } = useApi()
  const [guardian, setGuardian] = useState<Guardian>()
  const getByIdPromise = useRef(null)
  const loadGuardianById = (guardianId: string) => {
    if (guardianId && !getByIdPromise.current) {
      getByIdPromise.current = api.guardians.get(guardianId).then(setGuardian)
    }
  }
  const clearGuardian = () => setGuardian(null)
  return (
    <GuardianContext.Provider value={{ guardian, loadGuardianById, clearGuardian }}>
      {props.children}
    </GuardianContext.Provider>
  )
}
