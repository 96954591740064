export default {
  API: {
    URL: `[REACT_APP_API_HOST]`,
    GOOGLE_CLIENT_ID: `[REACT_APP_GOOGLE_CLIENT_ID]`,
    COOKIES_DOMAIN: `[REACT_APP_COOKIES_DOMAIN]`,
  },
  DEFAULT_CONTRACT_YEAR: `[REACT_APP_DEFAULT_CONTRACT_YEAR]`,
  EVENT: {
    HOST: `[REACT_APP_EVENT_HOST]`,
    ENABLED: `[REACT_APP_EVENT_ENABLED]`,
  },
  CHECK_CONTRACT_DUPLICATION_ENABLED: `[REACT_APP_CHECK_CONTRACT_DUPLICATION_ENABLED]`,
  FULLSTORY_ORG_ID: `[REACT_APP_FULLSTORY_ORG_ID]`,
  GIT_TAG: `[REACT_APP_GIT_TAG]`,
  HOTJAR_ID: `[REACT_APP_HOTJAR_ID]`,
  HOTJAR_SNIPPET_VERSION: `[REACT_APP_HOTJAR_SNIPPET_VERSION]`,
  REACT_APP_AGGLUTINATION_ENABLED: `[REACT_APP_AGGLUTINATION_ENABLED]`,
  SELF_ONBOARDING_REDIRECT_URL: `[REACT_APP_SELF_ONBOARDING_REDIRECT_URL]`,
  SENTRY_ENABLED: `[REACT_APP_SENTRY_ENABLED]`,
  SORTING_HAT: {
    REALM: `[REACT_APP_SORTING_HAT_REALM]`,
    HOST: `[REACT_APP_SORTING_HAT_HOST]`,
    LOGIN_PATH: `[REACT_APP_SORTING_HAT_LOGIN_PATH]`,
    GUARDIAN_SORTING_HAT_LOGIN: `[REACT_APP_GUARDIAN_SORTING_HAT_LOGIN]`,
  },
  SPLIT_IO_API_KEY: `[REACT_APP_SPLIT_IO_API_KEY]`,
  LIST_SLUG_SCHOOL_CHECKOUT: `[REACT_APP_LIST_SLUG_SCHOOL_CHECKOUT]`,
  TARGET: `[REACT_APP_BUILD_TARGET]`,
  ADD_DISCOUNTS_FEATURE_FLAG: `[REACT_APP_ADD_DISCOUNTS_FEATURE_FLAG]`,
  PILOT_SCHOOLS_FEATURE_FLAG: `[REACT_APP_PILOT_SCHOOLS_FEATURE_FLAG]`,
  EDIT_GUARDIAN_PILOT_SCHOOLS_FEATURE_FLAG: `[REACT_APP_EDIT_GUARDIAN_PILOT_SCHOOLS_FEATURE_FLAG]`,
  MANUAL_LIQUIDATION_PILOT_SCHOOLS_FEATURE_FLAG: `[REACT_APP_MANUAL_LIQUIDATION_PILOT_SCHOOLS_FEATURE_FLAG]`,
  HIDE_2021_CONTRACT_BUTTON: `[REACT_APP_HIDE_2021_CONTRACT_BUTTON]`,
  MAX_DAYS_TO_CHANGE_CONTRACT_DUE_DAY: `[REACT_APP_MAX_DAYS_TO_CHANGE_CONTRACT_DUE_DAY]`,
  GUARDIAN_STUDENT_AUTO_COMPLETE_ENABLED: `[REACT_APP_GUARDIAN_STUDENT_AUTO_COMPLETE_ENABLED]`,
}
