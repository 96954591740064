import { Controller, UseFormReturn } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { path } from 'ramda'
import { formatCentsToReal } from 'src/shared/utils'
import { ChangeEvent } from 'react'

export const discountsOptions = [
  { name: 'Mantêm após vencimento', value: -1 },
  { name: 'Mantêm até vencimento', value: 0 },
  { name: '5 dias antes do vencimento', value: 5 },
  { name: '10 dias antes do vencimento', value: 10 },
  { name: '15 dias antes do vencimento', value: 15 },
  { name: '20 dias antes do vencimento', value: 20 },
  { name: '25 dias antes do vencimento', value: 25 },
  { name: '30 dias antes do vencimento', value: 30 },
]

type DiscountFormFieldsProps = {
  amountDefaultValue?: number
  amountFieldName?: string
  daysBeforeDueDateDefaultValue?: number
  daysBeforeDueDateFieldName?: string
  descriptionDefaultValue?: string
  descriptionFieldName?: string
  form: UseFormReturn<any>
  totalAmountPaid: number
}

function DiscountFormFields({
  amountDefaultValue,
  amountFieldName = 'amount',
  daysBeforeDueDateDefaultValue,
  daysBeforeDueDateFieldName = 'days_before_due_date',
  descriptionDefaultValue,
  descriptionFieldName = 'description',
  form,
  totalAmountPaid,
}: DiscountFormFieldsProps) {
  const { control, formState, setValue, watch } = form
  const invalidAmount = Boolean(path(amountFieldName.split('.'), formState.errors))

  const discountAmount = watch(amountFieldName)
  const percentageValue = (discountAmount / totalAmountPaid) * 100

  const onPercentageValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    const percent = inputValue.replace('%', '')
    const newDiscountAmount = (totalAmountPaid * +percent) / 100
    setValue(amountFieldName, newDiscountAmount, { shouldValidate: true })
  }

  return (
    <>
      <Box mb={3} mt={1}>
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item xs={7}>
            <FormControl fullWidth variant="outlined">
              <Controller
                rules={{ required: true, validate: v => v > 0 && v <= totalAmountPaid }}
                control={control}
                name={amountFieldName}
                defaultValue={amountDefaultValue}
                render={({ field: { onChange, value } }) => (
                  <NumberFormat
                    onValueChange={value => {
                      onChange(value.floatValue)
                    }}
                    id={amountFieldName}
                    customInput={TextField}
                    variant="outlined"
                    label="Valor"
                    format={formatCentsToReal}
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    value={value}
                    error={invalidAmount}
                    helperText={
                      invalidAmount ? 'Desconto não pode ser zero ou superior a 100%' : ''
                    }
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <NumberFormat
              id={`${amountFieldName}-percent`}
              onChange={onPercentageValueChange}
              customInput={TextField}
              variant="outlined"
              label="Percentual"
              suffix="%"
              value={percentageValue}
              decimalScale={2}
              InputProps={{
                inputProps: { min: 0, max: 100 },
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mb={3}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id={daysBeforeDueDateFieldName}>Desconto válido até</InputLabel>
          <Controller
            rules={{ required: true, validate: v => v >= -1 }}
            control={control}
            name={daysBeforeDueDateFieldName}
            defaultValue={daysBeforeDueDateDefaultValue ?? ''}
            render={({ field: { value, ...rest } }) => (
              <Select
                labelId={daysBeforeDueDateFieldName}
                label="Desconto válido até"
                value={value}
                inputProps={{
                  label: 'Desconto válido até',
                }}
                {...rest}
              >
                {discountsOptions.map(({ name, value }) => (
                  <MenuItem key={value} value={value}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </Box>
      <Box mb={3}>
        <FormControl fullWidth variant="outlined">
          <Controller
            control={control}
            name={descriptionFieldName}
            defaultValue={descriptionDefaultValue}
            render={({ field }) => (
              <TextField
                id={descriptionFieldName}
                variant="outlined"
                label="Nome do desconto"
                {...field}
              />
            )}
          />
        </FormControl>
      </Box>
    </>
  )
}

export default DiscountFormFields
