import { Chip } from '@olaisaac/design-system'
import { FC } from 'react'
import { ContractStatus, EnrollmentStatus, SignAndPaymentStatus } from 'src/shared/interfaces'
import styled from 'styled-components'

const StyledDiv = styled.div`
  vertical-align: middle;
  text-align: center;
  border-radius: 100px;
  padding: 4px ${({ theme }) => theme.spacing(0.8)}px;

  &.success {
    background-color: ${({ theme }) => theme.palette.success.main};
  }
  &.error {
    background-color: ${({ theme }) => theme.palette.grey[900]};
  }
  &.primary {
    background-color: ${({ theme }) => theme.primitiveTokens.colors.isaacBlue[30]};
  }
  &.secondary {
    background-color: ${({ theme }) => theme.primitiveTokens.colors.isaacBlue[40]};
  }
  &.tertiary {
    background-color: ${({ theme }) => theme.palette.grey[600]};
  }
  &.warning {
    background-color: ${({ theme }) => theme.primitiveTokens.colors.isaacBlue[50]};
  }
`

export type EnrollmentStatusBadgeColor = 'gray' | 'green' | 'yellow' | 'coral'

export type EnrollmentStatusBadgeProps = {
  color: EnrollmentStatusBadgeColor
}
export const enrollmentParamsDict: PartialRecord<
  EnrollmentStatus | SignAndPaymentStatus | ContractStatus,
  { color: EnrollmentStatusBadgeColor; text: string }
> = {
  CANCELED: { color: 'coral', text: 'Inativo' },
  COMPLETE: { color: 'green', text: 'Matriculado' },
  ENROLLED: { color: 'green', text: 'Matriculado' },
  NOT_ENROLLED: { color: 'yellow', text: 'Não matriculado' },
  PAYMENT_AND_SIGN_PENDING: { color: 'yellow', text: 'Falta pagar e assinar' },
  PAYMENT_PENDING: { color: 'yellow', text: 'Falta pagar' },
  SIGN_PENDING: { color: 'yellow', text: 'Falta assinar' },
}

const EnrollmentStatusBadge: FC<EnrollmentStatusBadgeProps> = ({ color, children }) => (
  <StyledDiv className={color} data-testid="enrollment-status-badge">
    {children}
  </StyledDiv>
)

export const renderEnrollmentStatus = (status: EnrollmentStatus | ContractStatus) => (
  <Chip
    data-id={enrollmentParamsDict[status]?.text}
    variation={enrollmentParamsDict[status]?.color}
    label={enrollmentParamsDict[status]?.text}
  />
)

export default EnrollmentStatusBadge
