import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import styled from 'styled-components'
import Drawer from '@material-ui/core/Drawer'
import { DrawerState } from 'src/escolas/components/contract/InstallmentDrawer/constants'
import DrawerContent from 'src/escolas/components/contract/InstallmentDrawer/DrawerContent'
import { ActionDrawerHeader, Typography } from '@olaisaac/design-system'
import { useContract, useNavigation } from 'src/escolas/hooks'
import { propEq } from 'ramda'
import { renderStatus } from 'src/escolas/components/contract/ContractDetails'
import { Installment, Receivable, ReceivableStatuses } from 'src/shared/interfaces'
import { Box } from '@material-ui/core'
import { date2PTFormat } from 'src/shared/utils'
import { FeatureFlags, useFeatureFlag } from 'src/shared/hooks/useFeatureFlag'
import { useJWT } from 'src/shared/hooks'

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    width: calc(((100% - 160px) / 12) * 4);
    transition: transform 0.3s cubic-bezier(0.42, 0.29, 0.39, 0.83) !important;
  }
`

export type InstallmentDrawerProps = {
  callbackCheckout?: Dispatch<SetStateAction<boolean>>
  isOpen: boolean
  onClose: () => void
  orderReference: string
  removeIsaacOption?: boolean
  resetManualLiquidationForm?: boolean
  selectedReceivableId: uuid
  setIsFailureFeedbackOpen?: (value: boolean) => void
  setSelectedReceivableId: Dispatch<SetStateAction<uuid>>
}

const InstallmentDrawer: FC<InstallmentDrawerProps> = ({
  callbackCheckout,
  orderReference,
  isOpen,
  onClose,
  removeIsaacOption,
  selectedReceivableId,
  setSelectedReceivableId,
  resetManualLiquidationForm,
  setIsFailureFeedbackOpen,
}) => {
  const [drawerState, setDrawerState] = useState<DrawerState>(DrawerState.DEFAULT)
  const goBackToDefaultContent = () => setDrawerState(DrawerState.DEFAULT)
  const goBackToManualLiquidationContent = () => setDrawerState(DrawerState.MANUAL_LIQUIDATION)
  const showGoBack = drawerState !== DrawerState.DEFAULT

  const { contract } = useContract()
  const { isAdmin } = useJWT()
  const { schoolId } = useNavigation()

  const installment = contract?.installments.find(installment =>
    installment?.receivables.some(propEq('id', selectedReceivableId))
  )
  const receivables = installment?.receivables

  const [receivable, setReceivable] = useState<Receivable>(null)

  useEffect(() => {
    const receivable = receivables?.find(propEq('id', selectedReceivableId))
    if (receivable) setReceivable(receivable)
  }, [selectedReceivableId])

  const isPaid = receivable?.status === ReceivableStatuses.PAID
  const isReceivableOpen = receivable?.status === ReceivableStatuses.OPEN
  const { available_payment_methods } = receivable?.invoice || {}
  const { value: featureFlagValue, config } = useFeatureFlag(
    FeatureFlags.PILOT_SCHOOLS_FEATURE_FLAG
  )

  const isFeatureFlagEnabled = !isAdmin && featureFlagValue && config.includes(schoolId)

  const paymentMethodMap = {
    bank_slip: 'Boleto',
    pix: 'Pix',
    credit_card: 'Cartão de crédito',
  }
  let info = ''

  if (isReceivableOpen && available_payment_methods?.length) {
    const availablePayments = available_payment_methods.includes('all')
      ? Object.values(paymentMethodMap)
      : available_payment_methods.map(method => paymentMethodMap[method])

    const hasMutiplePayments = availablePayments.length > 1

    const availablePaymentsMessage = hasMutiplePayments
      ? `${availablePayments.slice(0, -1).join(', ')} e ${availablePayments.slice(-1)}`
      : availablePayments[0]
    info = `Forma de pagamento da parcela: ${availablePaymentsMessage}`
  }

  const getReceivableFromReceivableId = (selectedId: string) => {
    const receivable = installment?.receivables?.find(r => r?.id === selectedId)

    return receivable
  }

  const getReceivableFromInstallmentId = (installmentId: string, installments: Installment[]) => {
    const installment = installments?.find(i => i?.id === installmentId)
    // Check if the originals where agglutinated
    const originalReceivables = installment?.receivables.find(receivable => {
      if (!receivable.original_receivables) return false
      return receivable?.original_receivables.some(
        r => r?.status === ReceivableStatuses.AGGLUTINATED || r?.status === ReceivableStatuses.PAID
      )
    })
    // Return the root node of the agglutination tree
    return originalReceivables
  }

  const getInstallmentIdFromSelectedId = (selectedId: string) => {
    return selectedId.split('installment::')[1]
  }
  const getIncludesInstallment = (selectedId: string) => {
    return selectedId?.includes('installment::')
  }
  const getOriginalReceivables = (selectedId: string, installments: Installment[]) => {
    const isSplitReceivable = getIncludesInstallment(selectedId)
    if (isSplitReceivable) {
      const installmentId = getInstallmentIdFromSelectedId(selectedId)
      const agglutinationRoot = getReceivableFromInstallmentId(installmentId, installments)
      const originalReceivables = agglutinationRoot?.original_receivables
      return { originalReceivables, receivable: agglutinationRoot, isSplit: true }
    }

    const receivable = getReceivableFromReceivableId(selectedId)
    const originalReceivables = receivable?.original_receivables

    return { originalReceivables, receivable, isSplit: false }
  }

  const { isSplit } = getOriginalReceivables(selectedReceivableId, contract?.installments)

  const drawerHeader = {
    [DrawerState.DEFAULT]: (
      <ActionDrawerHeader
        title={`Parcela ${orderReference}`}
        subtitle={
          !isSplit && isReceivableOpen
            ? `Vencimento em ${date2PTFormat(receivable?.due_date)}`
            : undefined
        }
        onClose={onClose}
        onBack={showGoBack ? goBackToDefaultContent : undefined}
        extraContent={
          !isSplit && isReceivableOpen ? (
            info && (
              <Typography variation="caption" color="secondary" withoutMargin>
                {info}
              </Typography>
            )
          ) : (
            <Box display="flex" flexWrap="no-wrap" alignItems="center">
              {renderStatus(receivable?.status)}
            </Box>
          )
        }
      />
    ),
    [DrawerState.MANUAL_LIQUIDATION]: (
      <ActionDrawerHeader
        title={`Parcela ${orderReference}`}
        subtitle={`${isPaid ? 'Editar' : 'Registrar'} recebimento ${
          removeIsaacOption ? 'na conta escola' : ''
        }`}
        extraContent={
          isFeatureFlagEnabled && (
            <Typography variation="caption" color="secondary">
              Você está registrando este recebimento na conta do isaac (maquininha ou transferência)
            </Typography>
          )
        }
        onClose={onClose}
        onBack={showGoBack ? goBackToDefaultContent : undefined}
      />
    ),
    [DrawerState.CONFIRM_MANUAL_LIQUIDATION]: (
      <ActionDrawerHeader
        title="Registrar recebimento"
        onClose={onClose}
        onBack={showGoBack ? goBackToManualLiquidationContent : undefined}
      />
    ),
    [DrawerState.RENEGOTIATION]: (
      <ActionDrawerHeader
        title={`Parcela ${orderReference}`}
        subtitle="Renegociar"
        onClose={onClose}
        onBack={showGoBack ? goBackToDefaultContent : undefined}
      />
    ),
    [DrawerState.ADD_DISCOUNTS]: (
      <ActionDrawerHeader
        title={`Parcela ${orderReference}`}
        subtitle="Adicionar desconto"
        onClose={onClose}
        onBack={showGoBack ? goBackToDefaultContent : undefined}
      />
    ),
  }

  return (
    <StyledDrawer anchor="right" variant="persistent" open={isOpen}>
      {drawerHeader[drawerState]}

      <DrawerContent
        drawerState={drawerState}
        selectedReceivableId={selectedReceivableId}
        setDrawerState={setDrawerState}
        setSelectedReceivableId={setSelectedReceivableId}
        removeIsaacOption={removeIsaacOption}
        callbackCheckout={callbackCheckout}
        resetManualLiquidationForm={resetManualLiquidationForm}
        setIsFailureFeedbackOpen={setIsFailureFeedbackOpen}
      />
    </StyledDrawer>
  )
}

export default InstallmentDrawer
