import { Product } from 'src/shared/interfaces'
import { school1, school2 } from './schools'

export const product1: Product = {
  created_at: '2021-01-27T17:29:18.701Z',
  enrollment_amount: 0,
  enrollment_installments_quantity: 0,
  id: 'b6fa7b39-a7fe-4194-96f1-f310d3af1c15',
  installments_quantity: 12,
  monthly_amount: 30000,
  name: 'Judô',
  school_id: school1.id,
}

export const product1WithSchoolData: Product = {
  ...product1,
  school: school1,
}

export const productWithEnrollmentWithoutTemplate: Product = {
  created_at: '2021-01-27T17:29:18.701Z',
  enrollment_amount: 5000,
  enrollment_installments_quantity: 2,
  envelope_template_referral_id: '',
  id: '9a7e447a-ff48-4071-9d45-8f52544b1836',
  installments_quantity: 24,
  monthly_amount: 50000,
  name: 'Quadribol Avançado',
  school_id: school2.id,
}

export const productWithEnrollmentAndTemplate: Product = {
  created_at: '2021-01-27T17:29:18.701Z',
  enrollment_amount: 5000,
  enrollment_installments_quantity: 2,
  envelope_template_referral_id: '00000000-0000-0000-0000-000000000001',
  id: '9a7e447a-ff48-4071-9d45-8f52544b1836',
  installments_quantity: 24,
  monthly_amount: 50000,
  name: 'Produto com marícula e template',
  school_id: school2.id,
}

export const productsList = [
  product1,
  productWithEnrollmentWithoutTemplate,
  productWithEnrollmentAndTemplate,
]
