import { FC } from 'react'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import TableCell from 'src/escolas/components/Table/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded'

type CellValue = any

type TableColumnString = {
  enableSorting?: boolean
  field: string
  headerName?: string
  isComponent?: boolean
  renderCell?: (value: string) => React.ReactElement
  small?: boolean
  valueFormatter?: (value: string) => string
}

type TableColumnGeneric = {
  enableSorting?: boolean
  field: string
  headerName?: string
  isComponent?: boolean
  renderCell: (value: CellValue) => React.ReactElement
  small?: boolean
  valueFormatter?: (value: CellValue) => CellValue
}

export type TableColumn = TableColumnString | TableColumnGeneric
export type TableColumns = TableColumn[]

const StyledTableCell = styled(TableCell)`
  padding: 0px 18px;
  height: 48px;
  white-space: nowrap;
`

const StyledTableSortLabel = styled(TableSortLabel)`
  width: max-content;
  height: 48px;
`

type TableColumnCellProps = {
  ActionComponent?: (row, show) => JSX.Element
  column: TableColumn
  direction?: 'asc' | 'desc'
  orderBy?: string
  sorted?: boolean
  sortingHandler?: (field: string) => void
}

const handleSorting = (column, orderBy, sortingHandler) => {
  if (column.enableSorting) {
    return orderBy === column.field ? sortingHandler('') : sortingHandler(column.field)
  }
}

const TableColumnCell: FC<TableColumnCellProps> = ({
  column,
  sortingHandler,
  orderBy,
  sorted,
  direction,
  ActionComponent,
}) => {
  return (
    <>
      {column.isComponent ? (
        <StyledTableCell>
          <ActionComponent />
        </StyledTableCell>
      ) : (
        <StyledTableCell>
          <StyledTableSortLabel
            active={sorted && column.enableSorting}
            hideSortIcon
            direction={direction || 'asc'}
            IconComponent={column.enableSorting ? ArrowDropDownRoundedIcon : null}
            onClick={() => handleSorting(column, orderBy, sortingHandler)}
          >
            {/* TODO: DS FIX - Component not developed yet */}
            <Typography variant="body2" color="textSecondary" style={{ fontWeight: 500 }}>
              {column.headerName}
            </Typography>
          </StyledTableSortLabel>
        </StyledTableCell>
      )}
    </>
  )
}

export default TableColumnCell
