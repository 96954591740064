import { FC } from 'react'
import styled from 'styled-components'
import { Receivable } from 'src/shared/interfaces'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { typography } from '@material-ui/system'
import { CombinableReceivable } from 'src/escolas/contexts/receivablesAgglutinationContext'
import { formatCentsToReal } from 'src/shared/utils'

const StyledTypography = styled(Typography)`
  ${typography}
`

export type SelectedReceivableAgglutinationProps = {
  baseAmount: cents
  receivable?: Receivable
  receivables: CombinableReceivable[]
}

const SelectedReceivableAgglutination: FC<SelectedReceivableAgglutinationProps> = ({
  receivables,
  baseAmount,
}) => {
  return (
    <>
      <Box pb={1} display="flex" justifyContent="space-between">
        <StyledTypography variant="body2" fontSize="18px">
          Parcelas Selecionadas
        </StyledTypography>
      </Box>
      {receivables.map(({ amount, orderReference }) => (
        <Box
          pb={1}
          paddingTop="10px"
          display="flex"
          justifyContent="space-between"
          key={orderReference}
        >
          <Typography variant="body2" color="textSecondary">
            Parcela {orderReference}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {formatCentsToReal(amount)}
          </Typography>
        </Box>
      ))}
      <Box pt={1} display="flex" justifyContent="space-between" paddingBottom="10px">
        <Typography color="textSecondary">Valor parcial</Typography>
        <Typography color="textSecondary">{formatCentsToReal(baseAmount)}</Typography>
      </Box>
    </>
  )
}

export default SelectedReceivableAgglutination
