import { FC, useState } from 'react'
import { useApi } from 'src/shared/hooks'
import { Button } from '@olaisaac/design-system'
import { InvoiceProviders } from 'src/shared/interfaces'

export type PrintReceiptButtonProps = {
  provider?: InvoiceProviders | string
  receivableId: uuid
}
export const LABEL_BUTTON_GENERATE_RECEIPT = 'Gerar comprovante de pagamento'

const PrintReceiptButton: FC<PrintReceiptButtonProps> = ({ receivableId, provider }) => {
  const { api } = useApi()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleClick = async () => {
    setIsLoading(true)
    const response =
      provider === InvoiceProviders.STONE_CHECKOUT
        ? await api.receivables.printReceiptCheckout(receivableId)
        : await api.receivables.printReceipt(receivableId)
    const file = new Blob([response.data], { type: 'application/pdf' })
    const fileURL = URL.createObjectURL(file)
    const newWindow = window.open(fileURL, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
    setIsLoading(false)
  }

  return (
    <>
      <Button variation="primary" fullWidth onClick={handleClick} loading={isLoading}>
        {LABEL_BUTTON_GENERATE_RECEIPT}
      </Button>
    </>
  )
}

export default PrintReceiptButton
