import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import {
  Contract,
  Installment,
  InstallmentType,
  SignAndPaymentStatus,
  SignAndPaymentStatusLabel,
} from 'src/shared/interfaces'
import { ContractDetailsDrawerActions } from 'src/escolas/components/contract/ContractDetailsDrawer/ContractDetailsDrawerActions'
import { prop, sum } from 'ramda'
import ContractDetailsSummary from './ContractDetailsSummary'
import { ContractDetailsDrawerState } from 'src/escolas/components/contract/ContractDetailsDrawer/constants'
import { DialogContent, Notification } from '@olaisaac/design-system'

export type ContractDetailsDrawerDefaultContentProps = {
  contract: Contract
  contractStatus: string
  isSignable: boolean
  setDrawerState: Dispatch<SetStateAction<ContractDetailsDrawerState>>
}

type ContractInstallmentProps = {
  discounts: number
  installment: number
  installments: Installment[]
  installments_quantity: number
  totalAmount: number
}

export type ContractInstallments = {
  annuity: {
    totalAmount: number
    totalDiscounts: number
    totalWithDiscounts: number
  }
  enrollment: ContractInstallmentProps
  tuition: ContractInstallmentProps
}

const defaultContractInstallments = {
  annuity: {
    totalAmount: 0,
    totalDiscounts: 0,
    totalWithDiscounts: 0,
  },
  enrollment: {
    discounts: 0,
    installment: 0,
    installments: [],
    installments_quantity: 0,
    totalAmount: 0,
  },
  tuition: {
    discounts: 0,
    installment: 0,
    installments: [],
    installments_quantity: 0,
    totalAmount: 0,
  },
}

const ContractDetailsDrawerDefaultContent: FC<ContractDetailsDrawerDefaultContentProps> = ({
  contract,
  contractStatus,
  setDrawerState,
  isSignable,
}) => {
  const [contractInstallments, setContractInstallments] = useState<ContractInstallments>(
    defaultContractInstallments
  )

  const [isEnrolled, setIsEnrolled] = useState(false)
  const [isCanceled, setIsCanceled] = useState(false)
  const [alertMessageContent, setAlertMessageContent] = useState('')

  const getInstallmentsTotalAmount = installments => {
    return sum(installments.map(prop('amount')))
  }

  const getInstallmentsDiscountsTotalAmount = installments => {
    const discounts = installments?.map(prop('receivables'))[0]?.map(prop('discounts'))[0]

    return discounts?.length ? sum(discounts.map(prop('amount'))) : 0
  }

  const getContractInstallmentsDetails = (currentContract: Contract) => {
    const enrollmentInstallments = currentContract?.installments?.filter(({ type }) => {
      return type === InstallmentType.ENROLLMENT
    })

    const tuitionInstallments = contract?.installments?.filter(({ type }) => {
      return type === InstallmentType.TUITION
    })

    const enrollment = {
      discounts: getInstallmentsDiscountsTotalAmount(enrollmentInstallments),
      installment: contract.product.enrollment_amount,
      installments: enrollmentInstallments,
      installments_quantity: enrollmentInstallments?.length,
      totalAmount: getInstallmentsTotalAmount(enrollmentInstallments),
    }

    const tuition = {
      discounts: getInstallmentsDiscountsTotalAmount(tuitionInstallments),
      installment: contract.product.monthly_amount,
      installments: tuitionInstallments,
      installments_quantity: tuitionInstallments?.length,
      totalAmount: getInstallmentsTotalAmount(tuitionInstallments),
    }

    const enrollmentTotalDiscount = enrollment.discounts * enrollment.installments_quantity
    const tuitionTotalDiscount = tuition.discounts * tuition.installments_quantity

    const annuityTotalAmount = enrollment.totalAmount + tuition.totalAmount

    const annuity_with_discounts =
      annuityTotalAmount - (enrollmentTotalDiscount + tuitionTotalDiscount)

    const annuity_total_discounts = enrollmentTotalDiscount + tuitionTotalDiscount

    const annuity = {
      totalWithDiscounts: annuity_with_discounts,
      totalDiscounts: annuity_total_discounts,
      totalAmount: annuityTotalAmount,
    }

    setContractInstallments({
      annuity,
      enrollment,
      tuition,
    })
  }
  useEffect(() => {
    contract && getContractInstallmentsDetails(contract)
  }, [contract])

  const alertMessages = {
    CANCELED: '',
    COMPLETE: '',
    PAYMENT_AND_SIGN_PENDING:
      'Se o contrato foi assinado presencialmente em via impressa, registre a assinatura abaixo.',
    PAYMENT_PENDING: 'Este contrato está aguardando o pagamento da primeira cobrança.',
    SIGN_PENDING:
      'Se o contrato foi assinado presencialmente em via impressa, registre a assinatura abaixo.',
  }

  useEffect(() => {
    if (!contractStatus) return
    setIsEnrolled(contractStatus === SignAndPaymentStatus.COMPLETE)
    setIsCanceled(contractStatus === SignAndPaymentStatus.CANCELED)
    setAlertMessageContent(alertMessages[contractStatus])
  }, [contractStatus])

  return (
    <>
      {isEnrolled && (
        <Notification variation="success" description={SignAndPaymentStatusLabel[contractStatus]} />
      )}
      {isCanceled && <Notification variation="error" description="Inativo" />}
      {!isEnrolled && !isCanceled && alertMessageContent && (
        <Notification
          variation="warning"
          title={SignAndPaymentStatusLabel[contractStatus]}
          description={alertMessageContent}
        />
      )}
      <DialogContent>
        <ContractDetailsSummary contractInstallments={contractInstallments} />
      </DialogContent>
      <ContractDetailsDrawerActions isSignable={isSignable} setDrawerState={setDrawerState} />
    </>
  )
}

export default ContractDetailsDrawerDefaultContent
