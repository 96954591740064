import { last, toLower, toUpper } from 'ramda'

export const prettifyName = (name: string): string => {
  if (!name) {
    return ''
  }
  const splitted = toLower(name).split(' ')
  const pretty = splitted.map(s => `${toUpper(s.charAt(0))}${s.slice(1)}`)
  if (pretty.length === 1) {
    return pretty[0]
  }
  return `${pretty[0]} ${last(pretty)}`
}

export const prettifyFullName = (name: string): string => {
  if (!name) {
    return ''
  }
  const splitted = toLower(name).split(' ')
  const pretty = splitted.map(s => `${toUpper(s.charAt(0))}${s.slice(1)}`)
  if (pretty.length === 1) {
    return pretty[0]
  }
  return pretty.join(' ')
}
