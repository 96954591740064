import Client from '@olaisaac/portal-events'
import config from 'src/config'
import * as FullStory from '@fullstory/browser'

const eventClient = new Client({
  baseURL: config.EVENT.HOST,
  http: null,
})

export const SendFullStoryEvent = (metadata, type) => {
  if (config.FULLSTORY_ORG_ID) {
    return function () {
      FullStory.event(type, {
        ...metadata,
      })
    }
  }
}

const SendEvent = ({
  category,
  correlation_id = '',
  group,
  realm,
  type,
  user_id,
  metadata = null,
}) => {
  if (config.EVENT.ENABLED?.toLowerCase() === 'true') {
    eventClient.services.event.sendEvent({
      category,
      correlation_id,
      group,
      metadata,
      realm,
      time: new Date().toISOString(),
      type,
      user_id,
    })
    return
  }
  console.warn('event api is not enabled')
}

export default SendEvent
