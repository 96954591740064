import { useState, createContext, Dispatch, SetStateAction } from 'react'
export const Breadcrumbs = createContext<{
  breadcrumbs: Breadcrumbs
  setBreadcrumbs: Dispatch<SetStateAction<Breadcrumbs>>
}>(null)

export type Breadcrumbs = Array<{ name: string; path: string }>

export const BreadcrumbsProvider = props => {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumbs>([])
  return (
    <Breadcrumbs.Provider value={{ breadcrumbs, setBreadcrumbs }}>
      {props.children}
    </Breadcrumbs.Provider>
  )
}
