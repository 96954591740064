import { CommonMetadata } from './metadata'
import { Guardian } from './guardian'
import { Installment } from './installment'
import { Product } from './product'
import { Student } from './student'
import { Discount } from './receivable'

export enum ContractStatus {
  CANCELED = 'CANCELED',
  OPEN = 'OPEN',
}

export enum DebtStatus {
  OVERDUE = 'OVERDUE',
  UP_TO_DATE = 'UP_TO_DATE',
  WARNING = 'WARNING',
}

export enum ContractStatusLabel {
  CANCELED = 'Inativo',
  OPEN = 'Ativo',
}

export enum EnrollmentStatus {
  ENROLLED = 'ENROLLED',
  NOT_ENROLLED = 'NOT_ENROLLED',
}

export enum SignAndPaymentStatus {
  CANCELED = 'CANCELED',
  COMPLETE = 'COMPLETE',
  PAYMENT_AND_SIGN_PENDING = 'PAYMENT_AND_SIGN_PENDING',
  PAYMENT_PENDING = 'PAYMENT_PENDING',
  SIGN_PENDING = 'SIGN_PENDING',
}

export enum SignAndPaymentStatusLabel {
  CANCELED = 'Inativo',
  COMPLETE = 'Matriculado',
  PAYMENT_AND_SIGN_PENDING = 'Falta pagar e assinar',
  PAYMENT_PENDING = 'Falta pagar',
  SIGN_PENDING = 'Falta assinar',
}

export type Contract = CommonMetadata & {
  debt_status?: DebtStatus
  discount_description?: string
  due_day: number
  end_month: string
  guardian?: Guardian
  guardian_id: uuid
  installments?: Array<Installment>
  isReenrollable?: boolean
  next_year_contracts: Array<Contract>
  product?: Product
  product_id: uuid
  reference_year: string
  sign_payment_status?: string
  start_month: string
  status: ContractStatus
  student?: Student
  student_id: uuid
  total_amount: cents
}

export type GroupGuardian = {
  debt_status: DebtStatus
  guardian_id: string
  name: string
  next_year_sign_status: SignAndPaymentStatus
  products_names: string[]
  students_names: string[]
  tax_id: string
}

export type Enrollment = {
  enrollment_discounts: Array<Discount>
  enrollment_due_day: number
  enrollment_duration_months: number
  enrollment_start_month: string
  send_first_enrollment_installment: boolean
}

export type CreateContractPayload = Omit<
  Contract,
  'created_at' | 'id' | 'end_month' | 'total_amount' | 'status' | 'next_year_contracts'
> &
  Partial<Enrollment> & {
    discounts: Array<Discount>
    duration_months: number
    previous_year_contract_id: string
    referral?: string
    send_signable_document: boolean
  }

export enum ContractCancellationReason {
  DUPLICATED_CONTRACT = 'DUPLICATED_CONTRACT',
  OTHER = 'OTHER',
  STUDENT_LEFT = 'STUDENT_LEFT',
  WRONG_AMOUNT = 'WRONG_AMOUNT',
  WRONG_DATE = 'WRONG_DATE',
  WRONG_GUARDIAN_INFO = 'WRONG_GUARDIAN_INFO',
}

export enum ContractCreationSteps {
  CHECK_GUARDIAN = 'Verificando responsável',
  CREATE_CONTRACT = 'Criando contrato',
  CREATE_GUARDIAN = 'Criando responsável',
  CREATE_STUDENT = 'Criando aluno',
  FINISHED = 'Concluído',
}

export type ReenrollmentStats = {
  in_progress_count: number
  reenrolled_count: number
  total_open_contracts: number
}

export type CheckDuplicationPayload = {
  duration_months: number
  guardian_name: string
  product_id: uuid
  start_month: string
  student_name: string
}

export type CheckDuplicationResponse = {
  duplicated_contract_id?: uuid
  is_duplicated: boolean
  seconds_elapsed_since_creation?: number
}
