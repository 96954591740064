import React, { FC, useEffect } from 'react'
import styled from 'styled-components'
import { ActionDrawerHeader, Button, ButtonDocker, DialogContent } from '@olaisaac/design-system'
import Box from '@material-ui/core/Box'
import Drawer from '@material-ui/core/Drawer'
import { usePagedDrawer } from 'src/escolas/hooks'

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    width: calc(((100% - 160px) / 12) * 4);
    transition: transform 0.3s cubic-bezier(0.42, 0.29, 0.39, 0.83) !important;
  }
`

export type PagedDrawerProps = {
  handleClickMap: any
  isLoading: boolean
  isNextPageDisabled: boolean
  isOpen: boolean
  onClose: () => void
  pages: React.ReactFragment[]
  title: string
}

const PagedDrawer: FC<PagedDrawerProps> = ({
  isOpen,
  isNextPageDisabled,
  isLoading,
  onClose,
  pages,
  handleClickMap,
  title,
}) => {
  const { currentPage, setCurrentPage } = usePagedDrawer()

  const lastPage = pages.length > 0 ? pages.length - 1 : 0
  const goToNextPage = () => setCurrentPage(currentPage + 1)
  const goToPreviousPage = () => setCurrentPage(currentPage - 1)
  const openPage = pageNumber => (currentPage === pageNumber ? 'block' : 'none')

  useEffect(() => {
    if (isOpen) return
    setCurrentPage(0)
    onClose()
  }, [isOpen])

  return (
    <StyledDrawer anchor="right" variant="persistent" open={isOpen}>
      <ActionDrawerHeader
        title={title}
        onClose={onClose}
        onBack={currentPage > 0 ? goToPreviousPage : undefined}
      />

      <DialogContent>
        {pages?.map((page, index) => (
          <Box key={index} display={openPage(index)}>
            {page}
          </Box>
        ))}
      </DialogContent>

      <ButtonDocker>
        <Button
          type={currentPage !== lastPage ? 'button' : 'submit'}
          onClick={handleClickMap[currentPage] || goToNextPage}
          disabled={isNextPageDisabled}
          loading={isLoading}
          fullWidth
        >
          {currentPage !== lastPage ? 'Próximo' : 'Confirmar'}
        </Button>
      </ButtonDocker>
    </StyledDrawer>
  )
}

export default PagedDrawer
