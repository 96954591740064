import { FC } from 'react'
import styled from 'styled-components'

export type ViewProps = {
  color?: string
  inverted?: boolean
}

const View: FC<ViewProps> = ({ inverted = false, color, children }) => {
  const StyledView = styled.div`
    background-color: ${color === undefined ? (inverted ? '#f5f5f5' : '#3d4ed7') : color};
    width: 100%;
    min-height: 100%;
    height: auto;

    position: absolute;
    padding: ${inverted ? 64 : 95}px 16px 0 16px;

    color: ${inverted ? '#000000' : '#ffffff'};
  `

  return <StyledView> {children} </StyledView>
}

export default View
