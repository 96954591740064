import { keys } from 'ramda'
import { fieldsErrorMapping, defaultAddress as baseAddress } from '../constants'
import { EnrollmentFormFields, GuardianFormType } from '../types'

export const getWrongFieldNames = (errors?): Array<string> => {
  const findError = (errorName: string) => {
    return errors?.data?.errors?.find(item => item?.field === errorName)
  }
  return keys(fieldsErrorMapping)
    .map(k => (errors?.[k] || findError(k) ? fieldsErrorMapping[k] : null))
    .filter(Boolean)
}

export const getDefaultEnrollmentFormFields = (monthlyAmount: number): EnrollmentFormFields => {
  return { discounts: [], monthlyAmount, durationMonths: '1' }
}

export const getDefaultGuardianProps = (location): GuardianFormType => {
  let defaultGuardianName = null
  let defaultGuardianID = null

  let defaultAddress = baseAddress

  if (location.state?.guardian) {
    defaultGuardianName = { key: null, value: location.state.guardian.name }
    defaultAddress = location.state.guardian.address
    defaultGuardianID = location.state.guardian.id
  }

  return {
    address: defaultAddress,
    email: location.state?.guardian?.email ?? '',
    id: defaultGuardianID,
    name: defaultGuardianName,
    phone_number: location.state?.guardian?.phone_number ?? '',
    tax_id: location.state?.guardian?.tax_id ?? '',
  }
}
