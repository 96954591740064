import { Dispatch, FC, SetStateAction, useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import { DialogContent, Notification } from '@olaisaac/design-system'
import { propEq } from 'ramda'
import ReceivableFinancialSummary from 'src/escolas/components/contract/InstallmentDrawer/ReceivableFinancialSummary'
import { Receivable, ReceivableStatuses } from 'src/shared/interfaces'
import { InstallmentDrawerActions } from 'src/escolas/components/contract/InstallmentDrawer/InstallmentDrawerActions'
import { DrawerState } from 'src/escolas/components/contract/InstallmentDrawer/constants'
import AgglutinationHistory from 'src/escolas/components/contract/InstallmentDrawer/AgglutinationHistory'

export type DefaultContentProps = {
  receivables: Array<Receivable>
  selectedReceivableId: uuid
  setDrawerState: Dispatch<SetStateAction<DrawerState>>
}

const DefaultContent: FC<DefaultContentProps> = ({
  receivables,
  selectedReceivableId,
  setDrawerState,
}) => {
  const [isAgglutinated, setIsAgglutinated] = useState<boolean>(false)
  const [receivable, setReceivable] = useState<Receivable>(null)

  useEffect(() => {
    const receivable = receivables?.find(propEq('id', selectedReceivableId))
    if (receivable) setReceivable(receivable)
  }, [selectedReceivableId])

  const isRenegotiated = Boolean(receivable?.original_receivables?.length)

  const ChooseDrawerActions: FC<ChooseDrawerActionsProps> = ({
    receivable,
    selectedReceivableId,
    setDrawerState,
  }) => {
    if ([ReceivableStatuses.OPEN, ReceivableStatuses.PAID].includes(receivable?.status)) {
      return (
        <InstallmentDrawerActions
          selectedReceivableId={selectedReceivableId}
          setDrawerState={setDrawerState}
        />
      )
    }
    return null
  }

  return (
    <>
      {isRenegotiated && (
        <Notification
          description="Essa parcela é resultado de uma renegociação."
          variation="information"
        />
      )}

      <DialogContent>
        <AgglutinationHistory
          selectedReceivableId={selectedReceivableId}
          setIsAgglutinated={setIsAgglutinated}
          setReceivable={setReceivable}
        />
        <Box mt={3}>
          <ReceivableFinancialSummary
            receivable={receivable}
            isAgglutinated={isAgglutinated}
            key={receivable?.id}
          />
        </Box>
      </DialogContent>

      <ChooseDrawerActions
        receivable={receivable}
        selectedReceivableId={selectedReceivableId}
        setDrawerState={setDrawerState}
      />
    </>
  )
}

export type ChooseDrawerActionsProps = {
  receivable: Receivable
  selectedReceivableId: uuid
  setDrawerState: Dispatch<SetStateAction<DrawerState>>
}

export default DefaultContent
