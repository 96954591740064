import { useState, createContext, useRef } from 'react'
import { useApi } from 'src/shared/hooks'
import { School } from 'src/shared/interfaces'

type SchoolContext = {
  clearSchool: () => void
  loadSchoolBySlug: (schoolSlug: string) => void
  school: School
}

export const SchoolContext = createContext<SchoolContext>(null)

export const SchoolProvider = props => {
  const { api } = useApi()
  const [school, setSchool] = useState<School>()
  const getBySlugPromise = useRef(null)
  const loadSchoolBySlug = (schoolSlug: string) => {
    if (schoolSlug && !getBySlugPromise.current) {
      getBySlugPromise.current = api.schools.getBySlug(schoolSlug).then(setSchool)
    }
  }
  const clearSchool = () => setSchool(null)
  return (
    <SchoolContext.Provider value={{ school, loadSchoolBySlug, clearSchool }}>
      {props.children}
    </SchoolContext.Provider>
  )
}
