import React from 'react'
import ReactDOM from 'react-dom'
import envConfig from 'src/config'
import { ThemeProvider } from 'styled-components'
import { StylesProvider, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import CssBaseline from '@material-ui/core/CssBaseline'
import ptBR from 'dayjs/locale/pt-br'
import dayjs from 'dayjs'
import axios from 'axios'
import utc from 'dayjs/plugin/utc'
import DayJsUtils from '@date-io/dayjs'
import theme, { materialTheme } from 'src/shared/theme'
import * as FullStory from '@fullstory/browser'
import { identity } from 'ramda'
import { ApiProvider } from 'src/shared/contexts/apiContext'
import { clearCookies } from 'src/shared/api/sortingHatClient'
import { RESPONSE_STATUS } from 'src/shared/interfaces'

dayjs.extend(utc)
dayjs.locale('pt-br')

if (envConfig.FULLSTORY_ORG_ID) {
  FullStory.init({
    orgId: envConfig.FULLSTORY_ORG_ID,
  })
}

axios.defaults.withCredentials = true
axios.interceptors.response.use(identity, error => {
  if (error.response.status === RESPONSE_STATUS.UNAUTHORIZED) {
    console.error('você não tem acesso para realizar essa ação')
    return clearCookies()
  }
  return error
})

const importBuildTarget = () => {
  if (envConfig.TARGET === 'escolas') {
    return import('./escolas')
  } else if (envConfig.TARGET === 'responsaveis') {
    return import('./responsaveis')
  } else {
    return Promise.reject(new Error(`No such build target: ${envConfig.TARGET}`))
  }
}

importBuildTarget().then(({ default: Environment }) => {
  ReactDOM.render(
    <React.StrictMode>
      <MuiThemeProvider theme={materialTheme}>
        <ThemeProvider theme={theme}>
          <StylesProvider injectFirst>
            <MuiPickersUtilsProvider locale={ptBR} utils={DayJsUtils}>
              <ApiProvider>
                <Environment />
              </ApiProvider>
              <CssBaseline />
            </MuiPickersUtilsProvider>
          </StylesProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
  )
})
