import React, { Dispatch, FC, SetStateAction } from 'react'
import Menu from '@material-ui/core/Menu'
import { MenuItem, Tooltip } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { IconButton } from '@olaisaac/design-system'
export type ContractCheckoutActionsProps = {
  addContract?: Dispatch<SetStateAction<boolean>>
  canEditDiscounts: boolean
  canEditDueDay: () => boolean
  clickPaidAmountStatement: (boolean) => void
  isReenrollable?: boolean
  openDiscountsEdition: () => void
  openDueDayEdition: () => void
  showAnnualPaidAmountsStatement: boolean
  tooltip?: string
}
export const ContractCheckoutActions: FC<ContractCheckoutActionsProps> = ({
  addContract,
  isReenrollable,
  canEditDueDay,
  canEditDiscounts,
  openDiscountsEdition,
  openDueDayEdition,
  clickPaidAmountStatement,
  showAnnualPaidAmountsStatement,
  tooltip,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const close = () => {
    setAnchorEl(null)
  }
  const getHandleClick = callback => () => {
    callback()
    close()
  }

  return (
    <Box>
      <IconButton
        variation="ghost"
        aria-label="mais ações"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu id="menu-imprimir" anchorEl={anchorEl} keepMounted open={open} onClose={close}>
        {showAnnualPaidAmountsStatement && (
          <MenuItem
            onClick={() => clickPaidAmountStatement(true)}
            data-testid="generate-statement-button"
          >
            Gerar demonstrativo de pagamento
          </MenuItem>
        )}
        {canEditDueDay() ? (
          <MenuItem onClick={getHandleClick(openDueDayEdition)} component="a" target="blank">
            Editar vencimentos
          </MenuItem>
        ) : (
          <Tooltip title={tooltip}>
            <Box>
              <MenuItem
                disabled
                onClick={getHandleClick(openDueDayEdition)}
                component="a"
                target="blank"
              >
                Editar vencimentos
              </MenuItem>
            </Box>
          </Tooltip>
        )}

        <MenuItem
          disabled={!canEditDiscounts}
          onClick={getHandleClick(openDiscountsEdition)}
          component="a"
          target="blank"
        >
          Editar descontos de mensalidades
        </MenuItem>
        {isReenrollable && <MenuItem onClick={() => addContract(true)}>Rematricular</MenuItem>}
      </Menu>
    </Box>
  )
}
export default ContractCheckoutActions
