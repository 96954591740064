import { createTheme } from '@material-ui/core/styles'

import {
  brandMaterialTheme,
  brandPrimitiveTokens,
  brandHumanizedTokens,
} from '@olaisaac/design-system'

export const materialTheme = createTheme(brandMaterialTheme)

const theme = {
  ...materialTheme,
  primitiveTokens: brandPrimitiveTokens,
  humanizedTokens: brandHumanizedTokens,
}

export default theme
