import dayjs from 'dayjs'
import { InstallmentChange } from 'src/escolas/components/contract/ContractEditDrawer/ContractEditDueDayDrawer'
import {
  RenegotiationResponseBody,
  Discount,
  Receivable,
  ReceivableStatuses,
} from 'src/shared/interfaces'
import { installment1, installment2, installment3, installment4 } from './installments'
import { invoice1 } from './invoices'

export const discount: Discount = {
  amount: 4000,
  days_before_due_date: 5,
  description: '',
}

export const receivableRenegotiated: Receivable = {
  base_amount: 100000,
  created_at: '2021-01-27T17:29:18.701Z',
  current_additional_discount: 0,
  current_amount: 98000,
  current_discount: 0,
  current_early_payment_discount: 0,
  current_fine: 0,
  current_interest: 0,
  current_perpetual_discount: 0,
  description: 'Renegociado pq o aluno é bonzinho',
  discounts: [],
  due_date: '2021-01-12T16:50:26.393-03:00',
  id: '2b7973c9-d397-4c84-9385-6a7c0847df2e',
  installment: installment1,
  installment_id: installment1.id,
  invoice: invoice1,
  invoice_id: invoice1.id,
  original_amount: 100000,
  status: ReceivableStatuses.RENEGOTIATED,
  type: '',
}

const DIA = 1000 * 60 * 60 * 24

export const receivableOpen: Receivable = {
  base_amount: 80000,
  created_at: '2021-01-27T17:29:18.701Z',
  current_additional_discount: 0,
  current_amount: 78000,
  current_discount: 2000,
  current_early_payment_discount: 2000,
  current_fine: 0,
  current_interest: 0,
  current_perpetual_discount: 0,
  description: '',
  discounts: [],
  due_date: new Date(new Date().getTime() + 3 * DIA).toISOString(),
  id: '11f820ad-6403-41a2-9fd3-eeaff2e8541b',
  installment: installment1,
  installment_id: installment1.id,
  invoice: invoice1,
  invoice_id: invoice1.id,
  original_amount: 80000,
  status: ReceivableStatuses.OPEN,
  type: '',
}

export const receivableOverDue: Receivable = {
  id: '35d9c566-0ac2-422f-b938-b3b25188dd5b',
  created_at: '2022-02-21T08:54:27.640318-03:00',
  updated_at: '2022-02-21T08:54:27.640318-03:00',
  description: 'Gorgeous Rubber Hat 3/7',
  type: '',
  original_amount: 25000,
  status: ReceivableStatuses.OPEN,
  due_date: new Date(new Date().getTime() - 3 * DIA).toISOString(),
  invoice_id: '19fdc56e-1deb-4d32-8db1-ffe29bbe7139',
  installment_id: 'a8898942-0d20-4896-8050-c992d85b261e',
  original_receivables: [],
  discounts: [],
  base_amount: 25000,
  current_amount: 25884,
  current_discount: 0,
  current_perpetual_discount: 0,
  current_early_payment_discount: 0,
  current_due_payment_discount: 0,
  current_additional_discount: 0,
  current_fine: 500,
  current_interest: 384,
  lost_early_payment_discount: 0,
  lost_due_payment_discount: 0,
}

export const receivableAgglutinated1: Receivable = {
  base_amount: 50000,
  created_at: '2021-10-20T13:23:18.097Z',
  current_additional_discount: 0,
  current_amount: 52000,
  current_discount: 0,
  current_early_payment_discount: 0,
  current_fine: 0,
  current_interest: 2000,
  current_perpetual_discount: 0,
  description: '',
  discounts: [],
  due_date: new Date(new Date().getTime() + 3 * DIA).toISOString(),
  id: 'd8cbaef7-1aec-4d46-b950-28b8b5bcbd4d 4a223650-c64f-4d42-8ec3-363c09e66f34',
  installment: installment3,
  installment_id: installment3.id,
  invoice: invoice1,
  invoice_id: invoice1.id,
  original_amount: 50000,
  status: ReceivableStatuses.AGGLUTINATED,
  type: '',
}

export const receivableAgglutinated2: Receivable = {
  base_amount: 50000,
  created_at: '2021-10-20T13:23:18.097Z',
  current_additional_discount: 0,
  current_amount: 50000,
  current_discount: 0,
  current_early_payment_discount: 0,
  current_fine: 0,
  current_interest: 0,
  current_perpetual_discount: 0,
  description: '',
  discounts: [],
  due_date: new Date(new Date().getTime() + 3 * DIA).toISOString(),
  id: '4a223650-c64f-4d42-8ec3-363c09e66f34',
  installment: installment4,
  installment_id: installment4.id,
  invoice: invoice1,
  invoice_id: invoice1.id,
  original_amount: 50000,
  status: ReceivableStatuses.AGGLUTINATED,
  type: '',
}

export const receivableAgglutinatedOpen: Receivable = {
  base_amount: 102000,
  created_at: '2021-10-20T13:23:18.097Z',
  current_additional_discount: 0,
  current_amount: 82000,
  current_discount: 20000,
  current_early_payment_discount: 0,
  current_fine: 0,
  current_interest: 2000,
  current_perpetual_discount: 0,
  description: '',
  discounts: [],
  due_date: new Date(new Date().getTime() + 3 * DIA).toISOString(),
  id: 'b56e569d-48bd-4930-8ab8-0b3c9253bd0c',
  installment: installment4,
  installment_id: installment4.id,
  invoice: invoice1,
  invoice_id: invoice1.id,
  original_amount: 100000,
  original_receivables: [receivableAgglutinated1, receivableAgglutinated2],
  status: ReceivableStatuses.OPEN,
  type: '',
}

export const receivableDueToday: Receivable = {
  ...receivableOpen,
  id: 'c0b884eb-5ef5-45c3-b8fc-14e5587448e2',
  due_date: new Date().toISOString(),
}

export const receivableOpenWasRenegotiated: Receivable = {
  base_amount: 80000,
  created_at: '2021-01-27T17:29:18.701Z',
  current_additional_discount: 0,
  current_amount: 78000,
  current_discount: 2000,
  current_early_payment_discount: 0,
  current_fine: 0,
  current_interest: 0,
  current_perpetual_discount: 0,
  description: '',
  discounts: [],
  due_date: new Date(new Date().getTime() + 3 * DIA).toISOString(),
  id: '2b7273c9-d397-4c84-9385-6a8c0847df2e',
  installment: installment1,
  installment_id: installment1.id,
  invoice: invoice1,
  invoice_id: invoice1.id,
  original_amount: 80000,
  status: ReceivableStatuses.RENEGOTIATED,
  type: '',
}

export const receivableOpenNew: Receivable = {
  base_amount: 1000,
  created_at: '2021-01-27T17:29:18.701Z',
  current_additional_discount: 0,
  current_amount: 800,
  current_discount: 200,
  current_early_payment_discount: 0,
  current_fine: 0,
  current_interest: 0,
  current_perpetual_discount: 0,
  description: '',
  discounts: [],
  due_date: new Date(new Date().getTime() + 3 * DIA).toISOString(),
  id: '2b7273c9-d397-65bd-9385-6a8c0847df2e',
  installment: installment1,
  installment_id: installment1.id,
  invoice: invoice1,
  invoice_id: invoice1.id,
  original_amount: 1000,
  status: ReceivableStatuses.OPEN,
  type: '',
}

export const receivablePaid: Receivable = {
  base_amount: 100000,
  created_at: '2021-01-27T17:29:18.701Z',
  current_additional_discount: 0,
  current_amount: 98000,
  current_discount: 2000,
  current_early_payment_discount: 2000,
  current_fine: 0,
  current_interest: 0,
  current_perpetual_discount: 0,
  description: '',
  discounts: [],
  due_date: '2021-02-02T16:50:26.393-03:00',
  id: '90201f7d-55ec-4553-8e2b-b630b33fb0d6',
  installment: installment2,
  installment_id: installment2.id,
  invoice: invoice1,
  invoice_id: invoice1.id,
  original_amount: 100000,
  status: ReceivableStatuses.PAID,
  total_amount_paid: 98000,
  type: '',
}

export const receivableCanceled: Receivable = {
  base_amount: 100000,
  created_at: '2021-01-27T17:29:18.701Z',
  current_additional_discount: 0,
  current_amount: 98000,
  current_discount: 2000,
  current_early_payment_discount: 2000,
  current_fine: 0,
  current_interest: 0,
  current_perpetual_discount: 0,
  description: '',
  discounts: [],
  due_date: '2021-02-02T16:50:26.393-03:00',
  id: '90201f7d-55ec-4553-8e2b-b630b33fb0d6',
  installment: installment2,
  installment_id: installment2.id,
  invoice: invoice1,
  invoice_id: invoice1.id,
  original_amount: 100000,
  status: ReceivableStatuses.CANCELED,
  total_amount_paid: 98000,
  type: '',
}

export const receivableOpenWithFineAndInterest: Receivable = {
  base_amount: 160000,
  created_at: '2021-01-27T17:29:18.701Z',
  current_additional_discount: 0,
  current_amount: 159253,
  current_discount: 5000,
  current_early_payment_discount: 1000,
  current_fine: 3200,
  current_interest: 53,
  current_perpetual_discount: 4000,
  description: '',
  discounts: [],
  due_date: new Date(new Date().getTime() - 1 * DIA).toISOString(),
  id: '2b7273c9-d397-4c84-9385-6a8c0847df2e',
  installment: installment1,
  installment_id: installment1.id,
  invoice: invoice1,
  invoice_id: invoice1.id,
  original_amount: 160000,
  status: ReceivableStatuses.OPEN,
  type: '',
}

export const receivableWithDiscounts: Receivable = {
  base_amount: 160000,
  created_at: '2021-01-27T17:29:18.701Z',
  current_additional_discount: 0,
  current_amount: 159253,
  current_discount: 4000,
  current_early_payment_discount: 0,
  current_fine: 3200,
  current_interest: 53,
  current_perpetual_discount: 0,
  description: '',
  discounts: [discount],
  due_date: new Date(new Date().getTime() - 1 * DIA).toISOString(),
  id: '2b7273c9-d397-4c84-9385-6a8c0847df2e',
  installment: installment1,
  installment_id: installment1.id,
  invoice: invoice1,
  invoice_id: invoice1.id,
  original_amount: 160000,
  status: ReceivableStatuses.OPEN,
  type: '',
}
export const installmentChange1: InstallmentChange = {
  current_amount: 100000,
  due_month: '2021-12-02T00:00:00Z',
  id: installment1?.id,
  index: 2,
  newDueDate: dayjs(),
  newValue: 100000,
  orderReference: '3 de 13',
  originalDueDate: dayjs(),
  originalValue: 100000,
  receivable: receivableOpen,
}

export const receivableOriginatedFromRenegotiation: Receivable = {
  ...receivableOpen,
  original_receivables: [receivableRenegotiated],
}

export const LiquidationResponse = [receivablePaid]

export const renegotiationResp: RenegotiationResponseBody = {
  renegotiated: receivableOpenWasRenegotiated,
  created: [receivableOpenNew],
}

export const agglutinationResp: any = {
  renegotiated: receivableOpenWasRenegotiated,
  created: [receivableOpenNew],
}
export const receivables: Array<Receivable> = [receivableRenegotiated, receivableOpen]

export const receivablesPaid: Array<Receivable> = [receivablePaid]
