import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { Typography, IconButton, Button, TextField } from '@olaisaac/design-system'
import Box from '@material-ui/core/Box'
import MenuItem from '@material-ui/core/MenuItem'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'

import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import { dissoc, drop, map, prop, propEq, times } from 'ramda'
import { Discount } from 'src/shared/interfaces'
import { useContract, useNavigation, usePagedDrawer, useSnackbar } from 'src/escolas/hooks'
import { useApi, useJWT } from 'src/shared/hooks'
import {
  date2PTFormat,
  formatCentsToReal,
  formatToMonthYear,
  formatNumberToTwoFractionDigits,
  leftZeroPadding,
  validateEditReason,
} from 'src/shared/utils'
import PagedDrawer from 'src/escolas/components/PagedDrawer'
import { FailureFeedbackDialog } from 'src/escolas/components/modal/ConfirmationDialog'
import dayjs from 'dayjs'
import { InstallmentChange } from './ContractEditDueDayDrawer'
import DiscountFormFields, { discountsOptions } from '../../DiscountFormFields'
import { StyledButton } from 'src/responsaveis/components/ReceivableAccordion/ReceivableAccordion'
import theme from 'src/shared/theme'
import { AddDiscountFormType } from '../InstallmentDrawer/AddDiscountContent'
import { Paper } from '@material-ui/core'
import { parseUpdatedInstallments } from './utils'
import { FeatureFlags, useFeatureFlag } from 'src/shared/hooks/useFeatureFlag'

const StyledPaper = styled(Paper)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  padding: ${({ theme }) => theme.spacing(2)}px;
  position: relative;
  display: flex;
  flex-direction: column;
`

const StyledTypography = styled(Typography)`
  display: inline;
`

const StyledBodyLargeTypography = styled(Typography).attrs({
  variation: 'bodyLarge',
  withoutMargin: true,
})``

type ContractEditDiscountsForm = {
  discounts: AddDiscountFormType[]
  edit_reason: string
  installment_id: uuid
}

export type ContractEditDiscountsDrawerProps = {
  availableInstallments: InstallmentChange[]
  callbackEdits?: Dispatch<SetStateAction<boolean>>
  isOpen: boolean
  onClose: () => void
}

const ContractEditDiscountsDrawer: FC<ContractEditDiscountsDrawerProps> = ({
  availableInstallments,
  isOpen,
  onClose,
  callbackEdits,
}) => {
  const { api } = useApi()
  const { contract } = useContract()
  const form = useForm<ContractEditDiscountsForm>({
    mode: 'all',
    defaultValues: {
      installment_id: '',
      edit_reason: '',
    },
  })
  const {
    control,
    handleSubmit,
    watch,
    getValues,
    reset,
    trigger,
    formState: { isSubmitting, isValid, isDirty },
  } = form

  watch(['installment_id', 'discounts', 'edit_reason'])
  const { installment_id, discounts, edit_reason } = getValues()

  const { currentPage, setCurrentPage } = usePagedDrawer()
  const [installmentChanges, setInstallmentChanges] = useState<InstallmentChange[]>()
  const [isLoading, setIsLoading] = useState<boolean>()
  const [isNextPageDisabled, setIsNextPageDisabled] = useState(true)
  const [showFailureDialog, setShowFailureDialog] = useState(false)
  const { setContract } = useContract()
  const { isAdmin } = useJWT()
  const { schoolId } = useNavigation()

  const [discountsToAppendQueue, setDiscountsToAppendQueue] = useState<Discount[]>([])
  const [appendEmptyDiscount, setAppendEmptyDiscount] = useState(false)

  const { setMessage: setSnackbarMessage, setIsOpen: setSnackbarIsOpen } = useSnackbar()

  const { fields: discountFields, append, remove } = useFieldArray({
    control,
    name: 'discounts',
  })

  const { value: featureFlagValue, config } = useFeatureFlag(
    FeatureFlags.PILOT_SCHOOLS_FEATURE_FLAG
  )

  const isFeatureFlagEnabled = !isAdmin && featureFlagValue && config.includes(schoolId)

  const submitHandler = async () => {
    return await api.contracts
      .bulkEditDiscountsCreate(
        contract?.id,
        {
          installment_id,
          ...(isFeatureFlagEnabled && { edit_reason }),
          discounts: map<AddDiscountFormType, Discount>(dissoc('isFinished'), discounts),
        },
        schoolId
      )
      .then(contractData => {
        setContract(contractData)
        setSnackbarMessage('Descontos alterados com sucesso')
        setSnackbarIsOpen(true)
        onClose()
        if (callbackEdits) {
          callbackEdits(true)
        }
      })
      .catch(() => {
        setShowFailureDialog(true)
      })
  }

  const getInfo = () => {
    if (isLoading) return
    setIsLoading(true)

    api.contracts
      .bulkEditDiscountsInfo(contract?.id, {
        installment_id,
        discounts: map<AddDiscountFormType, Discount>(dissoc('isFinished'), discounts),
      })
      .then(data => {
        setInstallmentChanges(parseUpdatedInstallments(data))
        setCurrentPage(currentPage + 1)
      })
      .finally(() => setIsLoading(false))
  }

  const selectedInstallment = availableInstallments?.find(propEq('id', installment_id))
  const orderReference = selectedInstallment?.orderReference
  const receivable = selectedInstallment?.receivable

  const validateSelectInstallmentStep = () => {
    isFeatureFlagEnabled
      ? setIsNextPageDisabled(!installment_id || !validateEditReason(edit_reason))
      : setIsNextPageDisabled(!installment_id)
  }

  const setProgressButton = () => {
    if (currentPage === 0) {
      validateSelectInstallmentStep()
    } else if (currentPage === 1) {
      setIsNextPageDisabled(!isValid || !isDirty)
    }
  }

  useEffect(setProgressButton, [currentPage, installment_id, discounts, edit_reason])

  const appendDiscount = (discount: Discount) => {
    append({
      amount: discount?.amount,
      days_before_due_date: discount?.days_before_due_date,
      description: discount?.description,
      isFinished: false,
    })
  }

  const appendNewDiscount = () => {
    appendDiscount({
      amount: null,
      days_before_due_date: null,
      description: '',
    })
  }

  useEffect(() => {
    const allDiscounts = discountFields?.map((value, index) => index)
    remove(allDiscounts)
    if (!receivable?.discounts) return

    if (receivable?.discounts?.length === 0) return setAppendEmptyDiscount(true)

    setDiscountsToAppendQueue(receivable?.discounts)
  }, [receivable?.discounts])

  useEffect(() => {
    if (!discountsToAppendQueue.length) {
      times(index => trigger(`discounts.${index}.amount`), discountFields.length)
      return
    }
    appendDiscount(discountsToAppendQueue[0])
    setDiscountsToAppendQueue(drop(1, discountsToAppendQueue))
  }, [discountsToAppendQueue])

  useEffect(() => {
    if (!appendEmptyDiscount) return

    appendNewDiscount()
    setAppendEmptyDiscount(false)
  }, [appendEmptyDiscount])

  const page2 = (
    <React.Fragment key={1}>
      <Box mb={2.5}>
        <Typography variation="subtitleDesktopLarge">
          A edição dos descontos deverá ser feito a partir de qual parcela?
        </Typography>
      </Box>
      <Box mb={2.5}>
        <Typography color="secondary">
          Aplicável apenas para parcelas de mensalidades, a vencer e não renegociadas.
        </Typography>
      </Box>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="installment_id">Número da parcela</InputLabel>
        <Controller
          rules={{ required: true }}
          control={control}
          name="installment_id"
          defaultValue=""
          render={({ field: { value, onChange } }) => (
            <Select
              labelId="installment_id"
              label="Número da parcela"
              value={value}
              onChange={e => onChange(e.target.value)}
              fullWidth
            >
              {availableInstallments?.map(({ id, index, current_amount, due_month }) => (
                <MenuItem key={id} value={id}>
                  {`${index + 1}. ${formatToMonthYear(due_month)} - ${formatCentsToReal(
                    current_amount
                  )}`}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
      {installment_id && (
        <Box mt={4}>
          <StyledBodyLargeTypography>
            A edição será feita a partir da seguinte parcela:
          </StyledBodyLargeTypography>
          <Box mt={2.5} display="flex" justifyContent="space-between">
            <StyledBodyLargeTypography color="secondary">Parcela</StyledBodyLargeTypography>
            <StyledBodyLargeTypography color="secondary">
              {orderReference}
            </StyledBodyLargeTypography>
          </Box>
          <Box mt={1} display="flex" justifyContent="space-between">
            <StyledBodyLargeTypography color="secondary">
              Valor da parcela
            </StyledBodyLargeTypography>
            <StyledBodyLargeTypography color="secondary">
              {formatCentsToReal(receivable?.current_amount)}
            </StyledBodyLargeTypography>
          </Box>
          <Box mt={1} display="flex" justifyContent="space-between">
            <StyledBodyLargeTypography color="secondary">Vencimento</StyledBodyLargeTypography>
            <StyledBodyLargeTypography color="secondary">
              {date2PTFormat(dayjs(receivable?.due_date).toISOString())}
            </StyledBodyLargeTypography>
          </Box>
        </Box>
      )}
      {isFeatureFlagEnabled ? (
        <>
          <Box mt={4}>
            <Typography variation="subtitleDesktopLarge">
              Qual é o motivo da edição de desconto?
            </Typography>
          </Box>
          <Box mt={2}>
            <FormControl fullWidth variant="outlined">
              <Controller
                rules={{
                  required: true,
                  maxLength: 100,
                  minLength: 5,
                  validate: validateEditReason,
                }}
                name="edit_reason"
                control={control}
                render={({ field: { value, ...rest }, fieldState: { error } }) => (
                  <TextField
                    {...rest}
                    label="Motivo"
                    id="edit_reason-input"
                    value={value}
                    error={Boolean(error)}
                    helperText={
                      error ? 'Deve ter entre 5 e 100 caracteres (somente letras e números)' : ''
                    }
                  />
                )}
              />
            </FormControl>
          </Box>
        </>
      ) : null}
    </React.Fragment>
  )

  const page3 = (
    <React.Fragment key={2}>
      <Box mt={2} mb={2}>
        <StyledTypography variation="bodyLarge" color="secondary">
          Você pode adicionar
        </StyledTypography>
        <StyledTypography variation="bodyLarge"> até 3 descontos </StyledTypography>
        <StyledTypography variation="bodyLarge" color="secondary">
          nesta parcela.
        </StyledTypography>
      </Box>
      {discountFields.map((data, index) => {
        const [amount, days_before_due_date, isFinished] = watch([
          `discounts.${index}.amount` as const,
          `discounts.${index}.days_before_due_date` as const,
          `discounts.${index}.isFinished` as const,
        ])
        const daysBeforeDueDateOptionDescription = discountsOptions.find(
          propEq('value', days_before_due_date)
        )?.name
        const isValidAmount = amount && amount <= receivable?.original_amount && amount > 0
        const isValidDaysBeforeDueDate = discountsOptions
          .map(prop('value'))
          .includes(days_before_due_date)
        const isFinishButtonDisabled = !isValidAmount || !isValidDaysBeforeDueDate
        const percentage = formatNumberToTwoFractionDigits(
          (amount / receivable?.original_amount) * 100
        )
        return (
          <StyledPaper key={data.id} theme={theme}>
            <Box mb={2}>
              <Typography variation="caption" color="secondary">
                Desconto nº {leftZeroPadding(index + 1, 2)}
              </Typography>
              <Box position="absolute" top={14} right={16}>
                <IconButton
                  variation="ghost"
                  size="small"
                  aria-label="remover desconto"
                  onClick={() => remove(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>
            {isFinished && (
              <>
                <Box>
                  <StyledTypography color="secondary">Valor: </StyledTypography>
                  <StyledTypography>{formatCentsToReal(amount)}</StyledTypography>
                  <StyledTypography> - {percentage}</StyledTypography>
                  <StyledTypography color="secondary">%</StyledTypography>
                </Box>
                <Box>
                  {days_before_due_date > 0 && (
                    <Typography color="secondary">Válido até </Typography>
                  )}
                  <Typography color="secondary">{daysBeforeDueDateOptionDescription}</Typography>
                </Box>
              </>
            )}
            <Box display={isFinished ? 'none' : 'block'}>
              <DiscountFormFields
                form={form}
                amountDefaultValue={data.amount}
                amountFieldName={`discounts.${index}.amount`}
                daysBeforeDueDateDefaultValue={data.days_before_due_date}
                daysBeforeDueDateFieldName={`discounts.${index}.days_before_due_date`}
                descriptionDefaultValue={data.description}
                descriptionFieldName={`discounts.${index}.description`}
                totalAmountPaid={receivable?.original_amount}
              />
            </Box>
            <Controller
              rules={{ required: true, validate: Boolean }}
              control={control}
              name={`discounts.${index}.isFinished` as const}
              render={({ field: { onChange, value } }) => (
                <>
                  <Box
                    position="absolute"
                    top={14}
                    right={56}
                    display={isFinished ? 'block' : 'none'}
                  >
                    <IconButton
                      variation="ghost"
                      size="small"
                      aria-label="editar desconto"
                      onClick={() => onChange(!value)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Box>
                  <StyledButton
                    style={{ display: isFinished ? 'none' : 'block' }}
                    variation="ghost"
                    fullWidth
                    onClick={() => onChange(!value)}
                    disabled={isFinishButtonDisabled}
                  >
                    Salvar desconto
                  </StyledButton>
                </>
              )}
            />
          </StyledPaper>
        )
      })}

      <Button
        variation="ghost"
        startIcon={<AddIcon />}
        fullWidth
        onClick={appendNewDiscount}
        disabled={discountFields.length >= 3}
      >
        Novo desconto
      </Button>
    </React.Fragment>
  )
  const page4 = (
    <React.Fragment key={3}>
      <Box mb={3}>
        <Typography variation="subtitleDesktopLarge">
          Confirme as alterações de descontos:
        </Typography>
      </Box>
      {installmentChanges?.map(({ orderReference, newValue, originalValue }, index) => (
        <Box key={index}>
          <Box mt={3} display="flex" justifyContent="space-between">
            <StyledBodyLargeTypography>Parcela</StyledBodyLargeTypography>
            <StyledBodyLargeTypography>{orderReference}</StyledBodyLargeTypography>
          </Box>
          <Box mt={1.5} display="flex" justifyContent="space-between">
            <StyledBodyLargeTypography color="secondary">
              Valor com descontos originais
            </StyledBodyLargeTypography>
            <StyledBodyLargeTypography color="secondary">
              {formatCentsToReal(originalValue)}
            </StyledBodyLargeTypography>
          </Box>
          <Box mt={1.5} display="flex" justifyContent="space-between">
            <StyledBodyLargeTypography color="secondary">
              Valor com novos descontos
            </StyledBodyLargeTypography>
            <StyledBodyLargeTypography color="secondary">
              {formatCentsToReal(newValue)}
            </StyledBodyLargeTypography>
          </Box>
        </Box>
      ))}
    </React.Fragment>
  )
  const pages: React.ReactFragment[] = [page2, page3, page4]

  const handleClickMap = {
    2: handleSubmit(submitHandler),
    1: getInfo,
  }
  return (
    <>
      <PagedDrawer
        isLoading={isLoading || isSubmitting}
        isNextPageDisabled={isNextPageDisabled}
        pages={pages}
        handleClickMap={handleClickMap}
        isOpen={isOpen}
        onClose={() => {
          onClose()
          reset()
        }}
        title="Editar descontos"
      />
      <FailureFeedbackDialog
        buttonLabel="Voltar"
        isVisible={showFailureDialog}
        onClose={() => setShowFailureDialog(false)}
        submitHandler={() => setShowFailureDialog(false)}
        centered
      />
    </>
  )
}

export default ContractEditDiscountsDrawer
