import Box from '@material-ui/core/Box'
import {
  Button,
  ButtonGroup,
  Pagination,
  usePagination,
  Typography,
  Table,
  TableProps,
  Grid,
} from '@olaisaac/design-system'
import AddIcon from '@material-ui/icons/Add'
import { FC, SetStateAction, useEffect, useState } from 'react'
import { TableFilterParam } from 'src/escolas/components/TableFilter'
import {
  Contract,
  ContractStatus,
  // DebtStatus,
  Pagination as IPagination,
  SignAndPaymentStatus,
} from 'src/shared/interfaces'
import styled from 'styled-components'
import { useDidUpdateEffect } from 'src/escolas/hooks'
import { formatCPF, monthsToContractValidity, paramsToQueryString } from 'src/shared/utils'
import { assoc, mapObjIndexed } from 'ramda'
import { TableColumns } from 'src/escolas/components/Table/TableColumnCell'
import { renderDebtStatus } from 'src/escolas/components/contract/ContractDetails'
import { renderEnrollmentStatus } from 'src/escolas/components/EnrollmentStatusBadge/EnrollmentStatusBadge'
import { CSVLink } from 'react-csv'
import { useApi } from 'src/shared/hooks'
import { ContractFilters } from 'src/escolas/router/[schoolSlug]/contratos'
import { prettifyFullName } from 'src/shared/utils/namePrettifiers'
import {
  EnrollmentEventType,
  useSendEnrollmentEvent,
} from 'src/escolas/router/[schoolSlug]/contratos/enrollmentEvents'
import { PageWithTableControlBar } from 'src/escolas/components/layout/PageWithTableControlBar'
import { PageTableControlBar } from 'src/escolas/components/layout/PageTableControlBar'
import SearchByStudentOrGuardian from '../../SearchByStudentOrGuardian'
import { StatusSearch } from 'src/escolas/hooks/useSearchByStudentOrGuardian'
import TableFilterCheckout from '../../TableFilterCheckout'
import { TableFilterOptionsGeneric, ValueFilter } from '../../TableFilterCheckout/types'
import { groupNameStudents } from 'src/shared/utils/groupInfo'
import ContractYearPopover from '../ContractYearPopover'

const StyledButton = styled(Button)`
  width: 220px;
  margin-left: 8px;
`
const StyledButtonOutlined = styled(Button)`
  width: 220px;
  padding: 6px 16px;
  border: 1px solid ${({ theme }) => theme.palette.grey[400]};
`

const StyledCSVLink = styled(CSVLink)`
  text-decoration: none;
`
export enum SEARCH_BY {
  GUARDIAN = 'GUARDIAN',
  STUDENT = 'STUDENT',
}

type Contracts2022Props = {
  addContract: (referenceYear: string) => void
  filterOptions: Array<TableFilterOptionsGeneric>
  filters: ContractFilters
  handleRowClick: (obj: any, isGuardian?: boolean) => void
  keyprop?: string
  name: string
  schoolId: string
  search: (statusSearch: StatusSearch) => void
  setFilters: (value: SetStateAction<ContractFilters>) => void
  setName: (name: string) => void
}
const filterToParams = (filters: Record<string, TableFilterParam<any>>) =>
  mapObjIndexed(value => value?.value, filters)

const Contracts2022Checkout: FC<Contracts2022Props> = ({
  keyprop,
  schoolId,
  handleRowClick,
  filters,
  filterOptions,
  addContract,
  setFilters,
  name,
  setName,
  search,
}) => {
  const { api } = useApi()
  const [dataSource, setDataSource] = useState<Array<any>>([])
  const [total, setTotal] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(false)
  const [orderBy, setOrderBy] = useState<string>('')
  const sendEnrollmentEvent = useSendEnrollmentEvent()
  const {
    page,
    setPage,
    itensPerPage,
    setItensPerPage,
    initialItensPerPageOptions,
  } = usePagination()

  const formatData = data => {
    return data.map(
      ({
        id,
        guardian_id,
        start_month,
        end_month,
        student,
        product,
        status,
        debt_status,
        guardian,
        sign_payment_status,
      }: Contract) => {
        return {
          debt_status: debt_status,
          enrollmentStatus:
            status === ContractStatus.OPEN
              ? SignAndPaymentStatus[sign_payment_status]
              : ContractStatus.CANCELED,
          guardian: prettifyFullName(guardian.name),
          id,
          guardian_id,
          key: id,
          product: product.name,
          student: prettifyFullName(student.name),
          validity: monthsToContractValidity({ start_month, end_month }),
        }
      }
    )
  }

  const formatSearchByGuardian = data =>
    data.map(
      ({
        name,
        students_names,
        products_names,
        tax_id,
        debt_status,
        next_year_sign_status,
        guardian_id,
      }) => {
        return {
          id: guardian_id,
          name: prettifyFullName(name),
          tax_id: formatCPF(tax_id),
          students: groupNameStudents(
            Object.keys(students_names).map(value => students_names[value]),
            30
          ),
          key: guardian_id,
          products: Object.keys(products_names)?.length,
          status: debt_status,
          nextYearStatus: next_year_sign_status,
        }
      }
    )

  const query = (p: IPagination) =>
    api.contracts
      .getListCheckout({
        ...p,
        ...(filters.status?.value && { status: filters.status.value }),
        ...(filters.name?.isGuardian && filters.name?.isCpf
          ? { tax_id: filters.name.value }
          : { name: filters.name.value }),
        reference_year: '2022',
        school_id: schoolId,
        ...(filters.name?.isGuardian
          ? { search_by: SEARCH_BY.GUARDIAN }
          : { search_by: SEARCH_BY.STUDENT }),
      })
      .then(({ data, pagination }) => {
        return {
          data: filters.name?.isGuardian ? formatSearchByGuardian(data) : formatData(data),
          pagination,
        }
      })

  const qs = paramsToQueryString(filterToParams(filters))
  const searchData = () => {
    setIsLoading(true)
    query({ page: page, per_page: itensPerPage })
      .then(({ data, pagination }) => {
        pagination.total && setTotal(pagination.total)

        setDataSource(data)
      })
      .finally(() => setIsLoading(false))
  }

  useEffect(searchData, [])

  useDidUpdateEffect(() => {
    searchData()
  }, [qs, page, itensPerPage, orderBy, filters.name?.isGuardian])
  // eslint-disable-next-line no-constant-condition
  const source = keyprop ? dataSource.map(r => assoc('key', r[keyprop], r)) : dataSource

  const contractsTableColumns: TableColumns = [
    { headerName: 'Aluno', field: 'student', enableSorting: true },
    { headerName: 'Responsável', field: 'guardian', enableSorting: true },
    { headerName: 'Produto', field: 'product' },
    {
      field: 'debt_status',
      headerName: 'Situação',
      renderCell: value => renderDebtStatus(value),
    },
    {
      enableSorting: true,
      field: 'enrollmentStatus',
      headerName: 'Matrícula 2022',
      renderCell: value => renderEnrollmentStatus(value),
      small: true,
    },
  ]

  const contractsTableColumnsByGuardian: TableColumns = [
    { headerName: 'Responsável', field: 'name', enableSorting: true },
    { headerName: 'CPF', field: 'tax_id', enableSorting: true },
    { headerName: 'Aluno', field: 'students' },
    {
      headerName: 'Produto',
      field: 'products',
    },
    {
      headerName: 'Status',
      field: 'status',
      renderCell: value => renderDebtStatus(value),
    },
    {
      enableSorting: true,
      headerName: 'Matrícula 2022',
      field: 'nextYearStatus',
      renderCell: value => renderEnrollmentStatus(value),
    },
  ]

  const getStatusFromFilter = (values: Array<ValueFilter>) => {
    if (!values.length) return setFilters(assoc('status', { value: null }, filters))
    const listValueStatus = values.filter(value => value.group === 'status')
    if (listValueStatus.length === 1) {
      setFilters(assoc('status', { value: listValueStatus[0].value }, filters))
      return
    }
    setFilters(assoc('status', { value: null }, filters))
  }

  const defaultProps: TableProps<Contract> = {
    columns: contractsTableColumns,
    isLoading,
    orderBy: orderBy,
    rows: source,
    sorted: true,
    sortingHandler: value => setOrderBy(value),
  }

  const defaultPropsGuardian: TableProps<Contract> = {
    columns: contractsTableColumnsByGuardian,
    isLoading,
    orderBy: orderBy,
    rows: source,
    sorted: true,
    sortingHandler: value => setOrderBy(value),
  }

  const propsWithOnClick = assoc(
    'onRowClick',
    params => {
      const { guardian_id, product, student } = params.row
      const obj = {
        id: guardian_id,
        product,
        student,
      }
      handleRowClick(obj)
    },
    defaultProps
  )
  const propsWithOnClickGuardian = assoc(
    'onRowClick',
    params => handleRowClick(params.row, true),
    defaultPropsGuardian
  )

  return (
    <PageWithTableControlBar>
      <Grid item xs={4} style={{ marginTop: 0, marginBottom: 24 }}>
        <Box display="flex" justifyContent="space-between" mx="auto" alignItems="center">
          <Box display="flex" justifyContent="left" alignItems="center">
            <Typography variation="headlineDesktopMedium" color="primary" style={{ margin: 0 }}>
              Contratos 2022
            </Typography>
            <ContractYearPopover />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={4} style={{ paddingTop: 0, paddingBottom: 0 }}>
        <Grid container alignItems="center">
          <Grid item sm={11}>
            <SearchByStudentOrGuardian
              handleChange={name => setName(name)}
              search={(value: StatusSearch) => {
                search(value)
                setPage(1)
              }}
              searchValue={name}
              onClick={() => sendEnrollmentEvent(EnrollmentEventType.contracts2022Searched)}
            />
          </Grid>

          {!filters?.name?.isGuardian ? (
            <Grid item sm={1} style={{ textAlign: 'right' }}>
              <TableFilterCheckout
                filterOptions={filterOptions}
                callbackFilters={getStatusFromFilter}
                filterEventHandler={() =>
                  sendEnrollmentEvent(EnrollmentEventType.contracts2022Filtered)
                }
                maxListCharacter={45}
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>

      {filters?.name?.isGuardian ? (
        <Grid item xs={4} style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Table<Contract> {...propsWithOnClickGuardian} />
        </Grid>
      ) : (
        <Grid item xs={4} style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Table<Contract> {...propsWithOnClick} />
        </Grid>
      )}

      <PageTableControlBar>
        <Pagination
          currentPage={page}
          itensPerPage={itensPerPage}
          itensPerPageOptions={initialItensPerPageOptions}
          totalItens={total}
          onPageChange={newPage => {
            setPage(newPage)
          }}
          onItensPerChangeChange={newItensPerPage => {
            setItensPerPage(newItensPerPage)
          }}
        />

        <ButtonGroup>
          {!filters?.name?.isGuardian ? (
            <StyledCSVLink
              filename="contratos2022.csv"
              data={source}
              headers={contractsTableColumns.map(({ headerName, field }) => {
                return {
                  label: headerName,
                  key: field,
                }
              })}
              onClick={() => sendEnrollmentEvent(EnrollmentEventType.downloadCsv2022Clicked)}
            >
              <StyledButtonOutlined variation="ghost">Baixar tabela .csv</StyledButtonOutlined>
            </StyledCSVLink>
          ) : null}
          <StyledButton
            variation="primary"
            startIcon={<AddIcon />}
            onClick={() => addContract('2022')}
          >
            Adicionar contrato
          </StyledButton>
        </ButtonGroup>
      </PageTableControlBar>
    </PageWithTableControlBar>
  )
}

export default Contracts2022Checkout
