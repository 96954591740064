import { Address, Guardian } from 'src/shared/interfaces'

export const address1: Address = {
  additional_information: 'AP 203',
  city: 'Brasília',
  created_at: '2021-01-27T17:29:18.701Z',
  id: '57432b18-6d30-465d-92ea-7a4de63c463a',
  number: '308',
  state_code: 'DF',
  street: 'SQS 308 BLOCO C',
  zip: '70390100',
}

export const guardian1: Guardian = {
  address: address1,
  address_id: address1.id,
  created_at: '2021-01-27T17:29:18.701Z',
  email: 'jjohnson@gmail.com',
  id: '57432b19-6d30-465d-92ea-7a4de63c463a',
  name: 'Jack Johnson',
  phone_number: '61997776573',
  tax_id: '54564072137',
}

export const address2: Address = {
  additional_information: 'AP 84',
  city: 'São Paulo',
  created_at: '2021-01-27T17:29:18.701Z',
  id: '57432b18-6d30-465d-92ea-7a4de63c463a',
  number: '502',
  state_code: 'SP',
  street: 'Rua Auguxta 1111',
  zip: '20102391',
}

export const guardian2: Guardian = {
  address: address2,
  address_id: address2.id,
  created_at: '2021-01-27T17:29:18.701Z',
  email: 'jamiroquai@gmail.com',
  id: '57432b19-9999-465d-92ea-7a4de63c463a',
  name: 'Responsável',
  phone_number: '12919219090',
  tax_id: '63696379042',
}

export const guardiansList = [guardian1, guardian2]
