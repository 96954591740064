import { FC } from 'react'
import { Redirect } from 'react-router-dom'
import sortingHatClient from 'src/shared/api/sortingHatClient'
import config from 'src/config'
import Login from 'src/responsaveis/router/login'
import LoginIAM from 'src/responsaveis/router/login-iam'
import { useJWT } from 'src/shared/hooks'

export const isAuth = () => {
  try {
    return sortingHatClient.services.token.Get({ key: 'props' })
  } catch (err) {
    return false
  }
}

type UseLoginParams = {
  forceLogin?: boolean
  redirectOnSuccess?: string
}

const useLogin = ({ redirectOnSuccess, forceLogin }: UseLoginParams = {}): FC => {
  if (config.API.MOCK)
    return () => <Redirect to="/dashboard/57432b19-6d30-465d-92ea-7a4de63c463a" />
  const sortingHatLoginEnabled = config.SORTING_HAT.GUARDIAN_SORTING_HAT_LOGIN === 'true'
  const { guardian_id }: any = isAuth() || {}
  const { isGuardian } = useJWT()

  // @TODO investigar pq algumas vezes o JWT retorna isGuardian: true mas o
  // guardian_id do sorting hat retorna undefined
  // a checagem abaixo é pra garantir que o user não vai ser redirecionado
  // para dashboard/undefined
  const ensureIsGuardian = guardian_id && isGuardian

  if (!ensureIsGuardian || forceLogin) {
    return sortingHatLoginEnabled ? LoginIAM : Login
  }

  const path = redirectOnSuccess || `/dashboard/${guardian_id}`
  return () => <Redirect to={path} />
}

export default useLogin
