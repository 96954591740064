import { FC } from 'react'
import styled from 'styled-components'
import { Receivable } from 'src/shared/interfaces'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { typography } from '@material-ui/system'
import { formatCentsToReal } from 'src/shared/utils'

const StyledTypography = styled(Typography)`
  ${typography}
`

export type ReceivableFinancialSummaryProps = {
  isAgglutinated?: boolean
  receivable: Receivable
}

const ReceivableFinancialSummary: FC<ReceivableFinancialSummaryProps> = ({
  isAgglutinated,
  receivable,
}) => {
  const labelBaseAmount = isAgglutinated ? 'Novo valor padrão' : 'Valor padrão'

  const expectedLabel = receivable?.total_amount_paid > 0 ? 'Valor pago' : 'Valor esperado'
  const expectedValue = receivable?.current_amount

  return (
    <>
      <Box pb={1} display="flex" justifyContent="space-between">
        <StyledTypography fontWeight={700} variant="body2">
          {labelBaseAmount}
        </StyledTypography>
        <StyledTypography fontWeight={700} variant="body2">
          {formatCentsToReal(receivable?.original_amount)}
        </StyledTypography>
      </Box>
      <Box pb={1} display="flex" justifyContent="space-between">
        <Typography variant="body2">Desconto padrão</Typography>
        <Typography variant="body2">
          -{formatCentsToReal(receivable?.current_perpetual_discount)}
        </Typography>
      </Box>
      <Box pb={1} display="flex" justifyContent="space-between">
        <Typography variant="body2">Descontos de antecipação</Typography>
        <Typography variant="body2">
          -{formatCentsToReal(receivable?.current_early_payment_discount)}
        </Typography>
      </Box>
      <Box pb={1} display="flex" justifyContent="space-between">
        <Typography variant="body2">Descontos de pontualidade</Typography>
        <Typography variant="body2">
          -{formatCentsToReal(receivable?.current_due_payment_discount)}
        </Typography>
      </Box>
      <Box pb={1} display="flex" justifyContent="space-between">
        <Typography variant="body2">Descontos adicionais</Typography>
        <Typography variant="body2">
          -{formatCentsToReal(receivable?.current_additional_discount)}
        </Typography>
      </Box>
      <Box pb={1} display="flex" justifyContent="space-between">
        <Typography variant="body2" color="textSecondary">
          Multa
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {formatCentsToReal(receivable?.current_fine)}
        </Typography>
      </Box>
      <Box pb={2} display="flex" justifyContent="space-between">
        <Typography variant="body2" color="textSecondary">
          Juros
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {formatCentsToReal(receivable?.current_interest)}
        </Typography>
      </Box>
      <Divider />
      <Box pt={2} display="flex" justifyContent="space-between">
        <StyledTypography fontWeight={700}>{expectedLabel}</StyledTypography>
        <StyledTypography fontWeight={700}>{formatCentsToReal(expectedValue)}</StyledTypography>
      </Box>
    </>
  )
}

export default ReceivableFinancialSummary
