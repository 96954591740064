import { useEffect, useState } from 'react'
import { useApi, useSplitIO, useJWT } from 'src/shared/hooks'
import { useNavigation } from 'src/escolas/hooks'

import { Contract, Receivable, ReceivableStatuses } from 'src/shared/interfaces'

// motivo: na response temos vários descontos, porém acho que não temos como
// saber quais foram aplicados no momento do pagamento
// pra não precisar criar estas lógicas aqui, essa função somente calcula o desconto aplicado
export const setAppliedDiscount = (r: Receivable): Receivable & { appliedDiscount: cents } => ({
  ...r,
  appliedDiscount: r.original_amount + r.current_fine + r.current_interest - r.total_amount_paid,
})

const useContractPaidAmountsReport = (contractId: uuid = null) => {
  const { api } = useApi()
  const { school } = useNavigation()

  const [paidReceivables, setPaidReceivables] = useState<any[]>([])
  const [contract, setContract] = useState<Contract>(null)
  const [schoolData, setSchoolData] = useState<any>(null)

  const [isLoading, setIsLoading] = useState(true)

  const [totalOriginalAmount, setTotalOriginalAmount] = useState<cents>(0)
  const [totalInterest, setTotalInterest] = useState<cents>(0)
  const [totalFine, setTotalFine] = useState<cents>(0)
  const [totalDiscount, setTotalDiscount] = useState<cents>(0)

  const [totalDue, setTotalDue] = useState<cents>(0)
  const [totalPaid, setTotalPaid] = useState<cents>(0)
  const [isFeatureToggleEnabled, setIsFeatureToggleEnabled] = useState<boolean>(null)

  // feature toggle: this whole block can be removed when the test is over
  const { isSchool, isAdmin } = useJWT()
  let splitIOKey
  if (isSchool) splitIOKey = 'contract_paid_amounts_SCHOOL'
  if (isAdmin) splitIOKey = 'contract_paid_amounts_IP'
  const { isLoading: isLoadingFeatureToggle, value: isPaidAmountsReportEnabled } = useSplitIO(
    splitIOKey
  )

  useEffect(() => {
    if (isLoadingFeatureToggle) return
    setIsFeatureToggleEnabled(isPaidAmountsReportEnabled === 'on')
  }, [isLoadingFeatureToggle, isPaidAmountsReportEnabled])
  // end feature toggle handler

  useEffect(() => {
    // feature toggle: remove the condition below when split is over
    // this avoids a memory leak on unmounted component due to the promise
    if (!contractId || !isFeatureToggleEnabled) return

    api.contracts
      .get(contractId, {
        include_guardian: true,
        include_installments: true,
        include_product: true,
        include_receivables: true,
        include_student: true,
      })
      .then((c: Contract) => {
        setContract({ ...c })
      })
  }, [contractId, isFeatureToggleEnabled]) // feature toggle: the second dependency must be removed when split is over

  useEffect(() => {
    setSchoolData({ ...school })
  }, [school])

  useEffect(() => {
    const [amount, interest, fine, discount] = paidReceivables.reduce(
      (acc, { original_amount, current_interest, current_fine, appliedDiscount }) => {
        return [
          acc[0] + original_amount,
          acc[1] + current_interest,
          acc[2] + current_fine,
          acc[3] + appliedDiscount,
        ]
      },
      [0, 0, 0, 0, 0, 0]
    )
    setTotalOriginalAmount(amount)
    setTotalInterest(interest)
    setTotalFine(fine)
    setTotalDiscount(discount)
  }, [paidReceivables])

  useEffect(() => {
    if (!contract) return

    setPaidReceivables(
      contract.installments
        .map(installment =>
          installment.receivables
            .filter(receivable => receivable.status === ReceivableStatuses.PAID)
            .map(setAppliedDiscount)
        )
        .flat()
    )
  }, [contract])

  useEffect(() => {
    setTotalDue(totalOriginalAmount + totalInterest + totalFine)
  }, [totalOriginalAmount, totalInterest, totalFine])

  useEffect(() => {
    setTotalPaid(totalDue - totalDiscount)
  }, [totalDue, totalDiscount])

  useEffect(() => {
    setIsLoading(!(contract && paidReceivables))
  }, [contract, paidReceivables])

  useEffect(() => {
    // feature toggle: right side of the conditional below must be removed when split is over
    if (totalPaid <= 0 || !isFeatureToggleEnabled) return

    document.title = `Demonstrativo-${contract.product.name}-${contract.reference_year}-${contract.student.name}`.replace(
      /\s/g,
      '-'
    )
    window.print()
  }, [totalPaid, isPaidAmountsReportEnabled]) // feature toggle: the second dependency must be removed when split is over

  return {
    contract,
    isFeatureToggleEnabled,
    isLoading,
    isLoadingFeatureToggle,
    isPaidAmountsReportEnabled,
    paidReceivables,
    schoolData,
    splitIOKey,
    totalDiscount,
    totalDue,
    totalFine,
    totalInterest,
    totalOriginalAmount,
    totalPaid,
  }
}

export default useContractPaidAmountsReport
