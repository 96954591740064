import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import styled from 'styled-components'
import { useForm, Controller } from 'react-hook-form'
import { Radio, FormControlLabel, DatePicker, Typography, TextField } from '@olaisaac/design-system'
import Box from '@material-ui/core/Box'
import MenuItem from '@material-ui/core/MenuItem'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import { omit, propEq } from 'ramda'
import { Receivable } from 'src/shared/interfaces'
import { useContract, useNavigation, usePagedDrawer, useSnackbar } from 'src/escolas/hooks'
import { useApi, useJWT } from 'src/shared/hooks'
import {
  date2PTFormat,
  DATE_FORMAT,
  formatCentsToReal,
  isDayTodayOrAfter,
  formatToMonthYear,
  validateEditReason,
} from 'src/shared/utils'
import NumberFormat from 'react-number-format'
import PagedDrawer from 'src/escolas/components/PagedDrawer'
import { FailureFeedbackDialog } from 'src/escolas/components/modal/ConfirmationDialog'
import { parseUpdatedInstallments } from './utils'
import { FeatureFlags, useFeatureFlag } from 'src/shared/hooks/useFeatureFlag'

const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    margin-bottom: 0px;
  }
`

const StyledTypography = styled(Typography).attrs({ withoutMargin: true })`
  overflow-wrap: break-word;
`
const StyledBodyLargeTypography = styled(StyledTypography).attrs({
  variation: 'bodyLarge',
})``
const StyledRightTypography = styled(StyledTypography)`
  text-align: right;
`

type ContractEditForm = {
  change_due_month: string
  change_reason_additional_information: string
  due_day: number
  installment_id: uuid
  start_month: Dayjs
}

export type InstallmentChange = {
  current_amount?: number
  due_month: datestring
  id: uuid
  index: number
  newDueDate: Dayjs
  newValue?: number
  orderReference: string
  originalDueDate: Dayjs
  originalValue: number
  receivable: Receivable
}

export type ContractEditDueDayDrawerProps = {
  availableInstallments: InstallmentChange[]
  callbackEdits?: Dispatch<SetStateAction<boolean>>
  isOpen: boolean
  onClose: () => void
}

const ContractEditDueDayDrawer: FC<ContractEditDueDayDrawerProps> = ({
  availableInstallments,
  callbackEdits,
  isOpen,
  onClose,
}) => {
  const { api } = useApi()
  const { contract } = useContract()
  const form = useForm<ContractEditForm>({
    mode: 'all',
    defaultValues: {
      installment_id: '',
      change_reason_additional_information: '',
    },
  })
  const {
    control,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    formState: { isSubmitting, errors },
  } = form
  watch([
    'change_due_month',
    'installment_id',
    'due_day',
    'start_month',
    'change_reason_additional_information',
  ])
  const {
    change_due_month,
    installment_id,
    due_day,
    start_month,
    change_reason_additional_information,
  } = getValues()
  const { currentPage, setCurrentPage } = usePagedDrawer()
  const [installmentChanges, setInstallmentChanges] = useState<InstallmentChange[]>()
  const [isLoading, setIsLoading] = useState<boolean>()
  const [isNextPageDisabled, setIsNextPageDisabled] = useState(true)
  const [showFailureDialog, setShowFailureDialog] = useState(false)
  const { setContract } = useContract()
  const { isAdmin } = useJWT()
  const { schoolId } = useNavigation()
  const { value: featureFlagValue, config } = useFeatureFlag(
    FeatureFlags.PILOT_SCHOOLS_FEATURE_FLAG
  )

  const { setMessage: setSnackbarMessage, setIsOpen: setSnackbarIsOpen } = useSnackbar()

  const invalidStartDate = Boolean(errors?.start_month)
  const isFeatureFlagEnabled = !isAdmin && featureFlagValue && config.includes(schoolId)

  const isChangeDueMonthTrue = change_due_month_value =>
    isAdmin ? change_due_month_value === 'true' : true

  const submitHandler = async () => {
    return api.contracts
      .changeDueDayCreate(contract?.id, {
        ...(isFeatureFlagEnabled && { change_reason_additional_information }),
        change_due_month: isChangeDueMonthTrue(change_due_month),
        due_day: +due_day,
        installment_id,
        start_month: start_month.hour(0).minute(0).second(0).millisecond(0).utc().hour(0).format(),
      })
      .then(contractData => {
        setContract(contractData)
        setSnackbarMessage('Vencimentos alterados com sucesso')
        setSnackbarIsOpen(true)
        onClose()
        if (callbackEdits) {
          callbackEdits(true)
        }
      })
      .catch(() => {
        setShowFailureDialog(true)
      })
  }

  const getInfo = () => {
    if (isLoading) return
    setIsLoading(true)

    api.contracts
      .changeDueDayInfo(contract?.id, {
        change_due_month: isChangeDueMonthTrue(change_due_month),
        due_day: +due_day,
        installment_id,
        start_month: start_month.hour(0).minute(0).second(0).millisecond(0).utc().hour(0).format(),
      })
      .then(data => {
        setInstallmentChanges(parseUpdatedInstallments(data))
        setCurrentPage(currentPage + 1)
      })
      .finally(() => setIsLoading(false))
  }

  const installment = contract?.installments?.find(propEq('id', installment_id))
  const orderReference = availableInstallments?.find(propEq('id', installment_id))?.orderReference

  const currentDueDay = availableInstallments?.find(propEq('id', installment_id))?.originalDueDate

  const validateSelectInitialInstallmentStep = () => {
    isFeatureFlagEnabled
      ? setIsNextPageDisabled(
          !installment_id || !validateEditReason(change_reason_additional_information)
        )
      : setIsNextPageDisabled(!installment_id)
  }

  const setProgressButton = () => {
    if (currentPage === 0) {
      validateSelectInitialInstallmentStep()
    } else if (currentPage === 1) {
      setIsNextPageDisabled(!start_month || !due_day)
    }
  }

  const setProgressButtonWhenIsAdmin = () => {
    if (currentPage === 0) {
      setIsNextPageDisabled(!change_due_month)
    } else if (currentPage === 1) {
      validateSelectInitialInstallmentStep()
    } else if (currentPage === 2) {
      setIsNextPageDisabled(!start_month || !due_day)
    }
  }

  useEffect(isAdmin ? setProgressButtonWhenIsAdmin : setProgressButton, [
    currentPage,
    start_month,
    change_due_month,
    installment_id,
    change_reason_additional_information,
    due_day,
  ])

  useEffect(() => {
    const selectedInstallment = availableInstallments?.find(propEq('id', installment_id))
    if (!selectedInstallment) return
    const installmentDueDate = selectedInstallment.originalDueDate
    setValue('start_month', installmentDueDate)
    setValue('due_day', installmentDueDate.date())
  }, [installment_id])

  const getCurrentKey = (key: number) => (isAdmin ? (key += 1) : key)

  const SelectReason = (
    <React.Fragment key={0}>
      <Box mb={3}>
        <Typography variation="subtitleDesktopLarge">Qual o motivo da edição?</Typography>
      </Box>
      <FormControl component="fieldset">
        <Controller
          rules={{ required: true }}
          control={control}
          name="change_due_month"
          defaultValue=""
          render={({ field }) => (
            <RadioGroup aria-label="change_due_month" {...field}>
              <StyledFormControlLabel
                value="false"
                control={<Radio />}
                label="Solicitação do responsável"
              />
              <Box pl={4} pb={2}>
                <StyledTypography variation="caption" color="secondary">
                  Repasse não sofre alteração
                </StyledTypography>
              </Box>
              <StyledFormControlLabel
                value="true"
                control={<Radio />}
                label="Correção de contrato"
              />
              <Box pl={4}>
                <StyledTypography variation="caption" color="secondary">
                  O repasse será alterado
                </StyledTypography>
              </Box>
            </RadioGroup>
          )}
        />
      </FormControl>
    </React.Fragment>
  )
  const SelectInitialInstallment = (
    <React.Fragment key={getCurrentKey(0)}>
      <Box mb={2.5}>
        <Typography variation="subtitleDesktopLarge">
          A edição do vencimento deverá ser feito a partir de qual parcela?
        </Typography>
      </Box>
      <Box mb={2.5}>
        <Typography color="secondary">
          Aplicável apenas para parcelas a vencer e não renegociadas.
        </Typography>
      </Box>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="installment_id">Número da parcela</InputLabel>
        <Controller
          rules={{ required: true }}
          control={control}
          name="installment_id"
          defaultValue=""
          render={({ field: { value, onChange } }) => (
            <Select
              labelId="installment_id"
              label="Número da parcela"
              value={value}
              onChange={e => onChange(e.target.value)}
              fullWidth
            >
              {availableInstallments?.map(({ id, index, current_amount, due_month }) => (
                <MenuItem key={id} value={id}>
                  {`${index + 1}. ${formatToMonthYear(due_month)} - ${formatCentsToReal(
                    current_amount
                  )}`}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
      {installment_id && (
        <Box mt={4}>
          <StyledBodyLargeTypography>
            A edição será feita a partir da seguinte parcela:
          </StyledBodyLargeTypography>
          <Box mt={2.5} display="flex" justifyContent="space-between">
            <StyledBodyLargeTypography color="secondary">Parcela</StyledBodyLargeTypography>
            <StyledBodyLargeTypography color="secondary">
              {orderReference}
            </StyledBodyLargeTypography>
          </Box>
          <Box mt={1} display="flex" justifyContent="space-between">
            <StyledBodyLargeTypography color="secondary">
              Valor da parcela
            </StyledBodyLargeTypography>
            <StyledBodyLargeTypography color="secondary">
              {formatCentsToReal(installment?.amount)}
            </StyledBodyLargeTypography>
          </Box>
          <Box mt={1} display="flex" justifyContent="space-between">
            <StyledBodyLargeTypography color="secondary">Vencimento</StyledBodyLargeTypography>
            <StyledBodyLargeTypography color="secondary">
              {date2PTFormat(dayjs(currentDueDay).toISOString())}
            </StyledBodyLargeTypography>
          </Box>
        </Box>
      )}
      {isFeatureFlagEnabled ? (
        <>
          <Box mt={4}>
            <Typography color="secondary">Informe o motivo para a edição do vencimento</Typography>
          </Box>
          <Box mt={2}>
            <FormControl fullWidth variant="outlined">
              <Controller
                rules={{
                  required: true,
                  maxLength: 100,
                  minLength: 5,
                  validate: validateEditReason,
                }}
                name="change_reason_additional_information"
                control={control}
                render={({ field: { value, ...rest }, fieldState: { error } }) => (
                  <TextField
                    {...rest}
                    label="Motivo da edição"
                    id="change_reason_additional_information-input"
                    value={value}
                    error={Boolean(error)}
                    helperText={
                      error ? 'Deve ter entre 5 e 100 caracteres (somente letras e números)' : ''
                    }
                  />
                )}
              />
            </FormControl>
          </Box>
        </>
      ) : null}
    </React.Fragment>
  )
  const SelectNewDueDay = (
    <React.Fragment key={getCurrentKey(1)}>
      <Box mb={4}>
        <Typography variation="subtitleDesktopLarge">
          Selecione a data de vencimento das parcelas a serem editadas.{' '}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <FormControl variant="outlined">
          <Controller
            rules={{
              required: true,
              validate: value => value > 0 && value <= 31,
            }}
            name="due_day"
            control={control}
            render={({ field: { onChange, value, ...rest }, fieldState: { error, invalid } }) => (
              <NumberFormat
                {...rest}
                value={value}
                label="Novo dia de vencimento"
                customInput={TextField}
                variation="outlined"
                InputProps={{
                  inputProps: { min: 1, max: 31 },
                }}
                onValueChange={currentValue => {
                  onChange(currentValue.value)
                }}
                error={Boolean(error?.type) || invalid}
                helperText={error?.type ? 'Insira um dia de vencimento válido' : ''}
              />
            )}
          />
        </FormControl>
        <Box ml={2}>
          <StyledBodyLargeTypography>de cada mês</StyledBodyLargeTypography>
        </Box>
      </Box>
      <Box mt={4}>
        <Box mb={4}>
          <Box mb={2}>
            <Typography variation="subtitleDesktopLarge">
              Selecione a data de vencimento da primeira parcela selecionada.
            </Typography>
          </Box>
          <StyledBodyLargeTypography color="secondary">
            Caso ocorra mudança no mês, as próximas parcelas também passarão para os meses
            seguintes.
          </StyledBodyLargeTypography>
        </Box>

        <FormControl variant="outlined">
          <Controller
            rules={{ required: true, validate: isDayTodayOrAfter }}
            name="start_month"
            control={control}
            render={({ field }) => (
              <DatePicker
                label="Vencimento 1ª parcela"
                placeholder={DATE_FORMAT}
                format={DATE_FORMAT}
                disablePast
                minDateMessage="A data não pode ser anterior a hoje"
                invalidDateMessage="Formato de data inválido"
                error={invalidStartDate}
                helperText={invalidStartDate ? 'Selecione uma data de vencimento válida' : ''}
                {...omit(['ref'], field)}
              />
            )}
          />
        </FormControl>
      </Box>
    </React.Fragment>
  )
  const Confirmation = (
    <React.Fragment key={getCurrentKey(2)}>
      <Box mb={3}>
        <Typography variation="subtitleDesktopLarge">
          Confirme as alterações de vencimento:
        </Typography>
      </Box>
      <Box mb={1} display="flex" justifyContent="space-between">
        <StyledBodyLargeTypography>Motivo</StyledBodyLargeTypography>
        <Box ml={2} textAlign="right" maxWidth="80%">
          {isFeatureFlagEnabled ? (
            <>
              <StyledBodyLargeTypography>
                {`${change_reason_additional_information}`}
              </StyledBodyLargeTypography>
              <StyledRightTypography variation="caption" color="secondary">
                O repasse será alterado
              </StyledRightTypography>
            </>
          ) : (
            <>
              <StyledBodyLargeTypography>
                {isChangeDueMonthTrue(change_due_month)
                  ? 'Correção de contrato'
                  : 'Solicitação do responsável'}
              </StyledBodyLargeTypography>
              <StyledRightTypography variation="caption" color="secondary">
                {isChangeDueMonthTrue(change_due_month)
                  ? 'O repasse será alterado'
                  : 'Repasse não sofre alteração'}
              </StyledRightTypography>
            </>
          )}
        </Box>
      </Box>
      {installmentChanges?.map(
        ({ orderReference: originalOrderReference, originalDueDate, newDueDate }, index) => (
          <Box key={index}>
            <Box mt={3} display="flex" justifyContent="space-between">
              <StyledBodyLargeTypography>Parcela</StyledBodyLargeTypography>
              <StyledBodyLargeTypography>{originalOrderReference}</StyledBodyLargeTypography>
            </Box>
            <Box mt={1.5} display="flex" justifyContent="space-between">
              <StyledBodyLargeTypography color="secondary">
                Vencimento original
              </StyledBodyLargeTypography>
              <StyledBodyLargeTypography color="secondary">
                {date2PTFormat(dayjs(originalDueDate).toISOString())}
              </StyledBodyLargeTypography>
            </Box>
            <Box mt={1.5} display="flex" justifyContent="space-between">
              <StyledBodyLargeTypography color="secondary">
                Novo vencimento
              </StyledBodyLargeTypography>
              <StyledBodyLargeTypography color="secondary">
                {date2PTFormat(dayjs(newDueDate).toISOString())}
              </StyledBodyLargeTypography>
            </Box>
          </Box>
        )
      )}
    </React.Fragment>
  )

  const defaultPages = [SelectInitialInstallment, SelectNewDueDay, Confirmation]
  const pages: React.ReactFragment[] = isAdmin ? [SelectReason, ...defaultPages] : defaultPages

  const pagesConfig = {
    pages: {
      SELECT_REASON_STEP: {
        index: 0,
        action: null,
      },
      SELECT_INITIAL_INSTALLMENT_STEP: {
        index: 1,
        action: getInfo,
      },
      SELECT_NEW_DUE_DAY_STEP: {
        index: 2,
        action: getInfo,
      },
      CONFIRMATION_STEP: {
        index: 3,
        action: handleSubmit(submitHandler),
      },
    },
  }

  const getPagesConfig = () => {
    const {
      SELECT_REASON_STEP,
      SELECT_INITIAL_INSTALLMENT_STEP,
      SELECT_NEW_DUE_DAY_STEP,
      CONFIRMATION_STEP,
    } = pagesConfig.pages

    let currentSelectReasonIndex = SELECT_REASON_STEP.index
    let currentSelectInitialInstallmentIndex = SELECT_INITIAL_INSTALLMENT_STEP.index
    let currentSelectNewDueDayIndex = SELECT_NEW_DUE_DAY_STEP.index
    let currentConfirmationIndex = CONFIRMATION_STEP.index

    if (!isAdmin) {
      currentSelectReasonIndex = currentSelectReasonIndex - 1
      currentSelectInitialInstallmentIndex = currentSelectInitialInstallmentIndex - 1
      currentSelectNewDueDayIndex = currentSelectNewDueDayIndex - 1
      currentConfirmationIndex = currentConfirmationIndex - 1
    }

    return {
      [currentSelectReasonIndex]: SELECT_REASON_STEP.action,
      [currentSelectInitialInstallmentIndex]: SELECT_INITIAL_INSTALLMENT_STEP.action,
      [currentSelectNewDueDayIndex]: SELECT_NEW_DUE_DAY_STEP.action,
      [currentConfirmationIndex]: CONFIRMATION_STEP.action,
    }
  }

  const handleClickMap = getPagesConfig()

  return (
    <>
      <PagedDrawer
        isLoading={isLoading || isSubmitting}
        isNextPageDisabled={isNextPageDisabled}
        pages={pages}
        handleClickMap={handleClickMap}
        isOpen={isOpen}
        onClose={() => {
          onClose()
          reset()
        }}
        title="Editar vencimento"
      />
      <FailureFeedbackDialog
        buttonLabel="Voltar"
        isVisible={showFailureDialog}
        onClose={() => setShowFailureDialog(false)}
        submitHandler={() => setShowFailureDialog(false)}
        centered
      />
    </>
  )
}

export default ContractEditDueDayDrawer
