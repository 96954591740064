import React, { FC, useState } from 'react'
import copy from 'copy-to-clipboard'
import { Button, DialogTitle, DialogContent, DialogActions } from '@olaisaac/design-system'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import { EVENT_CATEGORIES, EVENT_GROUPS, REALMS } from 'src/shared/constants'
import SendEvent from 'src/shared/api/eventsClient'

import { useNavigation, useContract } from 'src/escolas/hooks'
import { useApi, useJWT } from 'src/shared/hooks'
import { Receivable } from 'src/shared/interfaces'

export type CopyInvoiceLinkSuccessDialogProps = {
  openReceivable: Receivable
}

const CopyInvoiceLinkSuccessDialog: FC<CopyInvoiceLinkSuccessDialogProps> = ({
  openReceivable,
}) => {
  const { api } = useApi()
  const [isOpen, setIsOpen] = useState(false)
  const [link, setLink] = useState<string>('')
  const { isAdmin, userId } = useJWT()
  const { schoolSlug } = useNavigation()
  const { contract } = useContract()

  const sendCopyLinkEvent = () => {
    SendEvent({
      category: EVENT_CATEGORIES.INVOICE_CATEGORY,
      group: EVENT_GROUPS.SCHOOLS_GROUP,
      metadata: {
        invoice_id: openReceivable.invoice_id,
        installment_id: openReceivable.installment_id,
        contract_id: contract.id,
        school_slug: schoolSlug,
      },
      realm: isAdmin ? REALMS.ADMIN_REALM : REALMS.SCHOOL_REALM,
      type: 'invoice-link-copied',
      user_id: userId,
    })
  }

  const handleCopyLink = async () => {
    if (openReceivable) {
      const { checkout_url } = await api.invoices.get(openReceivable.invoice_id)

      copy(checkout_url)
      setLink(checkout_url)
      setIsOpen(true)
      sendCopyLinkEvent()
    }
  }
  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <>
      <Button variation="primary" fullWidth onClick={handleCopyLink}>
        Copiar link de cobrança
      </Button>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle>
          <Typography color="textPrimary" variant="subtitle1">
            Link copiado com sucesso!
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" gutterBottom>
            O link do seu boleto foi copiado para a área de transferência como:
          </Typography>
          <br />
          <a target="_blank" rel="noreferrer" href={link}>
            {link}
          </a>
        </DialogContent>
        <DialogActions>
          <Button variation="primary" onClick={handleClose}>
            Ok, entendi
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CopyInvoiceLinkSuccessDialog
