import { FC } from 'react'

export type LogoProps = {
  inverted?: boolean
}

const IsaacLogo: FC<LogoProps> = ({ inverted = false }) => {
  return (
    <svg width="81" height="24" viewBox="0 0 81 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
        <path
          d="M42.5449 19.1828V23.6226H41.2029C38.8507 23.6226 37.2823 22.8353 36.5634 21.2922C35.2896 22.9613 33.3943 24 31.0749 24C26.5007 24 23.0376 20.3795 23.0376 15.5932C23.0376 10.8069 26.5031 7.19824 31.0772 7.19824C33.1352 7.19824 34.8671 8.01704 36.1082 9.33972V7.57334H40.8784V17.8916C40.8784 18.7104 41.3686 19.185 42.2205 19.185L42.5449 19.1828ZM36.1082 15.5932C36.1082 13.3263 34.377 11.6353 32.0237 11.6353C29.7036 11.6353 27.9723 13.3263 27.9723 15.5932C27.9723 17.8601 29.7036 19.5607 32.0237 19.5607C34.377 19.5607 36.1082 17.8601 36.1082 15.5932Z"
          fill={inverted ? '#3d4ed7' : '#ffffff'}
        />
        <path
          d="M63.4751 19.1822V23.6221H62.133C59.7803 23.6221 58.2124 22.8348 57.4935 21.2922C56.2192 22.9607 54.3245 24 52.0045 24C47.4309 24 43.9678 20.3789 43.9678 15.5926C43.9678 10.8064 47.4338 7.19824 52.0074 7.19824C54.066 7.19824 55.8002 8.01704 57.039 9.33916V7.57334H61.8092V17.8916C61.8092 18.7104 62.2993 19.185 63.1513 19.185L63.4751 19.1822ZM57.039 15.5926C57.039 13.3258 55.3077 11.6353 52.9544 11.6353C50.635 11.6353 48.9031 13.3258 48.9031 15.5926C48.9031 17.8595 50.635 19.5601 52.9544 19.5601C55.3077 19.5601 57.039 17.8601 57.039 15.5926Z"
          fill={inverted ? '#3d4ed7' : '#ffffff'}
        />
        <path
          d="M5.46303 7.57336H0.423828V23.6244H5.46303V7.57336Z"
          fill={inverted ? '#3d4ed7' : '#ffffff'}
        />
        <path
          d="M2.94323 5.67313C4.56873 5.67313 5.88645 4.40315 5.88645 2.83656C5.88645 1.26997 4.56873 0 2.94323 0C1.31773 0 0 1.26997 0 2.83656C0 4.40315 1.31773 5.67313 2.94323 5.67313Z"
          fill={inverted ? '#3d4ed7' : '#ffffff'}
        />
        <path
          d="M15.4876 13.4061C15.0353 13.2807 14.6082 13.1621 14.1962 13.0344C13.9272 12.9512 13.108 12.697 13.108 12.0784C13.108 11.6251 13.3758 11.0841 14.6531 11.0841C15.5897 11.0841 16.3377 11.529 16.8786 12.4063L21.0986 10.5724C20.4754 9.62931 19.7997 8.79589 18.7937 8.21047C16.518 6.8816 13.0041 6.85461 10.6211 8.14861C9.40912 8.80657 7.96435 10.0798 7.96435 12.4085C7.96435 13.8965 8.5712 14.9954 9.8736 15.8676C11.0371 16.6459 12.5239 17.1048 13.9914 17.5502C14.4215 17.6807 14.8276 17.8038 15.2238 17.9343C15.9287 18.1677 16.2858 18.5057 16.2858 18.9381C16.2858 19.4639 15.9707 20.0904 14.4699 20.0904C13.1203 20.0904 12.1808 19.5235 11.6726 18.4033L7.30615 20.5223C8.56362 22.8617 10.9239 24 14.5038 24C16.434 24 18.1215 23.5647 19.5219 22.7065C21.5059 21.4884 21.9243 18.991 21.311 17.1543C20.5297 14.8115 17.8654 14.0647 15.4876 13.4061Z"
          fill={inverted ? '#3d4ed7' : '#ffffff'}
        />
        <path
          d="M70.9611 18.2695C70.2562 17.5783 69.8991 16.6803 69.8991 15.6005C69.8991 14.5208 70.2562 13.6221 70.9611 12.9315C71.6659 12.241 72.5593 11.8872 73.6143 11.8872C74.9896 11.8872 76.0335 12.3934 76.7232 13.3893L80.9974 11.0105C80.2787 9.89057 79.2797 8.96243 78.0921 8.31116C76.767 7.57503 75.2726 7.19824 73.6487 7.19824C71.1472 7.19824 69.0355 8.01142 67.3719 9.61641C65.7083 11.2214 64.8628 13.233 64.8628 15.6C64.8628 17.9669 65.7065 19.9802 67.3719 21.5829C69.0372 23.1857 71.1466 24.0011 73.6487 24.0011C75.272 24.0011 76.767 23.6266 78.0921 22.8882C79.2867 22.2323 80.2876 21.293 80.9998 20.1596L76.7343 17.7859C76.0796 18.7981 75.0322 19.3144 73.6143 19.3144C72.5593 19.3132 71.6665 18.9623 70.9611 18.2695Z"
          fill={inverted ? '#3d4ed7' : '#ffffff'}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="81" height="24" fill={inverted ? '#3d4ed7' : '#ffffff'} />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IsaacLogo
