import styled from 'styled-components'
import { TableControlBar } from '@olaisaac/design-system'

export const PageTableControlBar = styled(TableControlBar)<{ $isActionDrawerOpen?: boolean }>(
  ({ $isActionDrawerOpen }) => ({
    bottom: 0,
    left: 96,
    position: 'fixed',
    right: $isActionDrawerOpen ? '25%' : 0,
    zIndex: 1,
  })
)
