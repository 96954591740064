export const REALMS = {
  SCHOOL_REALM: 'escolas',
  ADMIN_REALM: 'backoffice',
  GUARDIAN_REALM: 'responsaveis',
}
export const ROLES = {
  PAYOUT_REPORT_ACCESS: 'payout_report_access',
}

export const FILES = {
  pdf: {
    extension: 'pdf',
    contentType: 'application/pdf',
  },
  xlsx: {
    extension: 'xlsx',
    contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
}

export const EVENT_GROUPS = {
  GUARDIANS_GROUP: 'guardians',
  SCHOOLS_GROUP: 'schools',
}

export const EVENT_CATEGORIES = {
  ENROLLMENT_CATEGORY: 'enrollment',
  GENERAL_CATEGORY: 'general',
  INVOICE_CATEGORY: 'invoice',
  REPORT_CATEGORY: 'report',
  STATEMENT_CATEGORY: 'statement',
}
