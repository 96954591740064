import { useContext, useEffect } from 'react'
import { useParams, useLocation } from 'react-router'
import { SchoolContext } from 'src/escolas/contexts/schoolContext'
import { PathParams } from 'src/shared/interfaces'
import envConfig from 'src/config'
import { GuardianContext } from '../contexts/guardianContext'
import { useJWT } from 'src/shared/hooks'

type NavigationParams = Partial<{
  path: string
  slug: string
}>

const useNavigation = () => {
  const { schoolSlug, guardianId } = useParams<PathParams>()
  const { guardian, loadGuardianById, clearGuardian } = useContext(GuardianContext)
  const { school, loadSchoolBySlug, clearSchool } = useContext(SchoolContext)
  const { hasPayoutReportAccess } = useJWT()
  const location = useLocation()
  const { DEFAULT_CONTRACT_YEAR } = envConfig

  const schoolId = school?.id
  useEffect(() => {
    loadSchoolBySlug(schoolSlug)
    if (guardianId) {
      loadGuardianById(guardianId)
    }
  }, [])

  const getNavigationUrl = ({ slug, path }: NavigationParams): url => {
    if (!slug) {
      // TROCANDO DE PATH NA MESMA ESCOLA
      return `/${schoolSlug}/${path}`.replace(/\/\//g, '/')
    }
    if (!path) {
      if (schoolSlug) {
        // MUDANDO CONTEXTO DE UMA ESCOLA PARA OUTRA

        // REGEX match do primeiro uuid pra frente
        // eslint-disable-next-line security/detect-unsafe-regex
        const regexUUID = /\/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}.*/

        // REGEX match tudo entre as duas primeiras /
        // eslint-disable-next-line security/detect-unsafe-regex
        const regexFirst2Slashes = /^\/(.*?)(\/.*)?$/

        return location.pathname.replace(regexFirst2Slashes, `/${slug}$2`).replace(regexUUID, '')
      } else if (hasPayoutReportAccess) {
        return `/${slug}`
      } else {
        // MUDANDO DE UM CONTEXTO SEM ESCOLA PARA UM COM ESCOLA
        return `/${slug}/contratos/${DEFAULT_CONTRACT_YEAR}`.replace(/\/\//g, '/')
      }
    } else {
      return `/${slug}/${path}`.replace(/\/\//g, '/')
    }
  }

  const navigateTo = ({ slug, path }: NavigationParams) => {
    window.location.href = getNavigationUrl({ slug, path })
    // HARD NAVIGATE ON PURPOSE TO FORCE FULL RELOAD DUE TO CHANGE OF CONTEXT
  }

  return {
    clearSchool,
    getNavigationUrl,
    navigateTo,
    school,
    schoolId,
    schoolSlug,
    guardian,
    clearGuardian,
  }
}

export default useNavigation
