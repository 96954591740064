import { Address, Product } from 'src/shared/interfaces'
import { GuardianFormNames } from './types'

export const fieldsErrorMapping = {
  cpf: GuardianFormNames.TAX_ID,
  email: GuardianFormNames.EMAIL,
  'payer.address.zip_code': GuardianFormNames.ZIP,
  'payer.cpf_cnpj': GuardianFormNames.TAX_ID,
  zipcode: GuardianFormNames.ZIP,
}

export const fieldsNamesMapping = {
  [GuardianFormNames.EMAIL]: 'Email',
  [GuardianFormNames.ZIP]: 'CEP',
  [GuardianFormNames.TAX_ID]: 'CPF',
}

export const CREATE_NEW_STUDENT_PREFIX = `Criar novo: `
export const CREATE_NEW_GUARDIAN_PREFIX = `Criar novo: `
export const DEBOUNCE_MS = 500

export const defaultProduct: Product = {
  created_at: '',
  enrollment_amount: null,
  enrollment_installments_quantity: null,
  envelope_template_referral_id: '',
  id: '',
  installments_quantity: null,
  monthly_amount: null,
  name: '',
  school_id: '',
}

export const defaultAddress: Omit<Address, 'created_at' | 'updated_at' | 'id'> = {
  additional_information: null,
  city: null,
  number: null,
  state_code: null,
  street: null,
  zip: null,
}

export enum ProductFormActions {
  DELETE = 'DELETE',
  EDIT = 'EDIT',
}

export const DUPLICATED_CONTRACT_MESSAGE_MAX_SECONDS = 5 * 60
